import { MenuDivider } from '@szhsin/react-menu';
import styled, { css } from 'styled-components';

const DropdownDivider = styled(MenuDivider)`
  ${({ theme }) => css`
    --dropdown-divider-height: ${theme.dropdownDividerHeight}px;
    --dropdown-divider-margin-y: ${theme.dropdownDividerMarginY}px;
    --dropdown-divider-margin-x: ${theme.dropdownDividerMarginX}px;
    --dropdown-divider-bg: ${theme.dropdownDividerBg};
    height: var(--dropdown-divider-height);
    margin: var(--dropdown-divider-margin-y) var(--dropdown-divider-margin-x) !important;
    background: var(--dropdown-divider-bg);
  `}
`;

export default DropdownDivider;
