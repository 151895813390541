import { ArcElement, BarController, BarElement, ChartData, Chart as ChartJS, ChartOptions, ChartType, DoughnutController, Plugin, Tooltip } from 'chart.js';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { CategoryScale, Filler, LinearScale, LineController, LineElement, PointElement, TimeScale } from 'chart.js';
import { useEffect, useRef } from 'react';
import ChartContainer from './ChartContainer';
import ChartDefaults from './ChartDefaults';

export type ChartProps = BaseHOCPropsWithoutRef<'div'> & {
  type: ChartType;
  data: ChartData<ChartType, unknown, unknown>;
  options?: ChartOptions;
  plugins?: Plugin[];
};

ChartJS.register(
  LineController,
  BarController,
  DoughnutController,
  LineElement,
  BarElement,
  ArcElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Filler,
  Tooltip,
);

ChartDefaults();

export default function Chart({ type, data, options, plugins, ...props }: ChartProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<ChartJS<ChartType, unknown, unknown> | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current;
      chartRef.current = new ChartJS(ctx, { type, data, options, plugins });
      return () => chartRef.current?.destroy();
    }
  });

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data]);

  return (
    <ChartContainer {...props}>
      <canvas ref={canvasRef} />
    </ChartContainer>
  );
}
