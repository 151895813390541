import { Flex } from '~/components/layout';
import styled, { css } from 'styled-components';

type SidenavContentProps = {
  isActive: boolean;
};

const SidenavContent = styled(Flex)<SidenavContentProps>`
  ${({ theme }) => css`
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${theme.spacers[6]}px;
    background-color: var(--sidenav-bg);
  `}
`;

export default SidenavContent;
