import React from 'react';
import Button, { ButtonProps } from '~/components/form//Button';
import styled, { css } from 'styled-components';

const ButtonWithRef = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => <Button ref={ref} {...props} />);
ButtonWithRef.displayName = 'ButtonWithRef';

const RadioButton = styled(ButtonWithRef)`
  ${({ isActive = false, theme }) => css`
    --radio-button-active-bg: ${theme.radioButtonActiveBg};
    --radio-button-active-border-color: ${theme.radioButtonActiveBorderColor};
    --radio-button-active-color: ${theme.radioButtonActiveColor};
    --radio-button-active-box-shadow: ${theme.radioButtonActiveBoxShadow};
    --btn-color: ${theme.colors.gray700};
    --btn-border-color: ${theme.colors.transparent};
    --btn-background-color: ${theme.colors.transparent};
    --btn-hover-color: ${theme.colors.gray800};
    --btn-hover-border-color: ${theme.colors.transparent};
    --btn-hover-background-color: ${theme.colors.transparent};
    position: relative;
    margin: -${theme.borderWidth}px !important;

    /* 
     * Active
     */

    ${isActive &&
    `
      --btn-background-color: var(--radio-button-active-bg);
      --btn-border-color: var(--radio-button-active-border-color);
      --btn-color: var(--radio-button-active-color);
      --btn-hover-background-color: var(--radio-button-active-bg);
      --btn-hover-border-color: var(--radio-button-active-border-color);
      --btn-hover-color: var(--radio-button-active-color);
    `}

    /* 
     * Divider
     */

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      height: ${theme.spacers[5]}px;
      border-right: ${theme.borderWidth}px solid ${isActive ? theme.colors.transparent : theme.colors.gray400};
      transform: translateY(-50%);
    }
  `}
`;

export default RadioButton;
