import Carousel from 'react-slick';
import { darken, fadeIn, formatSVG } from '~/helpers';
import { progressX } from '~/components/animations';
import styled, { css } from 'styled-components';

type CarouselProps = { autoadvance: boolean };

const Slider = styled(Carousel)<CarouselProps>`
  ${({ autoadvance, autoplaySpeed, theme }) => css`
    position: static;

    .slick-slide > * > * {
      outline: none !important;
    }

    .slick-list {
      &::after,
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        height: 100%;
        width: 0.5rem;
      }

      &::after {
        right: 0;
        background-image: linear-gradient(to left, ${theme.colors.white10}, ${fadeIn(theme.colors.white, 0)});
      }

      &::before {
        left: 0;
        background-image: linear-gradient(to right, ${theme.colors.white10}, ${fadeIn(theme.colors.white, 0)});
      }
    }

    .slick-arrow {
      position: absolute;
      top: 100%;
      width: 40px;
      height: 40px;
      border: 0;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-color: ${theme.colors.primary};
      background-image: ${formatSVG(`
        <svg viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' xmlns='http://www.w3.org/2000/svg'>
          <line x1='19' y1='12' x2='5' y2='12'></line><polyline points='12 19 5 12 12 5'></polyline>
        </svg>
      `)};
      background-size: 24px 24px;
      background-position: center center;
      box-shadow: ${theme.boxShadow[2]};
      font-size: 0;
      cursor: pointer;
      transition: ${theme.transition.base};

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: ${darken(theme.colors.primary, 0.05)};
      }

      @media (min-width: ${theme.breakpoints.desktop}px) {
        top: 50%;
        width: 48px;
        height: 48px;
      }
    }

    .slick-prev {
      left: ${theme.spacers[7]}px;
      transform: translateY(-50%);

      @media (min-width: ${theme.breakpoints.desktop}px) {
        left: 0;
      }
    }

    .slick-next {
      right: ${theme.spacers[7]}px;
      transform: translateY(-50%) scaleX(-1);

      @media (min-width: ${theme.breakpoints.desktop}px) {
        right: 0;
      }
    }

    .slick-dots {
      position: relative;
      margin-top: ${theme.spacers[10]}px;
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      text-align: center;
      font-size: 0;
    }

    .slick-dots > li {
      display: inline-block;
    }

    .slick-dots > li + li {
      margin-left: 0.5rem;
    }

    .slick-dots > li > button {
      position: relative;
      width: 32px;
      height: 4px;
      background-color: ${theme.colors.primary};
      border-radius: 1rem;
      border: none;
      cursor: pointer;
      overflow: hidden;

      &:focus {
        outline: none;
      }

      @media (min-width: ${theme.breakpoints.desktop}px) {
        width: 48px;
      }
    }

    .slick-dots > li.slick-active ~ li > button {
      background-color: ${theme.colors.gray300};
    }

    ${autoadvance &&
    css`
      .slick-dots > li.slick-active > button::after,
      .slick-dots > li.slick-active > button::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }

      .slick-dots > li.slick-active > button::after {
        background-color: ${theme.colors.primary};
        transform: scaleX(0);
        transform-origin: left center;
        animation: ${progressX} ${autoplaySpeed}ms linear;
      }

      .slick-dots > li.slick-active > button::before {
        background-color: ${theme.colors.gray300};
      }
    `}
  `}
`;

export default Slider;
