import { Avatar } from '~/components/ui';
import { Checkbox } from '~/components/form';
import { MenuItem, ClickEvent } from '@szhsin/react-menu';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import Icon from '~/components/vendor/Icon';
import styled, { css } from 'styled-components';
import { StyledCheckbox, StyledCheckboxInput } from '~/components/form/Checkbox';
import { userCircle01 } from '~/components/vendor/Icon/icons';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

type StyledMenuItemProps = {
  isActive?: boolean;
  isCursorEnabled?: boolean;
  isDisabled?: boolean;
  isInactive?: boolean;
};

const StyledMenuItem = styled(Base)<StyledMenuItemProps>`
  ${({ isActive, isCursorEnabled, isDisabled, isInactive, theme }) => css`
    --dropdown-item-padding-y: ${theme.dropdownItemPaddingY}px;
    --dropdown-item-padding-x: ${theme.dropdownItemPaddingX}px;
    --dropdown-item-margin-x: ${theme.dropdownItemMarginX}px;
    --dropdown-item-font-size: ${theme.dropdownItemFontSize}px;
    --dropdown-item-border-radius: ${theme.dropdownItemBorderRadius}px;
    --dropdown-item-bg: ${theme.dropdownItemBg};
    --dropdown-item-color: ${theme.dropdownItemColor};
    --dropdown-item-hover-bg: ${theme.dropdownItemHoverBg};
    --dropdown-item-hover-color: ${theme.dropdownItemHoverColor};
    --dropdown-item-active-bg: ${theme.dropdownItemActiveBg};
    --dropdown-item-active-color: ${theme.dropdownItemActiveColor};
    --dropdown-item-opacity: 1;
    --dropdown-item-cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
    margin-right: var(--dropdown-item-margin-x);
    margin-left: var(--dropdown-item-margin-x);
    font-size: var(--dropdown-item-font-size);
    background-color: var(--dropdown-item-bg);
    border-radius: var(--dropdown-item-border-radius);
    color: var(--dropdown-item-color);
    opacity: var(--dropdown-item-opacity);
    cursor: var(--dropdown-item-cursor);

    /*
     * Hover
     */

    &:hover,
    &.szh-menu__item--hover {
      background-color: var(--dropdown-item-hover-bg);
      color: var(--dropdown-item-hover-color);
    }

    /*
     * Active
     */

    ${isActive &&
    ` 
      --dropdown-item-bg: var(--dropdown-item-active-bg);
      --dropdown-item-color: var(--dropdown-item-active-color);
    `}

    /*
     * Disabled
     */

    ${isDisabled &&
    `
      --dropdown-item-hover-bg: var(--dropdown-item-bg);
      --dropdown-item-hover-color: var(--dropdown-item-color);
      --dropdown-item-opacity: .5;
    `}

    ${isDisabled &&
    !isCursorEnabled &&
    `
      --dropdown-item-cursor: not-allowed !important;
    `}

    /*
     * Inactive
     */

    ${isInactive &&
    `
      --dropdown-item-color: ${theme.colors.gray700};
      --dropdown-item-hover-bg: var(--dropdown-item-bg);
      --dropdown-item-hover-color: var(--dropdown-item-color);
    `}

    /*
     * Icon
     */

    ${Icon.styledComponent} {
      color: ${theme.colors.gray500};
      display: flex;
    }

    ${Icon.styledComponent}, ${Avatar.styledComponent}, ${StyledCheckboxInput} {
      margin-right: ${theme.spacers[4]}px;
    }

    ${StyledCheckbox} {
      display: inline-flex;
      align-items: center;
      font-size: var(--dropdown-item-font-size);
      color: var(--dropdown-item-color);
    }

    ${StyledCheckbox}::before {
      content: '';
      position: absolute;
      inset: 0;
    }

    ${StyledCheckbox} > ${Avatar.styledComponent} {
      margin-left: 0;
    }
  `}
`;

type DropdownItemInnerProps = StyledMenuItemProps & {
  checkBoxProps?: React.ComponentProps<typeof Checkbox>;
  icon?: string;
  isCheckbox?: boolean;
  user?: { profileImageSet?: boolean; profileFileId: string; _id: string; fullName: string; email: string };
};

type DropdownItemProps = BaseHOCPropsWithoutRef<typeof MenuItem, DropdownItemInnerProps>;

const DropdownItem = ({ children, icon, user, checkBoxProps, isCheckbox, onClick, ...props }: DropdownItemProps) => {
  const dropdownItemIcon = icon && icon === 'user' ? <Icon icon={userCircle01} size={16} /> : icon ? <Icon icon={icon} size={16} /> : null;
  const dropdownItemAvatar = user ? <UserAvatar user={user} size="2xs" /> : null;
  const dropdownItemChildren = user ? user?.fullName : children;

  const dropdownItemContent = (
    <>
      {dropdownItemIcon}
      {dropdownItemAvatar}
      {dropdownItemChildren}
    </>
  );

  function handleClick(e: ClickEvent) {
    e.syntheticEvent?.stopPropagation();
    onClick?.(e);
  }

  return (
    <StyledMenuItem as={MenuItem} onClick={handleClick} {...props}>
      {isCheckbox ? <Checkbox {...(checkBoxProps ?? {})}>{dropdownItemContent}</Checkbox> : dropdownItemContent}
    </StyledMenuItem>
  );
};

export default DropdownItem;
