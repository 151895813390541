import styled, { css } from 'styled-components';
import TableIcon from './TableIcon';

const TableHeaderIcon = styled(TableIcon)`
  ${() => css`
    & {
      height: calc(var(--table-header-height) - var(--table-header-padding-y) * 2);
      padding-top: var(--table-header-padding-y);
      padding-bottom: var(--table-header-padding-y);
      background-color: var(--table-header-bg);
    }
  `}
`;

export default TableHeaderIcon;
