/* eslint-disable max-len */
import Carousel from 'react-slick';
import styled, { css } from 'styled-components';
import { darken, formatSVG } from '~/helpers';

const CardSlider = styled(Carousel)`
  ${({ theme }) => css`
    .slick-slide > * > * {
      outline: none !important;
    }

    .slick-arrow {
      position: absolute;
      z-index: 1;
      top: 100%;
      width: 3rem;
      height: 3rem;
      background-repeat: no-repeat;
      background-color: ${theme.colors.primary};
      background-image: ${formatSVG(`
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='${theme.colors.white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'>
          <line x1='19' y1='12' x2='5' y2='12'></line><polyline points='12 19 5 12 12 5'>
        </polyline></svg>
      `)};
      background-position: center center;
      background-size: 1.5rem 1.5rem;
      border: 0;
      border-top-left-radius: 10rem;
      border-bottom-left-radius: 10rem;
      outline: 0;
      font-size: 0;
      cursor: pointer;
      transition: ${theme.transition.base};
      transition-property: background-color;

      &:hover {
        background-color: ${darken(theme.colors.primary, 0.05)};
      }
    }

    .slick-arrow.slick-disabled {
      background-image: ${formatSVG(`
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='${theme.colors.gray500}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'>
          <line x1='19' y1='12' x2='5' y2='12'></line><polyline points='12 19 5 12 12 5'></polyline>
        </svg>
      `)};
    }

    .slick-prev {
      left: 50%;
      transform: translate(-100%, -50%);
    }

    .slick-next {
      right: 50%;
      transform: translate(100%, -50%) scaleX(-1);

      &::after {
        content: '';
        position: absolute;
        top: 0.75rem;
        bottom: 0.75rem;
        left: calc(100% - ${theme.borderWidth}px);
        border-right: ${theme.borderWidth}px solid ${theme.borderColor};
      }
    }
  `}
`;

export default CardSlider;
