import React from 'react';
import { components, Props } from 'react-select';
import { MenuProps } from 'react-select';
import styled, { css } from 'styled-components';

const StyledMenu = styled.div<Props>`
  ${({ menuAlignment, menuWidth, theme }) => css`
    .react-select__menu-portal:has(&&&) {
      z-index: ${theme.zindex.select};
    }

    .react-select__menu {
      --select-menu-padding-y: ${theme.selectMenuPaddingY}px;
      --select-menu-border-width: ${theme.selectMenuBorderWidth}px;
      --select-menu-border-color: ${theme.selectMenuBorderColor};
      --select-menu-border-radius: ${theme.selectMenuBorderRadius}px;
      --select-menu-box-shadow: ${theme.selectMenuBoxShadow};
      --select-menu-item-margin-x: ${theme.selectMenuItemMarginX}px;
      --select-menu-item-padding-y: ${theme.selectMenuItemPaddingY}px;
      --select-menu-item-padding-x: ${theme.selectMenuItemPaddingX}px;
      --select-menu-item-font-size: ${theme.selectMenuItemFontSize}px;
      --select-menu-item-border-radius: ${theme.selectMenuItemBorderRadius}px;
      --select-menu-item-bg: ${theme.selectMenuItemBg};
      --select-menu-item-hover-bg: ${theme.selectMenuItemHoverBg};
      --select-menu-item-active-bg: ${theme.selectMenuItemActiveBg};
      --select-menu-item-color: ${theme.selectMenuItemColor};
      --select-menu-item-hover-color: ${theme.selectMenuItemHoverColor};
      --select-menu-item-active-color: ${theme.selectMenuItemActiveColor};
      left: ${menuAlignment === 'left' ? 0 : 'auto'};
      right: ${menuAlignment === 'right' ? 0 : 'auto'};
      z-index: ${theme.zindex.dropdown};
      width: ${menuWidth === 'auto' ? 'auto' : '100%'};
      min-width: ${menuWidth === 'auto' ? '100%' : null};
      border: var(--select-menu-border-width) solid var(--select-menu-border-color);
      border-radius: var(--select-menu-border-radius);
      box-shadow: var(--select-menu-box-shadow);
      overflow: clip;

      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        overflow-y: auto;
      }
    }

    .react-select__menu-list {
      padding-top: var(--select-menu-padding-y);
      padding-bottom: var(--select-menu-padding-y);
    }

    .react-select__menu-notice {
      color: ${theme.colors.gray600};
      padding: ${theme.spacers[4]}px ${theme.spacers[5]}px;
    }

    /*
     * Group
     */

    .react-select__group {
      padding-top: 0;
      padding-bottom: 0;
    }

    .react-select__group-heading {
      font-size: ${theme.fontSize['2xs']}px;
      font-weight: ${theme.fontWeight.bold};
      text-transform: uppercase;
      letter-spacing: 0.08em;
      color: ${theme.colors.gray700};
      padding: ${theme.spacers[6]}px;
      margin-bottom: 0;
    }

    /*
     * Option
     */

    .react-select__option {
      overflow: hidden;
      width: calc(100% - var(--select-menu-item-margin-x) * 2);
      margin-right: var(--select-menu-item-margin-x);
      margin-left: var(--select-menu-item-margin-x);
      padding: var(--select-menu-item-padding-y) var(--select-menu-item-padding-x);
      border-radius: var(--select-menu-item-border-radius);
      background-color: var(--select-menu-item-bg);
      font-size: var(--select-menu-item-font-size);
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--select-menu-item-color);
      cursor: pointer;
    }

    .react-select__option:hover {
      background-color: var(--select-menu-item-hover-bg);
      color: var(--select-menu-item-hover-color);
    }

    .react-select__option--is-focused,
    .react-select__option--is-selected {
      background-color: var(--select-menu-item-active-bg);
      color: var(--select-menu-item-active-color);
    }

    .react-select__menu-list--is-multi .react-select__option--is-selected:not(.react-select__option--is-focused):has(input[type='checkbox']) {
      background: none !important;
    }

    .react-select__option--is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}
`;

const SelectMenu = ({ children, ...props }: MenuProps) => {
  if (props.selectProps?.isCreatable && props.selectProps?.async && (props.isLoading || !props.options.length)) {
    return null;
  }

  return (
    <StyledMenu {...props.selectProps}>
      <components.Menu {...props}>{children}</components.Menu>
    </StyledMenu>
  );
};

export default SelectMenu;
