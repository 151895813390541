import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import { StyledButton } from './Button';
import styled, { css } from 'styled-components';

interface ButtonGroupProps {
  size?: keyof CustomTheme['buttonPaddingX'];
  isRounded?: boolean;
}

const ButtonGroup = styled(Base)<ButtonGroupProps>`
  ${({ size = 'base', isRounded, theme }) => css`
    --btn-group-border-radius: ${isRounded ? theme.borderRadius.rounded : theme.buttonBorderRadius[size]}px;
    display: inline-flex;
    border-radius: var(--btn-group-border-radius);
    box-shadow: ${theme.buttonBoxShadow};

    ${StyledButton} {
      --btn-padding-y: ${theme.buttonPaddingY[size]}px;
      --btn-padding-x: ${theme.buttonPaddingX[size]}px;
      --btn-font-size: ${theme.buttonFontSize[size]}px;
      --btn-border-radius: var(--btn-group-border-radius);
      --btn-box-shadow: none;

      & + ${StyledButton} {
        position: relative;
        margin-left: 0;
        border-left-width: 0;

        &::before {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          height: 50%;
          border-right: var(--btn-border-width) solid;
          opacity: 0.2;
        }
      }

      &:has(+ ${StyledButton}) {
        border-right-width: 0;
      }

      &:first-of-type:not(:last-of-type) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-of-type:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  `}
`;

export default ButtonGroup;
