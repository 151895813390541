import { StyledBadge } from '~/components/ui/Badge/Badge';
import styled, { css } from 'styled-components';

const CardBadge = styled(StyledBadge)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: ${theme.spacers[-5]}px;
    transform: translateY(-50%);
  `}
`;

export default CardBadge;
