import styled from 'styled-components';

const SelectDivider = styled.div`
  height: var(--select-menu-border-width);
  margin-top: var(--select-menu-padding-y);
  margin-bottom: var(--select-menu-padding-y);
  background-color: var(--select-menu-border-color);
  background-clip: content-box;
`;

export default SelectDivider;
