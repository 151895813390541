import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import { SelectOptionProps } from '..';
import { Text } from '~/components/type';

const SelectValueContainer = ({ children, ...props }: ValueContainerProps<SelectOptionProps>) => {
  //
  // Renders completely custom container
  //

  if (props?.hasValue && props?.selectProps?.onValueContainerRender) {
    const { getValue } = props;
    return (
      <components.ValueContainer {...props}>
        {props?.selectProps?.onValueContainerRender?.(getValue())}
        {(children as any[])?.[1]}
      </components.ValueContainer>
    );
  }

  //
  // Group values by label
  //

  if (props?.hasValue && props?.selectProps?.isMulti && props?.selectProps?.groupValuesByLabel) {
    const values = props.getValue();
    const label = props?.selectProps?.groupValuesByLabel;

    return (
      <components.ValueContainer className="react-select__value-container__grouped-value" {...props}>
        <span className="react-select__grouped-value">{values.length === 1 ? values[0].label : `${values.length} ${label}`}</span>
        {(children as any[])?.[1]!}
      </components.ValueContainer>
    );
  }

  //
  // Truncate values
  //

  if (props?.hasValue && props?.selectProps?.isMulti && props?.selectProps?.truncateValues) {
    const values = props.getValue();

    return (
      <components.ValueContainer className="react-select__value-container__grouped-value" {...props}>
        <Text utils={{ textTruncate: true }}>{values.map((value) => value.label).join(', ')}</Text>
        {(children as any[])?.[1]!}
      </components.ValueContainer>
    );
  }

  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

export default SelectValueContainer;
