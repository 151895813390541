import React from 'react';
import { CollapseBodyProps } from '~/components/ui/Collapse/CollapseBody';
import { Collapse } from 'react-collapse';
import styled, { css } from 'styled-components';
import TableCell from './TableCell';
import TableRow from './TableRow';

const StyledTableCollapseBody = styled.div`
  ${({ theme }) => css`
    flex: 0 0 100%;
    max-width: 100%;

    // Since TableCollapseBody creates a second row its parent TableRow should allow that
    ${TableRow}:has(&) {
      flex-wrap: wrap;
    }

    // Nested TableCell shouldn't behave as a flex by default
    ${TableCell} {
      position: relative; // Its content should go above the StretchedLink
      display: block !important;
      padding: ${theme.spacers[7]}px var(--row-gutter-x);
      border-top: var(--table-border-width) solid;
      border-image: linear-gradient(
          to right,
          transparent,
          transparent var(--row-gutter-x),
          var(--table-border-color) var(--row-gutter-x),
          var(--table-border-color) calc(100% - var(--row-gutter-x)),
          transparent calc(100% - var(--row-gutter-x)),
          transparent
        )
        1;
    }
  `}
`;

type TableCollapseBodyType = {
  (props: TableCollapseBodyProps & { ref?: React.Ref<Collapse> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledTableCollapseBody;
};

type TableCollapseBodyProps = CollapseBodyProps;

const TableCollapseBody: TableCollapseBodyType = React.forwardRef(({ isOpen, ...props }: TableCollapseBodyProps, ref: React.Ref<Collapse>) => {
  return (
    <StyledTableCollapseBody>
      <Collapse ref={ref} isOpened={isOpen} {...props} />
    </StyledTableCollapseBody>
  );
});

TableCollapseBody.styledComponent = StyledTableCollapseBody;
TableCollapseBody.displayName = 'TableCollapseBody';

export default TableCollapseBody;
