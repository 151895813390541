import React from 'react';
import Image, { ImageProps } from '../Image';
import styled, { css } from 'styled-components';
import { CustomTheme } from '~/@types/styled-components';

function parseThemeFromUrl(src: string): keyof CustomTheme['avatarImageBgColor'] {
  try {
    const url = new URL(src);
    const params = new URLSearchParams(url.search);
    return (params.get('theme') as keyof CustomTheme['avatarImageBgColor']) || 'dark';
  } catch (e) {
    return 'dark';
  }
}

type AvatarImageProps = {
  imageTheme?: keyof CustomTheme['avatarImageBgColor'];
  shouldClipGoogleLogo?: boolean;
};

const ImageWithRef = React.forwardRef((props: ImageProps & AvatarImageProps, ref: React.Ref<HTMLImageElement>) => <Image ref={ref} {...props} />);
ImageWithRef.displayName = 'ImageWithRef';

const AvatarImage = styled(ImageWithRef).attrs(({ src }) => ({
  imageTheme: src ? parseThemeFromUrl(src) : 'dark',
}))<AvatarImageProps>`
  ${({ imageTheme, isLoaded, shouldClipGoogleLogo, theme }) => css`
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-color: ${isLoaded && imageTheme ? theme.avatarImageBgColor[imageTheme] : theme.imageFallbackColor};
    border-radius: inherit;
    object-fit: contain;
    transform: ${shouldClipGoogleLogo ? 'scale(1.5)' : 'none'};
  `}
`;

export default AvatarImage;
