import React from 'react';
import { components, MultiValueProps } from 'react-select';
import { SelectOptionProps } from '..';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

function MultiValueLabel(props: MultiValueProps<SelectOptionProps>) {
  return (
    <>
      {props.data.user && <UserAvatar size="3xs" user={props.data.user} />}
      <components.MultiValueLabel {...props} />
    </>
  );
}

export default MultiValueLabel;
