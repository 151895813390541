import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const ChartContainer = styled(Base)`
  ${({ theme }) => css`
    position: relative;
    height: ${theme.chartHeight}px;

    .chart-tooltip {
      position: absolute;
      z-index: 0;
      min-width: 200px;
      padding: ${theme.spacers[5]}px;
      background-color: ${theme.colors.black};
      border-radius: ${theme.borderRadius.base}px;
      box-shadow: ${theme.boxShadow[3]};
      font-size: ${theme.fontSize.sm}px;
      color: ${theme.colors.white};
    }

    .chart-tooltip-line {
      position: absolute;
      z-index: 0;
      border-right: ${theme.borderWidth}px solid ${theme.colors.black};
      pointer-events: none;
    }

    .chart-tooltip-heading {
      margin-bottom: ${theme.spacers[4]}px;
      font-size: ${theme.fontSize.sm}px;
      text-align: center;
    }

    .chart-tooltip-row {
      display: flex;
      align-items: center;
    }

    .chart-tooltip-row + .chart-tooltip-row {
      margin-top: ${theme.spacers[2]}px;
    }

    .chart-tooltip-indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: ${theme.spacers[3]}px;
      border-radius: 50%;
    }

    .chart-tooltip-value {
      margin-left: auto;
    }
  `}
`;

export default ChartContainer;
