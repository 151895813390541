import { Base } from '~/components/layout';
import { check, dotsHorizontal as ellipsis, info01 as info, minus, x } from '~/components/vendor/Icon/icons';
import { CustomTheme } from '~/@types/styled-components';
import { formatSVG } from '~/helpers';
import styled, { css } from 'styled-components';
import { BaseProps } from '../layout/Base';

export const icons = { check, ellipsis, info, minus, x };

type Props = BaseProps & {
  size?: number;
};

export type StatusProps = Props & {
  icon?: keyof typeof icons | null;
  variant: keyof CustomTheme['colors'] | 'circle-dashed';
  progress?: number | null;
  mobile?: Props;
  desktop?: Props;
};

const Status = styled(Base).attrs<StatusProps>(({ size, theme }) => ({ size: size ? size : theme.statusSize }))<StatusProps>`
  ${({ size, icon, variant, progress, mobile = {}, desktop = {}, theme }) => css`
    --status-size: ${size}px;
    display: inline-flex;
    vertical-align: middle;
    min-width: var(--status-size);
    width: var(--status-size);
    height: var(--status-size);
    border-radius: ${theme.borderRadius.rounded}px;

    /*
     * Variant
     */

    ${variant &&
    variant !== 'circle-dashed' &&
    !progress &&
    `
      background-color: ${theme.colors[variant]};
    `}

    /*
     * Icon
     */

    ${icon &&
    variant !== 'circle-dashed' &&
    `
      background-repeat: no-repeat;
      background-image: ${
        icon ? formatSVG(`<svg viewBox="0 0 24 24" fill="none" color="white" xmlns="http://www.w3.org/2000/svg">${icons[icon]}</svg>`) : null
      };
      background-size: ${theme.statusIconSize};
      background-position: center center;
    `}

    /*
     * Progress
     */

    ${progress &&
    variant !== 'circle-dashed' &&
    `
      background-image: conic-gradient(
        ${theme.colors[variant]},
        ${theme.colors[variant]} ${progress}%,
        ${theme.colors.gray400} ${progress}%,
        ${theme.colors.gray400}
      );
    `}

    /*
     * Dashed
     */

    ${variant === 'circle-dashed' &&
    `
      border: ${theme.borderWidth}px dashed ${theme.colors.gray600};
    `}

    /*
     * Mobile
     */

    ${mobile?.size &&
    css`
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        --size: ${mobile.size}px;
      }
    `}

    /*
     * Desktop
     */

    ${desktop?.size &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --size: ${desktop.size}px;
      }
    `}
  `}
`;

export default Status;
