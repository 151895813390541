import React, { useEffect, useRef } from 'react';
import { MenuProps, useClick, useMenuState } from '@szhsin/react-menu';
import DropdownControlled, { DropdownControlledProps } from './DropdownControlled';

type DropdownProps = DropdownControlledProps & {
  menuButton?: MenuProps['menuButton'];
  onMenuChange?: ({ close, opening, open, closing }: { close?: boolean; opening?: boolean; open: boolean; closing?: boolean }) => void;
};

export default function Dropdown({ menuButton, onMenuChange, ...props }: DropdownProps) {
  const anchorRef = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  const clonedMenuButton = React.cloneElement(
    typeof menuButton === 'function' ? (menuButton({ open: menuState?.state === 'open' }) as JSX.Element) : (menuButton as JSX.Element),
    {
      ref: anchorRef,
      ...anchorProps,
    },
  );

  useEffect(() => {
    if (menuState.state !== undefined) {
      onMenuChange?.({ open: menuState.state === 'open', opening: menuState.state === 'opening' });
    }
  }, [menuState.state, onMenuChange]);

  return (
    <>
      {clonedMenuButton}
      <DropdownControlled anchorRef={anchorRef} onClose={() => toggleMenu(false)} {...menuState} {...props} />
    </>
  );
}
