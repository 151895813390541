import ReactCrop from 'react-image-crop';
import styled, { css } from 'styled-components';

const Crop = styled(ReactCrop)`
  ${({ theme }) => css`
    display: block;
    overflow: hidden;

    .ReactCrop__crop-selection {
      box-shadow: 0 0 0 9999em ${theme.colors.black90};
    }

    .ReactCrop__drag-handle {
      margin: 0 !important;
      border: 0 !important;

      &.ord-ne {
        transform: translate(50%, -50%);
      }

      &.ord-se {
        transform: translate(50%, 50%);
      }

      &.ord-sw {
        transform: translate(-50%, 50%);
      }

      &.ord-nw {
        transform: translate(-50%, -50%);
      }
    }

    .ReactCrop__drag-handle::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: ${theme.spacers[5]}px;
      height: ${theme.spacers[5]}px;
      border-radius: ${theme.borderRadius.rounded}px;
      border: ${theme.borderWidth}px solid ${theme.borderColor};
      background-color: ${theme.colors.white};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      transform: translate(-50%, -50%);
    }
  `}
`;

export default Crop;
