import Collapse from '~/components/ui/Collapse/Collapse';
import styled, { css } from 'styled-components';
import TableRow from './TableRow';

const TableCollapse = styled(Collapse)`
  ${({ theme }) => css`
    --table-collapse-offset: ${theme.tableCollapseOffset}px;

    &:has(.ReactCollapse--collapse[aria-hidden='false']) + & {
      margin-top: var(--table-collapse-offset);
    }

    &:has(.ReactCollapse--collapse[aria-hidden='false']) + & > ${TableRow} {
      border-top: 0;
    }
  `}
`;

export default TableCollapse;
