// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import Input, { InputProps } from '~/components/form/Input/Input';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, PropTypes } from 'react-places-autocomplete';
import styled, { css } from 'styled-components';
import { Coordinates } from '~/generated/graphql';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAqh4JvqXta4fV6ua9S1NFHzCjZGmePZ1c';

export const StyledDropdown = styled(Base)`
  ${() => css`
    position: relative;
  `}
`;

const StyledDropdownMenu = styled(Base)`
  ${({ theme }) => css`
    position: absolute;
    top: calc(100% + ${theme.dropdownMenuOffset}px);
    z-index: ${theme.zindex.dropdown};
    width: 100%;
    padding-top: ${theme.selectMenuPaddingY}px;
    padding-bottom: ${theme.selectMenuPaddingY}px;
    border: ${theme.selectMenuBorderWidth}px solid ${theme.selectMenuBorderColor};
    border-radius: ${theme.selectMenuBorderRadius}px;
    background-color: ${theme.selectMenuBg};
    box-shadow: ${theme.selectMenuBoxShadow};
  `}
`;

type StyledDropdownItemProps = {
  isActive?: boolean;
};

const StyledDropdownItem = styled(Base)<StyledDropdownItemProps>`
  ${({ theme, isActive }) => css`
    margin-right: ${theme.selectMenuItemMarginX}px;
    margin-left: ${theme.selectMenuItemMarginX}px;
    padding: ${theme.selectMenuItemPaddingY}px ${theme.selectMenuItemPaddingX}px;
    border-radius: ${theme.selectMenuItemBorderRadius}px;
    background-color: ${theme.selectMenuItemBg};
    font-size: ${theme.selectMenuItemFontSize}px;
    color: ${theme.selectMenuItemColor};
    cursor: pointer;

    :hover {
      background-color: ${theme.selectMenuItemHoverBg};
      color: ${theme.selectMenuItemHoverColor};
    }

    ${isActive &&
    `
      background-color: ${theme.selectMenuItemActiveBg};
      color: ${theme.selectMenuItemActiveColor};
    `}
  `}
`;

/**
 * We create CustomPlacesAutocomplete complete component to modify
 * behavior of blur action + Esc key. Usually users select from the dropdown
 * by TAB, and as a result we need to trigger handleSelect cb for such action.
 *
 * TODO:
 * 1) Somehow handle PASTE action
 *    The problem is only that onPaste we need to trigger suggestion fetch + try to find such item.
 *
 */

class CustomPlacesAutocomplete extends PlacesAutocomplete {
  handleInputOnBlur = () => {
    if (!this.mousedownOnSuggestion) {
      if (this.state.suggestions.some((suggestion) => suggestion.description === this.props.value)) {
        this.handleSelect(this.props.value, null, null);
      }

      this.clearSuggestions();
    }
  };

  handleInputKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.handleEnterKey();
        break;
      case 'ArrowDown':
        event.preventDefault(); // prevent the cursor from moving
        this.handleDownKey();
        break;
      case 'ArrowUp':
        event.preventDefault(); // prevent the cursor from moving
        this.handleUpKey();
        break;
      case 'Escape':
        if (this.state.suggestions.some((suggestion) => suggestion.description === this.props.value)) {
          this.handleSelect(this.props.value, null, null);
        }
        this.clearSuggestions();
        break;
    }
  };
}

export const getAddressComponent = (result: google.maps.GeocoderResult, type: string, version: 'short_name' | 'long_name' = 'short_name') => {
  const component = result.address_components.find((c) => c.types.includes(type));
  return component ? component[version] : 'N/A';
};

export const getBounds = (result: google.maps.GeocoderResult) => {
  const bounds = result.geometry.bounds;
  if (bounds) {
    const ne = { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() };
    const sw = { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() };
    return [ne, sw];
  }
  return;
};

export type PlaceDetails = {
  googleMapsUri?: string;
  websiteUri?: string;
  editorialSummary?: {
    text?: string;
  };
  photos?: {
    authorAttributions?: {
      photoUri?: string;
    }[];
    name?: string;
  }[];
};

const getPlaceDetails = async (placeId: string) => {
  const res = await fetch(
    `https://content-places.googleapis.com/v1/places/${placeId}?key=${GOOGLE_MAPS_API_KEY}&fields=googleMapsUri,websiteUri,editorialSummary,photos`,
  );
  return res.json() as Promise<PlaceDetails>;
};

type Props = Omit<PropTypes, 'children' | 'onSelect' | 'onChange'> & {
  inputProps: BaseHOCPropsWithoutRef<'input', InputProps>;
  onSelect: (coordinates: Coordinates, result: google.maps.GeocoderResult, placeDetails: PlaceDetails) => void;
  onChange: (value: string) => void;
  validateDefaultValue?: boolean;
};

/**
 * If you want to add this component to some page - don't forget to check if google script is included.
 * Also, if there will be a few components, you should write a fix for the callback name:
 * https://github.com/hibiken/react-places-autocomplete#googlecallbackname
 */

const SelectAddress = ({ value: defaultValue, onChange, onError, onSelect, inputProps, validateDefaultValue, ...props }: Props) => {
  const [value, setValue] = useState(defaultValue ?? '');

  const handleSelect = async (val: string) => {
    setValue(val);
    onChange(val);
    const results = await geocodeByAddress(val);
    const coordinates = await getLatLng(results[0]);
    const city = getAddressComponent(results[0], 'locality', 'long_name');
    const state = getAddressComponent(results[0], 'administrative_area_level_1');
    const cityState = `${city}, ${state}`;
    const shortAddress = val.split(',')[0];
    const postalCode = getAddressComponent(results[0], 'postal_code');
    const placeId = results[0].place_id;

    onSelect(
      {
        ...coordinates,
        cityState,
        shortAddress,
        postalCode,
        placeId,
        __typename: 'Coordinates',
      },
      results[0],
      onSelect.length === 3 ? await getPlaceDetails(placeId) : undefined,
    );
  };

  useEffect(() => {
    if (defaultValue) {
      if (validateDefaultValue) {
        handleSelect(defaultValue);
      } else {
        setValue(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <>
      <CustomPlacesAutocomplete
        value={value}
        googleCallbackName="googleInitCallback"
        onChange={(newValue) => {
          setValue(newValue);
          onChange(newValue);
        }}
        onError={onError}
        onSelect={handleSelect}
        {...props}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          // `disabled` needs to be `undefined`, because its default value breaks `autoFocus`
          <StyledDropdown>
            <Input {...getInputProps(inputProps)} disabled={inputProps.disabled ?? undefined} />
            {!!suggestions.length && (
              <StyledDropdownMenu>
                {suggestions.map((suggestion) => (
                  <StyledDropdownItem
                    isActive={suggestion.active}
                    data-testid={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </StyledDropdownItem>
                ))}
              </StyledDropdownMenu>
            )}
          </StyledDropdown>
        )}
      </CustomPlacesAutocomplete>
    </>
  );
};

export default SelectAddress;
