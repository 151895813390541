import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const GlobalAlerts = styled(Base)`
  ${({ theme }) => css`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${theme.zindex.alert};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: ${theme.spacers[5]}px;
    padding-left: ${theme.spacers[5]}px;
    margin-bottom: ${theme.spacers[10]}px;
    pointer-events: none;
  `}
`;

export default GlobalAlerts;
