import React from 'react';
import { CustomTheme } from '~/@types/styled-components';
import { Image } from '~/components/ui';
import { StretchedLink } from '../type';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import CardBody from './CardBody';
import CardHeader from './CardHeader';
import CardIcon from './CardIcon';
import CardLeft from './CardLeft';
import CardProgress from './CardProgress';
import CardTop from './CardTop';
import CardWrapper from './CardWrapper';
import styled, { css } from 'styled-components';

const icons = {
  box: '/img/icons/icon-box.svg',
  check: '/img/icons/icon-check.svg',
  clipboard: '/img/icons/icon-clipboard.svg',
  mail: '/img/icons/icon-mail.svg',
  time: '/img/icons/icon-time.svg',
  user: '/img/icons/icon-add-user.svg',
  write: '/img/icons/icon-write.svg',
  refresh: '/img/icons/icon-refresh.svg',
  exclamation: '/img/icons/icon-exclamation.svg',
};

export type CardIconType = keyof typeof icons;

export type StyledCardProps = {
  size?: keyof CustomTheme['cardSpacing'];
  isModalContent?: boolean;
  isInactive?: boolean;
  isFlush?: boolean;
  isDragging?: boolean;
};

export const StyledCard = styled(Base)<StyledCardProps>`
  ${({ size = 'base', isModalContent, isInactive, isFlush, isDragging, theme }) => css`
    --card-spacing: ${theme.cardSpacing[size]}px;
    --card-border-width: ${theme.cardBorderWidth}px;
    --card-border-color: ${theme.cardBorderColor};
    --card-border-radius: ${theme.cardBorderRadius}px;
    --card-border-radius-inner: calc(var(--card-border-radius) - var(--card-border-width));
    --card-bg: ${theme.cardBg};
    --card-box-shadow: ${theme.cardBoxShadow};
    --card-icon-size: ${theme.cardIconSize}px;
    position: relative;
    border: var(--card-border-width) solid var(--card-border-color);
    border-radius: var(--card-border-radius);
    background-color: var(--card-bg);
    box-shadow: var(--card-box-shadow);

    ${CardIcon} ~ & {
      margin-top: calc(var(--card-icon-size) / -2);
    }

    ${CardIcon} ~ & > ${CardBody} {
      padding-top: ${theme.spacers[10]}px;
    }

    ${CardLeft}:first-child {
      border-top-left-radius: var(--card-border-radius-inner);
      border-bottom-left-radius: var(--card-border-radius-inner);
    }

    ${CardLeft} + ${CardBody}:last-child {
      border-top-right-radius: var(--card-border-radius-inner);
      border-bottom-right-radius: var(--card-border-radius-inner);
    }

    ${CardTop}:first-child,
    ${CardBody}:first-child,
    ${CardHeader}:first-child {
      border-top-left-radius: var(--card-border-radius-inner);
      border-top-right-radius: var(--card-border-radius-inner);
    }

    ${CardTop}:last-child,
    ${CardBody}:last-child,
    ${CardHeader}:last-child {
      border-bottom-left-radius: var(--card-border-radius-inner);
      border-bottom-right-radius: var(--card-border-radius-inner);
    }

    ${isInactive &&
    `
      --card-bg: transparent;
      --card-box-shadow: none;
    `}

    ${isModalContent &&
    `
      z-index: 0;
      width: 100%;
      margin-bottom: 0;
      border-width: 0;
      pointer-events: auto;

      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}

    /*
     * Collapsible content
     */

    &:has(.ReactCollapse--collapse) {
      transition: ${theme.transition.base};
      transition-property: box-shadow;
    }

    &:has(.ReactCollapse--collapse[aria-hidden='false']) {
      --box-shadow: ${theme.boxShadow[4]};
    }

    /*
     * Flush
     */

    ${isFlush &&
    `
      --card-spacing: 0;
      --card-bg: none;
      --card-border-width: 0;
      --card-border-radius: 0;
      --card-box-shadow: none;

      ${StretchedLink}::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        background-color: ${theme.colors.gray100};
        border-radius: ${theme.cardBorderRadius}px;
        border: ${theme.cardBorderWidth}px solid ${theme.cardBorderColor};
        visibility: hidden;
      }

      &:hover ${StretchedLink}::before {
        visibility: visible;
      }
    `}

    /*
     * Dragging
     */

    ${isDragging &&
    `
      ${StretchedLink}::before {
        visibility: visible;
        pointer-events: auto;
      }
    `}
  `}
`;

type CardType = {
  <C extends React.ElementType = 'div'>(props: CardProps<C> & { ref?: React.Ref<HTMLDivElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type CardInnerProps = StyledCardProps & {
  icon?: CardIconType | React.ReactNode;
  progress?: number | false;
  variant?: keyof CustomTheme['colors'];
};

type CardProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, CardInnerProps>;

const Card: CardType = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, size = 'base', ...props }: CardProps<C>, ref: React.Ref<HTMLDivElement>) => {
    return props.icon ? (
      <CardWrapper>
        <CardIcon variant={props.variant}>{icons[props.icon] ? <Image src={icons[props.icon]} alt="Card icon" /> : <>{props.icon}</>}</CardIcon>
        <StyledCard ref={ref} as={as as React.ElementType} size={size} {...props}>
          {props.progress ? <CardProgress progress={props.progress} /> : <></>}
          {props.children}
        </StyledCard>
      </CardWrapper>
    ) : (
      <StyledCard ref={ref} as={as as React.ElementType} size={size} {...props}>
        {props.progress ? <CardProgress progress={props.progress} /> : <></>}
        {props.children}
      </StyledCard>
    );
  },
);

Card.displayName = 'Card';
export default Card;
