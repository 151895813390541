import React from 'react';
import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';
import TableRow from './TableRow';

const icon = (
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="1.20145" cy="1.225" rx="1.20145" ry="1.225" fill="currentColor" />
    <ellipse cx="1.20145" cy="6.12501" rx="1.20145" ry="1.225" fill="currentColor" />
    <ellipse cx="1.20145" cy="11.025" rx="1.20145" ry="1.225" fill="currentColor" />
    <ellipse cx="6.80814" cy="1.225" rx="1.20145" ry="1.225" fill="currentColor" />
    <ellipse cx="6.80814" cy="6.12501" rx="1.20145" ry="1.225" fill="currentColor" />
    <ellipse cx="6.80814" cy="11.025" rx="1.20145" ry="1.225" fill="currentColor" />
  </svg>
);

type TableHandleProps = {
  isHidden?: boolean;
};

const TableHandle = styled(Base).attrs(() => ({ role: 'button', children: icon }))<TableHandleProps>`
  ${({ isHidden }) => css`
    display: inline-flex;
    justify-content: center;
    min-width: 1em;
    cursor: inherit;

    /*
     * Hidden
     */

    ${isHidden &&
    `
      display: none;
    `}

    /*
     * Dragging
     */

    ${TableRow}[data-rbd-is-dragging] > * > & {
      visibility: visible !important;
    }
  `}
`;

export default TableHandle;
