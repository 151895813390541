import { CustomTheme } from '~/@types/styled-components';
import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type CardIconProps = {
  variant?: keyof CustomTheme['colors'];
};

const CardIcon = styled(Base)<CardIconProps>`
  ${({ variant = 'primary', theme }) => css`
    position: inherit;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.cardIconSize}px;
    height: ${theme.cardIconSize}px;
    margin-right: auto;
    margin-left: auto;
    background-image: linear-gradient(to bottom, ${theme.cardBorderColor} 50%, transparent 50%);
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: ${theme.cardBorderWidth}px;
      left: ${theme.cardBorderWidth}px;
      z-index: -1;
      width: calc(100% - ${theme.cardBorderWidth * 2}px);
      height: calc(100% - ${theme.cardBorderWidth * 2}px);
      background-color: ${theme.colors[variant]};
      border: ${theme.cardBorderWidth * 4}px solid ${theme.cardBg};
      border-radius: 50%;
    }
  `}
`;

export default CardIcon;
