import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const Progress = styled(Base)`
  ${({ theme }) => css`
    --progress-height: ${theme.progressHeight}px;
    --progress-width: ${theme.progressWidth}px;
    --progress-bg-color: ${theme.progressBg};
    display: flex;
    height: var(--progress-height);
    width: var(--progress-width);
    background-color: var(--progress-bg-color);
    border-radius: ${theme.borderRadius.rounded}px;
  `}
`;

export default Progress;
