import { Base } from '~/components/layout';
import { dropdownMenuIn } from '~/components/animations';
import { EditorProps } from './Editor';
import { StyledCard } from '~/components/card/Card';
import styled, { css } from 'styled-components';

type EditorContainerProps = {
  inputMaxHeight?: EditorProps['inputMaxHeight'];
  isCollapsible?: EditorProps['isCollapsible'];
  isDisabled?: EditorProps['isDisabled'];
  isFlush?: EditorProps['isFlush'];
  isReadOnly?: boolean;
};

const EditorContainer = styled(Base)<EditorContainerProps>`
  ${({ inputMaxHeight, isCollapsible, isFlush, isDisabled, isReadOnly, theme }) => css`
    --editor-min-height: ${theme.editorHeight};
    --editor-padding-y: ${theme.inputPaddingY.base}px;
    --editor-padding-x: ${theme.inputPaddingX.base}px;
    --editor-font-size: ${theme.fontSize.base}px;
    --editor-line-height: ${theme.lineHeight.base};
    --editor-background-color: ${theme.inputBg};
    --editor-border-radius: ${theme.inputBorderRadius.base}px;
    --editor-border-width: ${theme.inputBorderWidth}px;
    --editor-border-color: ${theme.inputBorderColor};
    --editor-box-shadow: ${theme.inputBoxShadow};
    --editor-color: ${theme.inputColor};
    --editor-disabled-color: ${theme.colors.gray600};
    --editor-placeholder-color: ${theme.inputPlaceholderColor};
    display: flex;
    flex-direction: column;
    min-height: ${`calc(var(--editor-min-height) * ${theme.lineHeight.base} + var(--editor-padding-y) * 2 + var(--editor-border-width) * 2)`};
    background-color: var(--editor-background-color);
    border-radius: var(--editor-border-radius);
    border: var(--editor-border-width) solid var(--editor-border-color);
    box-shadow: var(--editor-box-shadow);

    /* Max height */

    ${inputMaxHeight &&
    `
      max-height: ${inputMaxHeight}px;
      overflow-y: auto;
    `}

    /* Collapsible */

    ${isCollapsible &&
    `
      --editor-min-height: ${theme.editorHeightSm};
    `}

    /* Disabled */
    
    ${isDisabled &&
    `
      pointer-events: none;
    `}

    /* Flush */

    ${(isFlush || isReadOnly) &&
    `
      --editor-padding-y: 0;
      --editor-padding-x: 0;
      --editor-border-width: 0;
      --editor-box-shadow: none;
    `}

    /* Read only */

    ${isReadOnly &&
    `
      --editor-min-height: 0;
    `}

    /* Tip Tap */

    .tiptap {
      padding: var(--editor-padding-y) var(--editor-padding-x);
      line-height: var(--editor-line-height);
      font-size: var(--editor-font-size);
      outline: none;
      color: var(--editor-color);

      /* Placeholder */

      p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        height: 0;
        color: var(--editor-placeholder-color);
        pointer-events: none;
      }

      /* Markup */

      *:last-child {
        margin-bottom: 0;
      }

      h1 {
        margin-bottom: 0.33em;
        font-size: 1.125em;
        font-weight: ${theme.headingFontWeight.lg};
        line-height: ${theme.headingLineHeight.lg};
        letter-spacing: ${theme.headingLetterSpacing.lg};
      }

      p {
        margin-bottom: 1.25em;
      }

      ol,
      ul {
        padding-inline-start: 1.5em;
        margin-bottom: 1.25em;
      }

      ol li,
      ul li {
        padding-left: 0.1875em;
      }

      ul li {
        list-style-type: disc;
      }

      ul li + li,
      ol li + li {
        margin-top: 0.125em;
      }

      mark:not([data-color]) {
        border: ${theme.borderWidth}px solid ${theme.colors.warning};
        border-radius: ${theme.borderRadius.sm}px;
        background-color: rgba(244, 198, 34, 0.3);
        padding-top: ${theme.spacers[1]};
      }

      img {
        max-width: 100%;
        min-width: 100px;
        height: auto;
        border-radius: ${theme.borderRadius.base}px;
        background-color: ${theme.imageFallbackColor};
        -webkit-user-select: none !important;
        cursor: default;
      }

      img[src*='data:'] {
        opacity: 0.5;
      }

      .node-file,
      .node-imageZoom {
        margin-bottom: 1.25em;
      }

      &.ProseMirror-focused .node-imageZoom.ProseMirror-selectednode img:not([src*='data:']),
      &.ProseMirror-focused .node-file.ProseMirror-selectednode [data-node-view-wrapper]:not(.is-uploading) ${StyledCard} {
        box-shadow: 0 0 0 3px ${theme.colors.primary40};
      }

      &.ProseMirror-focused .node-file.ProseMirror-selectednode [data-node-view-wrapper]:not(.is-uploading) ${StyledCard} {
        border-color: transparent;
      }

      ul.list-checked li {
        position: relative;
        list-style-type: none;
      }

      ul.list-checked li::before {
        content: '';
        position: absolute;
        top: calc((1.5em - ${theme.checkboxSize}px) / 2);
        left: -1.5em;
        min-width: ${theme.checkboxSize}px;
        width: ${theme.checkboxSize}px;
        height: ${theme.checkboxSize}px;
        margin-right: ${theme.checkboxGutter}px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-color: ${theme.checkboxIndicatorActiveBg};
        background-image: ${theme.checkboxIndicatorIcon};
        background-position: center center;
        background-size: 10px 8px;
      }
    }

    /* Toolbar */

    .tippy-box[data-state='visible'] {
      animation: ${dropdownMenuIn} 0.1s ease-out;
    }
  `}
`;

export default EditorContainer;
