import { ScriptableTooltipContext } from 'chart.js';
import Color from 'color';
import getOrCreateTooltip from './getOrCreateTooltip';

export default function externalTooltipHandler(context: ScriptableTooltipContext<any>) {
  const { chart, tooltip } = context;

  if ('type' in chart.config && chart.config.type !== 'line' && chart.config.type !== 'bar') {
    return;
  }

  const { tooltipEl, tooltipLineEl } = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.visibility = 'hidden';
    tooltipLineEl.style.visibility = 'hidden';

    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    titleLines.forEach((title) => {
      const titleFormatted = title.replace(/,(?![ ])/g, ' ');

      const headingEl = document.createElement('h6');
      const text = document.createTextNode(titleFormatted);

      headingEl.classList.add('chart-tooltip-heading');
      headingEl.appendChild(text);
      tooltipEl.appendChild(headingEl);
    });

    bodyLines.forEach((body, i) => {
      if (tooltip.dataPoints[i].dataset.tooltipHidden) {
        return;
      }

      const rowEl = document.createElement('div');

      rowEl.classList.add('chart-tooltip-row');
      rowEl.innerHTML = body.join('');

      if ('type' in chart.config && chart.config.type === 'line') {
        const color = tooltip.labelColors[i];
        const indicatorEl = document.createElement('span');

        indicatorEl.classList.add('chart-tooltip-indicator');
        indicatorEl.style.backgroundColor = Color(color.backgroundColor).alpha(1).string();
        rowEl.prepend(indicatorEl);
      }

      tooltipEl.appendChild(rowEl);
    });
  }

  // Position tooltip
  const position = chart.canvas.getBoundingClientRect();
  const caretX = tooltip.caretX;
  const caretY = tooltip.caretY;

  const tooltipClientLeft = position.left + caretX - tooltipEl.offsetWidth / 2;
  const tooltipClientRight = position.left + caretX + tooltipEl.offsetWidth / 2;

  const tooltipLeft =
    tooltipClientLeft < 0
      ? caretX + Math.abs(tooltipClientLeft)
      : tooltipClientRight > window.innerWidth
      ? caretX - (tooltipClientRight - window.innerWidth)
      : caretX;

  tooltipEl.style.top = '0';
  tooltipEl.style.left = `${tooltipLeft}px`;
  tooltipEl.style.transform = 'translateX(-50%) translateY(-100%)';
  tooltipEl.style.visibility = 'visible';

  tooltipLineEl.style.top = '0';
  tooltipLineEl.style.bottom = '56px';
  if ('type' in chart.config && chart.config.type == 'bar' && chart.data.datasets.length === 1) {
    tooltipLineEl.style.maxHeight = `${caretY}px`;
  }
  tooltipLineEl.style.left = `${caretX}px`;
  tooltipLineEl.style.transform = 'translateX(-50%)';
  tooltipLineEl.style.visibility = 'visible';
}
