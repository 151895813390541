import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const Tabs = styled(Base)`
  ${() => css`
    display: grid;
  `}
`;

export default Tabs;
