import React from 'react';
import { DocumentRequestStatus } from '~/generated/graphql';
import Badge, { BadgeProps } from '~/components/ui/Badge/Badge';
import { BaseProps } from '../layout/Base';

type FileStatusType = {
  (props: FileStatusProps & { ref?: React.Ref<HTMLSpanElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type FileStatusInnerProps = {
  isLender: boolean;
  status?: DocumentRequestStatus | 'uploading';
  utils?: BaseProps;
};

type FileStatusProps = BadgeProps & FileStatusInnerProps;

const FileStatus: FileStatusType = React.forwardRef(({ status, utils = {}, isLender, ...props }: FileStatusProps, ref: React.Ref<HTMLSpanElement>) => {
  const getVariant = () => {
    switch (status) {
      case 'approved':
        return 'success';
      case 'rejected':
        return !isLender ? 'danger' : 'gray600';
      case 'review':
        return !isLender ? 'primary' : 'gray600';
      default:
        return 'gray600';
    }
  };

  const getName = () => {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'rejected':
        return !isLender ? 'Rejected' : 'Requested';
      case 'review':
        return !isLender ? 'In Review' : 'Requested';
      default:
        return !isLender ? 'No File' : 'Requested';
    }
  };

  if (status === 'uploading') {
    return null;
  }

  return (
    <Badge ref={ref} utils={{ bgColor: getVariant(), ...utils }} {...props}>
      {getName()}
    </Badge>
  );
});

FileStatus.displayName = 'FileStatus';
export default FileStatus;
