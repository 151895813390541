import { SubMenu, SubMenuProps } from '@szhsin/react-menu';
import { submenuSelector } from '@szhsin/react-menu/style-utils';
import { Theme as theme } from '~/theme';
import Icon from '~/components/vendor/Icon';
import styled, { css } from 'styled-components';

const dropdownSubmenuAttrs: SubMenuProps = {
  gap: theme.dropdownItemMarginX,
  menuClassName: submenuSelector.name.slice(1),
  overflow: 'auto',
};

const DropdownSubmenu = styled(SubMenu).attrs(() => dropdownSubmenuAttrs)`
  ${({ theme }) => css`
    .szh-menu__item {
      --dropdown-submenu-padding-y: ${theme.dropdownItemPaddingY}px;
      --dropdown-submenu-padding-x: ${theme.dropdownItemPaddingX}px;
      --dropdown-submenu-margin-x: ${theme.dropdownItemMarginX}px;
      --dropdown-submenu-font-size: ${theme.dropdownItemFontSize}px;
      --dropdown-submenu-bg: ${theme.dropdownItemBg};
      --dropdown-submenu-border-radius: ${theme.dropdownItemBorderRadius}px;
      --dropdown-submenu-hover-bg: ${theme.dropdownItemHoverBg};
      --dropdown-submenu-color: ${theme.dropdownItemColor};
      --dropdown-submenu-hover-color: ${theme.dropdownItemHoverColor};
      position: relative;
      display: flex;
      align-items: center;
      padding: var(--dropdown-submenu-padding-y) var(--dropdown-submenu-padding-x);
      margin-right: var(--dropdown-submenu-margin-x);
      margin-left: var(--dropdown-submenu-margin-x);
      background-color: var(--dropdown-submenu-bg);
      border-radius: var(--dropdown-submenu-border-radius);
      font-size: var(--dropdown-submenu-font-size);
      color: var(--dropdown-submenu-color);

      ${Icon.styledComponent} {
        margin-right: ${theme.spacers[4]}px;
        color: ${theme.colors.gray500};
        display: flex;
      }
    }

    .szh-menu__item--open,
    .szh-menu__item--hover {
      background-color: var(--dropdown-submenu-hover-bg);
      color: var(--dropdown-submenu-hover-color);

      &::before {
        content: '';
        position: absolute;
        inset: 0 calc(-1 * var(--dropdown-submenu-margin-x));
      }
    }
  `}
`;

export default DropdownSubmenu;
