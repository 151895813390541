import { BaseNav } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const NavPills = styled(BaseNav)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export default NavPills;
