import React, { useState, useEffect } from 'react';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { Transition, TransitionStatus } from 'react-transition-group';
import Alert from '~/components/ui/Alert';
import styled, { css } from 'styled-components';

type StyledAlertProps = {
  status: TransitionStatus;
};

const alertOpacity: Record<TransitionStatus, string> = {
  entered: 'var(--global-alert-opacity-to)',
  entering: 'var(--global-alert-opacity-to)',
  exiting: 'var(--global-alert-opacity-from)',
  exited: 'var(--global-alert-opacity-from)',
  unmounted: 'var(--global-alert-opacity-from)',
};

const alertMarginBottom: Record<TransitionStatus, string> = {
  entered: 'var(--global-alert-margin-bottom-to)',
  entering: 'var(--global-alert-margin-bottom-to)',
  exiting: 'var(--global-alert-margin-bottom-from)',
  exited: 'var(--global-alert-margin-bottom-from)',
  unmounted: 'var(--global-alert-margin-bottom-from)',
};

const StyledAlert = styled(Alert)<StyledAlertProps>`
  ${({ status, theme }) => css`
    --global-alert-opacity-from: 0;
    --global-alert-opacity-to: 1;
    --global-alert-margin-bottom-from: -${theme.spacers[4]}px;
    --global-alert-margin-bottom-to: 0;
    margin: 0 auto ${alertMarginBottom[status]};
    text-align: center;
    pointer-events: auto;
    opacity: ${alertOpacity[status]};
    transition: ${theme.transition.base};
    transition-property: margin-bottom, opacity;

    & + &,
    & + div > & {
      margin-top: ${theme.spacers[3]}px;
    }
  `}
`;

type GlobalAlertType = {
  <C extends React.ElementType = 'div'>(props: GlobalAlertProps<C> & { ref?: React.Ref<HTMLDivElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type GlobalAlertInnerProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

type GlobalAlertProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, GlobalAlertInnerProps>;

const TIMEOUT = 4000;
const DURATION = 150;

const GlobalAlert: GlobalAlertType = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, isOpen, onClose, ...props }: GlobalAlertProps<C>, ref: React.Ref<HTMLDivElement>) => {
    const [isIn, setIn] = useState(false);

    useEffect(() => {
      setIn(Boolean(isOpen));
      if (isOpen && onClose) {
        const timeout = setTimeout(() => setIn(false), TIMEOUT);
        return () => clearTimeout(timeout);
      }
    }, [isOpen, onClose]);

    return (
      <Transition in={isIn} timeout={DURATION} onExited={onClose} unmountOnExit>
        {(status) => (
          <StyledAlert as={as as React.ElementType} ref={ref} status={status} {...props}>
            {props.children}
          </StyledAlert>
        )}
      </Transition>
    );
  },
);

GlobalAlert.displayName = 'GlobalAlert';
export default GlobalAlert;
