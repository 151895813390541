import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import Input from './Input';
import styled, { css } from 'styled-components';

const InputAutowidthPlaceholder = styled(Base).attrs(() => ({ 'aria-hidden': true }))`
  ${() => css`
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    pointer-events: none;
  `}
`;

const InputAutowidthInput = styled(Input).attrs(() => ({ isFlush: true }))`
  ${() => css`
    grid-column: 1;
    grid-row: 1;
    width: 0px;
    min-width: 100%;
  `}
`;

type InputAutowidthProps = {
  inputProps?: BaseHOCPropsWithoutRef<'input'>;
};

const InputAutowidth = styled(Base).attrs<InputAutowidthProps>(({ inputProps }) => ({
  children: (
    <>
      <InputAutowidthPlaceholder>{inputProps?.value ? inputProps.value.toString().replaceAll(' ', '\u00A0') : <>&nbsp;</>}</InputAutowidthPlaceholder>
      <InputAutowidthInput {...inputProps} />
    </>
  ),
}))<InputAutowidthProps>`
  ${() => css`
    display: inline-grid;
  `}
`;

export default InputAutowidth;
