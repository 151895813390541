import Base from '../layout/Base';
import styled, { css } from 'styled-components';

const CardImageTop = styled(Base)`
  ${({ theme }) => css`
    margin-top: calc(var(--card-border-width) * -1);
    margin-right: calc(var(--card-border-width) * -1);
    margin-left: calc(var(--card-border-width) * -1);
    width: calc(100% + (var(--card-border-width) * 2));
    border-top-right-radius: var(--card-border-radius-inner);
    border-top-left-radius: var(--card-border-radius-inner);
    background-color: ${theme.imageFallbackColor};

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  `}
`;

export default CardImageTop;
