import React from 'react';
import styled from 'styled-components';
import { Collapse, CollapseProps } from 'react-collapse';

export type CollapseBodyProps = {
  isOpen: boolean;
  children: React.ReactNode;
};

const CollapseBody = styled(Collapse as unknown as React.FC<Partial<CollapseProps>>).attrs((props) => ({
  isOpened: (props as unknown as CollapseBodyProps).isOpen,
}))<CollapseBodyProps>``;

export default CollapseBody;
