import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const RatioItem = styled(Base)`
  ${() => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}
`;

export default RatioItem;
