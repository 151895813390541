import React from 'react';
import Icon, { IconProps } from '~/components/vendor/Icon';
import { TableRow, TableCell } from '~/components/table';
import Input from './Input';
import InputAddon from './InputAddon';
import InputGroup from './InputGroup';
import styled, { css } from 'styled-components';
import { lock05 } from '~/components/vendor/Icon/icons';

//
// Input lock
//

type InputLockType = {
  isLocked?: boolean;
};

const IconWithRef = React.forwardRef((props: Omit<IconProps, 'icon'> & { icon?: string }, ref: React.Ref<HTMLOrSVGElement>) => (
  <Icon ref={ref} {...(props as IconProps)} />
));
IconWithRef.displayName = 'IconWithRef';

const InputLock = styled(IconWithRef).attrs(() => ({
  icon: lock05,
}))<InputLockType>`
  ${({ isLocked = false, theme }) => css`
    --input-lock-color: ${theme.colors.gray500};
    --input-lock-hover-color: ${theme.colors.gray700};
    --input-lock-fill-opacity: 0.2;
    color: var(--input-lock-color);

    /*
     * Hover
     */

    &:hover {
      color: var(--input-lock-hover-color);
    }

    &:hover > rect {
      fill-opacity: var(--input-lock-fill-opacity);
    }

    /*
     * Disabled
     */

    ${InputGroup} > ${Input}:disabled + ${InputAddon} > & {
      --input-lock-color: ${theme.colors.primary};
      --input-lock-hover-color: ${theme.colors.primary};
      --input-lock-fill-opacity: 0.4;
    }

    /*
     * Inside a table
     */

    ${TableRow}:not(:hover) > ${TableCell} > ${InputGroup} > ${Input}:not(:disabled) + ${InputAddon} > & {
      visibility: hidden;
    }

    /*
     * Locked
     */

    ${isLocked &&
    `
      --input-lock-color: ${theme.colors.primary};
      --input-lock-hover-color: ${theme.colors.primary};
      --input-lock-fill-opacity: 0.4;

      ${TableRow}:not(:hover) > ${TableCell} > ${InputGroup} > ${Input} + ${InputAddon} > && {
        visibility: visible;
      }
    `}
  `}
`;

export default InputLock;
