import { BaseUl } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

type ListProps = {
  gutter?: keyof CustomTheme['spacers'];
  isUnstyled?: boolean;
};

const List = styled(BaseUl)<ListProps>`
  ${({ gutter = 0, isUnstyled = true, theme }) => css`
    --list-gutter: ${theme.spacers[gutter]}px;
    margin-bottom: 0;
    padding-left: ${isUnstyled ? 0 : '1.5em'};
    list-style-type: ${isUnstyled ? 'none' : 'disc'};
  `}
`;

export default List;
