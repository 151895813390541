import styled, { css } from 'styled-components';

const DropzoneIcon = styled.img`
  ${({ theme }) => css`
    width: ${theme.fontSize.mobile['2xl']}px;
    height: ${theme.fontSize.mobile['2xl']}px;
  `}
`;

export default DropzoneIcon;
