import React, { useEffect, useState } from 'react';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import Input, { InputProps } from './Input';
import InputAddon from './InputAddon';
import InputGroup from './InputGroup';
import InputLock from './InputLock';

type InputLockableType = {
  <C extends React.ElementType = 'div'>(props: InputLockableProps<C> & { ref?: React.Ref<HTMLInputElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type InputLockableInnerProps = InputProps & {
  lockStatus?: boolean;
  isLockEnabled?: boolean;
  onLockClick?: (isLocked: boolean) => void;
};

type InputLockableProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, InputLockableInnerProps>;

const InputLockable: InputLockableType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, lockStatus = false, isLockEnabled = false, onLockClick, ...props }: InputLockableProps<C>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [isLocked, setLocked] = useState(lockStatus);

    useEffect(() => {
      setLocked(lockStatus);
    }, [lockStatus]);

    if (isLockEnabled) {
      return (
        <InputGroup size={props?._size || props?.size}>
          <Input ref={ref} as={as as React.ElementType} {...props} />
          <InputAddon>
            <InputLock
              role="button"
              onClick={() => {
                setLocked((prevState) => {
                  const newValue = !prevState;
                  onLockClick?.(newValue);
                  return newValue;
                });
              }}
              isLocked={isLocked}
            />
          </InputAddon>
        </InputGroup>
      );
    }

    return <Input ref={ref} as={as as React.ElementType} placeholder="Input" disabled={isLocked} {...props} />;
  },
);

InputLockable.displayName = 'InputLockable';
export default InputLockable;
