import { BaseSpan } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

//
// Styled badge
//

export type BadgeBaseProps = {
  size?: keyof CustomTheme['badgeFontSize'];
};

const BadgeBase = styled(BaseSpan)<BadgeBaseProps>`
  ${({ size = 'base', theme }) => css`
    --badge-height: ${theme.badgeHeight[size]}px;
    --badge-padding-x: ${theme.badgePaddingX[size]}px;
    --badge-bg: ${theme.colors.gray600};
    --badge-font-size: ${theme.badgeFontSize[size]}px;
    --badge-count-size: ${theme.badgeCountSize[size]}px;
    --badge-count-bg: ${theme.colors.gray500};
    --badge-count-font-size: ${theme.fontSize['2xs']}px;
    --badge-close-size: ${theme.badgeCloseSize[size]}px;
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    height: var(--badge-height);
    padding-right: var(--badge-padding-x);
    padding-left: var(--badge-padding-x);
    background-color: ${theme.colors.gray600};
    white-space: nowrap;
    font-size: var(--badge-font-size);
    line-height: 1;
    vertical-align: middle;
    border-radius: ${theme.borderRadius.rounded}px;
    color: ${theme.colors.white};
  `}
`;

export default BadgeBase;
