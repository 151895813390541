import styled, { css } from 'styled-components';
import { Base } from '~/components/layout';

const FileIcon = styled(Base)`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${theme.fileIconWidth}px;
    height: ${theme.fileIconHeight}px;
    text-transform: uppercase;
    font-size: ${theme.fontSize['3xs']}px;
    font-weight: ${theme.fontWeight.bold};
    background: linear-gradient(-135deg, transparent 10%, ${theme.colors.gray200} 5%);
    color: ${theme.colors.gray700};
  `}
`;

export default FileIcon;
