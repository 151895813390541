import { BaseLi } from '~/components/layout/Base';
import styled from 'styled-components';

const ListItem = styled(BaseLi)`
  & + & {
    margin-top: var(--list-gutter);
  }
`;

export default ListItem;
