import { MenuButton } from '@szhsin/react-menu';
import Icon from '~/components/vendor/Icon';
import styled from 'styled-components';

const DropdownButton = styled(MenuButton)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  > ${Icon.styledComponent} {
    z-index: 1;
    position: relative;
  }
`;

export default DropdownButton;
