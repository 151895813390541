import { formatSVG } from '~/helpers';
import { Heading } from '~/components/type';
import styled, { css } from 'styled-components';

type ListHeadingProps = {
  isAsc?: boolean;
  isDesc?: boolean;
  isSortable?: boolean;
};

const ListHeading = styled(Heading)<ListHeadingProps>`
  ${({ isAsc, isDesc, isSortable, theme }) => css`
    font-size: ${theme.fontSize['2xs']}px;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.08em;
    color: ${theme.colors.gray700};

    /* 
     * Sortable
     */

    ${isSortable &&
    `
      &::after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 8px;
        margin-left: ${theme.spacers[2]}px;
        background-image: ${formatSVG(`<svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M2.5 0L0 3H5L2.5 0Z' fill='${isAsc ? theme.colors.primary : theme.colors.gray500}' />
          <path d='M2.5 8L0 5H5L2.5 8Z' fill='${isDesc ? theme.colors.primary : theme.colors.gray500}' />
        </svg>`)};
      }

      &:hover::after {
        background-image: ${formatSVG(`<svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M2.5 0L0 3H5L2.5 0Z' fill='${isAsc ? theme.colors.primary : isDesc ? theme.colors.gray700 : theme.colors.gray500}' />
          <path d='M2.5 8L0 5H5L2.5 8Z' fill='${isDesc ? theme.colors.primary : theme.colors.gray700}' />
        </svg>`)};
      }
    `}
  `}
`;

export default ListHeading;
