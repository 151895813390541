import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

type AvatarTitleProps = {
  variant?: keyof CustomTheme['avatarVariant'];
};

const AvatarTitle = styled(Base)<AvatarTitleProps>`
  ${({ variant = 'base', theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-color: ${variant ? theme.avatarVariant[variant] : theme.avatarVariant.base};
    border-radius: inherit;
  `}
`;

export default AvatarTitle;
