import Base from '~/components/layout/Base';
import CardBadge from './CardBadge';
import styled, { css } from 'styled-components';
import { Ratio, RatioItem } from '../ui';

const CardTop = styled(Base)`
  ${({ theme }) => css`
    position: inherit;

    > *:not(${CardBadge}) {
      border-radius: inherit;
      margin: calc(var(--card-border-width) * -1) calc(var(--card-border-width) * -1) auto calc(var(--card-border-width) * -1);
    }

    > ${Ratio} > ${RatioItem}, > ${Ratio} > ${RatioItem} > * {
      border-radius: inherit;
    }

    > ${CardBadge} {
      top: 100%;
      right: auto;
      bottom: auto;
      left: ${theme.spacers[5]}px;
      border: ${theme.borderWidth * 2}px solid ${theme.cardBg};
    }
  `}
`;

export default CardTop;
