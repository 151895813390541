import { Flex } from '~/components/layout';
import SidenavBackdrop from './SidenavBackdrop';
import SidenavContent from './SidenavContent';
import SidenavRounding from './SidenavRounding';
import styled, { css } from 'styled-components';

export type SidenavProps = {
  isActive: boolean;
  onClose: () => void;
};

const Sidenav = styled(Flex).attrs<SidenavProps>(({ isActive, onClose, children }) => ({
  children: (
    <>
      <SidenavContent isActive={isActive}>{children}</SidenavContent>
      <SidenavRounding isActive={isActive} />
      <SidenavBackdrop isActive={isActive} onClick={onClose} />
    </>
  ),
  isActive,
}))<SidenavProps>`
  ${({ isActive, theme }) => css`
    --sidenav-width: ${theme.sidenavWidth}px;
    --sidenav-bg: ${theme.sidenavBg};
    --sidenav-border-radius: ${theme.sidenavBorderRadius}px;
    position: fixed;
    z-index: ${theme.zindex.fixed};
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100dvh;
    width: var(--sidenav-width);

    /*
     * Mobile
     */

    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      pointer-events: ${isActive ? 'auto' : 'none'};
      transform: translateX(${isActive ? '0%' : '-100%'});
      transition: ${theme.transition.base};
      transition-property: transform;

      & ~ main,
      & ~ header {
        transition: ${theme.transition.base};
        transition-property: transform;
        transform: ${isActive ? `translateX(${theme.sidenavWidth}px)` : undefined};
      }

      ${isActive &&
      `
        #__next:has(&) {
          overflow-x: clip;
        }
      `}
    }

    /*
     * Desktop
     */

    @media (min-width: ${theme.breakpoints.desktop}px) {
      & ~ main,
      & ~ header {
        margin-left: ${theme.sidenavWidth}px;
      }
    }
  `}
`;

export default Sidenav;
