import React from 'react';
import { CSSProperties } from 'react';
import { BaseSVG, BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type StyledIconProps = {
  size?: CSSProperties['width'];
};

const StyledIcon = styled(BaseSVG)<StyledIconProps>`
  ${({ size }) => css`
    min-width: ${size};
    vertical-align: middle;
  `}
`;

type IconType = {
  <C extends React.ElementType = 'svg'>(props: IconProps<C> & { ref?: React.Ref<HTMLOrSVGElement> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledIcon;
};

export type IconInnerProps = StyledIconProps & {
  icon: string;
};

export type IconProps<C extends React.ElementType = 'svg'> = BaseHOCPropsWithoutRef<C, IconInnerProps>;

const Icon: IconType = React.forwardRef(
  <C extends React.ElementType = 'svg'>({ as, icon, size = '1em', ...props }: IconProps<C>, ref: React.Ref<HTMLOrSVGElement>) => {
    if (!icon) return;

    return (
      <StyledIcon
        as={as as React.ElementType}
        ref={ref}
        size={size}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        dangerouslySetInnerHTML={{ __html: icon }}
        {...props}
      />
    );
  },
);

Icon.displayName = 'Icon';
Icon.styledComponent = StyledIcon;
export default Icon;
