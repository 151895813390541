import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const CardFooter = styled(Base)`
  ${({ theme }) => css`
    padding: calc(var(--card-spacing) / 2) var(--card-spacing);
    border-top: ${theme.cardBorderWidth}px solid ${theme.cardBorderColor};
  `}
`;

export default CardFooter;
