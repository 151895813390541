import Base, { BaseProps } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type DropdownMenuInnerProps = BaseProps & {
  isFlush?: boolean;
};

type DropdownMenuProps = DropdownMenuInnerProps & {
  mobile?: DropdownMenuInnerProps;
  desktop?: DropdownMenuInnerProps;
};

const DropdownMenu = styled(Base)<DropdownMenuProps>`
  ${({ isFlush, mobile = {}, desktop = {}, theme }) => css`
    --dropdown-menu-width: ${theme.dropdownMenuWidth};
    --dropdown-menu-padding-y: ${theme.dropdownMenuPaddingY}px;
    --dropdown-menu-border-width: ${theme.dropdownMenuBorderWidth}px;
    --dropdown-menu-border-color: ${theme.dropdownMenuBorderColor};
    --dropdown-menu-border-radius: ${theme.dropdownMenuBorderRadius}px;
    --dropdown-menu-bg: ${theme.dropdownMenuBg};
    --dropdown-menu-shadow: ${theme.dropdownMenuBoxShadow};
    min-width: var(--dropdown-menu-width);
    max-height: inherit;
    overflow: inherit;
    border: var(--dropdown-menu-border-width) solid var(--dropdown-menu-border-color);
    border-radius: var(--dropdown-menu-border-radius);
    background-color: var(--dropdown-menu-bg);
    box-shadow: var(--dropdown-menu-shadow);
    padding-top: var(--dropdown-menu-padding-y);
    padding-bottom: var(--dropdown-menu-padding-y);
    scrollbar-width: thin;
    user-select: none;

    /*
     * Flush
     */

    ${isFlush &&
    `
      --dropdown-menu-padding-y: 0;
      --dropdown-menu-border-width: 0;
      --dropdown-menu-shadow: none;
    `}

    /*
     * Mobile
     */

    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      ${mobile.isFlush &&
      `
        --dropdown-menu-max-height: none;
        --dropdown-menu-padding-y: 0;
        --dropdown-menu-border-width: 0;
        --dropdown-menu-shadow: none;
      `}
    }

    /*
     * Desktop
     */

    @media (min-width: ${theme.breakpoints.desktop}px) {
      ${desktop.isFlush &&
      `
        --dropdown-menu-max-height: none;
        --dropdown-menu-padding-y: 0;
        --dropdown-menu-border-width: 0;
        --dropdown-menu-shadow: none;
      `}
    }
  `}
`;

export default DropdownMenu;
