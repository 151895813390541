import { BaseA } from '~/components/layout/Base';
import { Icon } from '~/components/vendor';
import styled, { css } from 'styled-components';

type NavPillsLinkProps = {
  isActive?: boolean;
};

const NavPillsLink = styled(BaseA)<NavPillsLinkProps>`
  ${({ isActive, theme }) => css`
    display: inline-flex;
    align-items: center;
    padding: ${theme.spacers[3]}px ${theme.spacers[4]}px;
    font-size: ${theme.fontSize.sm}px;
    border-radius: ${theme.borderRadius.base}px;
    color: ${theme.colors.gray800};
    transition: ${theme.transition.base};

    & + & {
      margin-top: ${theme.spacers[2]}px;
    }

    /* 
     * Icons
     */

    & > svg,
    & > ${Icon.styledComponent} {
      margin-right: ${theme.spacers[5]}px;
      font-size: ${theme.fontSize.base}px;
      color: ${theme.colors.gray500};
      transition: inherit;
    }

    /* 
     * Hover
     */

    &:hover {
      color: ${theme.colors.primary};
      background-color: ${theme.colors.primary10};
    }

    &:hover > svg,
    &:hover > ${Icon.styledComponent} {
      color: inherit;
    }

    /* 
     * Active
     */

    ${isActive &&
    `
      color: ${theme.colors.primary};
      background-color: ${theme.colors.primary10};

      & > svg,
      & > ${Icon.styledComponent} {
        color: inherit;
      }
    `}
  `}
`;

export default NavPillsLink;
