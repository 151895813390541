import ReactDOM from 'react-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled, { css } from 'styled-components';

const StyledTooltip = styled(ReactTooltip).attrs(() => ({ 'data-tooltip-position-strategy': 'fixed' }) as any)`
  ${({ theme }) => css`
    --rt-opacity: 1;
    z-index: ${theme.zindex.tooltip} !important;
    max-width: ${theme.tooltipMaxWidth}px !important;
    background: ${theme.tooltipBg} !important;
    border-radius: ${theme.tooltipBorderRadius}px !important;
    box-shadow: ${theme.tooltipBoxShadow} !important;
    padding: ${theme.tooltipPaddingY}px ${theme.tooltipPaddingX}px !important;
    font-size: ${theme.tooltipFontSize}px !important;
    text-align: center !important;
    color: ${theme.tooltipColor} !important;
    transition: ${theme.transition.base} !important;
    transition-property: opacity !important;
    overflow-wrap: break-word !important;

    /*
     * Caret
     */

    &::before {
      display: none !important;
    }

    &::after {
      width: ${theme.tooltipArrowSize}px !important;
      height: ${theme.tooltipArrowSize}px !important;
      background-color: ${theme.tooltipBg};
      border-radius: ${theme.tooltipArrowRadius}px;
      box-shadow: ${theme.tooltipBoxShadow};
      border: 0 !important;
      transform: rotate(45deg);
      transform-origin: center center;
    }

    &.place-top {
      ::after {
        top: 100% !important;
        left: 50% !important;
        margin-top: -${theme.tooltipArrowSize / 2}px !important;
        margin-left: -${theme.tooltipArrowSize / 2}px !important;
      }
    }

    &.place-right {
      ::after {
        top: 50% !important;
        right: 100% !important;
        margin-top: -${theme.tooltipArrowSize / 2}px !important;
        margin-right: -${theme.tooltipArrowSize / 2}px !important;
      }
    }

    &.place-bottom {
      ::after {
        bottom: 100% !important;
        left: 50% !important;
        margin-bottom: -${theme.tooltipArrowSize / 2}px !important;
        margin-left: -${theme.tooltipArrowSize / 2}px !important;
      }
    }

    &.place-left {
      ::after {
        top: 50% !important;
        left: 100% !important;
        margin-top: -${theme.tooltipArrowSize / 2}px !important;
        margin-left: -${theme.tooltipArrowSize / 2}px !important;
      }
    }
  `}
`;

const Tooltip = ({ ...props }) => ReactDOM.createPortal(<StyledTooltip {...props} />, document.body);

export default Tooltip;
