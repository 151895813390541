import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

export type DropzoneContainerProps = {
  isDragActive?: boolean;
};

const DropzoneContainer = styled(Base)<DropzoneContainerProps>`
  ${({ isDragActive, theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.dropzoneContainerSpacing}px;
    border-radius: ${theme.dropzoneContainerBorderRadius}px;
    border: ${theme.dropzoneContainerBorderWidth}px dashed ${theme.dropzoneContainerBorderColor};
    overflow: hidden;
    transition: ${theme.transition.base};
    cursor: pointer;

    &:hover {
      border-color: ${theme.dropzoneContainerActiveBorderColor};
      background-color: ${theme.dropzoneContainerActiveBg};
    }

    ${isDragActive &&
    `
      border-color: ${theme.dropzoneContainerActiveBorderColor};
      background-color: ${theme.dropzoneContainerActiveBg};
    `}
  `}
`;

export default DropzoneContainer;
