import { Text } from '../type';
import styled, { css } from 'styled-components';

const EmptyCell = styled(Text).attrs(() => ({ children: '--' }))`
  ${({ theme }) => css`
    color: ${theme.colors.gray600};
  `}
`;

export default EmptyCell;
