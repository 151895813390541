import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const DropzoneLabel = styled(Base)`
  ${({ theme }) => css`
    display: inline-block;
    margin-left: ${theme.spacers[4]}px;
  `}
`;

export default DropzoneLabel;
