import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import { getDomain } from './getDomain';

type GetLogoUrlArgs = {
  logo?: string | null;
  urls?: string[] | null;
  website?: string;
};

export function getLogoUrl({ logo, urls, website }: GetLogoUrlArgs): string | null {
  if (logo?.startsWith('http') || logo?.includes('brandfetch') || logo?.includes('blob')) {
    return logo;
  }

  if (logo && !logo.includes('logo.clearbit.com')) {
    return `${getBaseApiUrl()}/api/lender/file/${logo}?width=96&height=96`;
  }

  if (website) {
    return `//logo.clearbit.com/${getDomain(website)}?size=80`;
  }

  if (urls?.[0]) {
    return `//logo.clearbit.com/${getDomain(urls?.[0])}?size=80`;
  }

  return null;
}
