import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

interface ProgressBarProps {
  value?: number;
}

const ProgressBar = styled(Base)<ProgressBarProps>`
  ${({ value = 0, theme }) => css`
    --progress-bar-bg-color: ${theme.colors.primary};
    display: flex;
    height: 100%;
    width: ${value}%;
    border-radius: inherit;
    background-color: var(--progress-bar-bg-color);
  `}
`;

export default ProgressBar;
