import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

//
// Input dismiss
//

const InputDismiss = styled(Base)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: translate(-70%, 70%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${theme.colors.gray500};
    background-image: ${theme.checkboxIndicatorCloseIcon};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0.5rem 0.5rem;

    &:hover {
      background-color: ${theme.colors.danger};
    }
  `}
`;

export default InputDismiss;
