import { BadgeBase } from '../Badge';
import styled, { css } from 'styled-components';

const AvatarBadge = styled(BadgeBase)`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.spacers[-3]}px;
    bottom: ${theme.spacers[-3]}px;
    border: ${theme.borderWidth * 2}px solid ${theme.colors.gray100};
  `}
`;

export default AvatarBadge;
