import { useRouter } from 'next/router';
import { useImmediateEffect } from '~/hooks/useImmediateEffect';

type RedirectProps = {
  to: string;
  as?: string;
  replace?: boolean;
};

const Redirect = ({ to, as, replace }: RedirectProps) => {
  const router = useRouter();
  useImmediateEffect(() => {
    if (replace) {
      router.replace(to, as);
      return;
    }
    router.push(to, as);
  }, []);
  return null;
};

export default Redirect;
