import React, { useEffect, useState } from 'react';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled from 'styled-components';

type StyledCollapseProps = {
  isOpen: boolean;
};

export const StyledCollapse = styled(Base)<StyledCollapseProps>``;

type CollapseType = {
  <C extends React.ElementType = 'div'>(props: CollapseProps<C> & { ref?: React.Ref<HTMLDivElement> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledCollapse;
};

type CollapseInnerProps = {
  children: (isOpen: boolean, setOpen: (isOpen: boolean) => void) => React.ReactNode;
  hasError?: boolean;
  isDefaultOpen?: boolean;
  isForceOpen?: boolean;
};

type CollapseProps<C extends React.ElementType = 'div'> = Omit<BaseHOCPropsWithoutRef<C>, 'children'> & CollapseInnerProps;

const Collapse: CollapseType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, children, hasError, isDefaultOpen = false, isForceOpen, ...props }: CollapseProps<C>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [isOpen, setOpen] = useState<boolean>(isDefaultOpen);

    useEffect(() => {
      if ((hasError && !isOpen) || isForceOpen) {
        setOpen(true);
      }
    }, [hasError, isOpen, isForceOpen]);

    return (
      <StyledCollapse as={as as React.ElementType} ref={ref} isOpen={isOpen} {...props}>
        {children(isOpen, (isOpen) => {
          if (!isOpen && hasError) {
            return;
          }
          setOpen(isOpen);
        })}
      </StyledCollapse>
    );
  },
);

Collapse.displayName = 'Collapse';
Collapse.styledComponent = StyledCollapse;
export default Collapse;
