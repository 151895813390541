import { ScriptableContext, ScaleOptions } from 'chart.js';

export default function externalTooltipLabelCallback(ctx: ScriptableContext<any>) {
  const { chart } = ctx;
  if ('type' in chart.config && chart.config.type !== 'line' && chart.config.type !== 'bar') {
    return;
  }
  const label = ctx.dataset.label;
  const scale = ctx.chart.scales[ctx.dataset.yAxisID || 'y'];
  const callback = (scale.options as ScaleOptions).ticks!.callback;
  const formattedScale = callback!.apply(scale, [ctx.parsed.y, 0, []]);
  const content = `<span class="chart-tooltip-axis">${label}</span><span class="chart-tooltip-value">${formattedScale}</span>`;
  return content;
}
