import { Icon } from '../vendor';
import { star } from '../vendor/Icon/icons';
import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type Props = {
  rating?: number | null;
};

const Rating = styled(Base).attrs<Props>(({ rating = 0 }) => ({
  children: (
    <>
      <Icon icon={star} />
      <Icon icon={star} />
      <Icon icon={star} />
      <Icon icon={star} />
      <Icon icon={star} />
    </>
  ),
  role: 'img',
  'aria-label': `${rating} out of 5 stars`,
}))<Props>`
  ${({ rating, theme }) => css`
    --rating-item-color: ${theme.ratingItemColor};
    --rating-item-active-color: ${theme.ratingItemActiveColor};
    display: flex;
    flex-wrap: nowrap;
    color: var(--rating-item-color);

    & > ${Icon.styledComponent} > polygon {
      fill: currentColor;
    }

    & > ${Icon.styledComponent}:nth-child(-n + ${rating}) {
      color: var(--rating-item-active-color);
    }
  `}
`;

export default Rating;
