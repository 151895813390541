import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import { Heading } from '~/components/type';
import { StyledCard } from './Card';
import CardBody from './CardBody';
import styled, { css } from 'styled-components';

type CardGroupProps = {
  size?: keyof CustomTheme['cardSpacing'];
};

const CardGroup = styled(Base)<CardGroupProps>`
  ${({ size = 'sm', theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacers[8]}px;

    & > ${Heading} {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: ${theme.spacers[8]}px;
      margin-bottom: ${theme.spacers[8]}px;
      white-space: nowrap;

      &::after {
        content: '';
        width: 100%;
        margin-left: ${theme.spacers[5]}px;
        border-top: ${theme.borderWidth}px solid ${theme.borderColor};
      }
    }

    & > ${StyledCard} {
      ${CardBody} {
        padding-right: 0;
        padding-left: 0;
        margin-right: ${theme.cardSpacing[size]}px;
        margin-left: ${theme.cardSpacing[size]}px;
      }

      ${CardBody} + ${CardBody},
      ${CardBody} + * ${CardBody} {
        border-top: ${theme.cardBorderWidth}px solid ${theme.cardBorderColor};
      }
    }

    & > ${StyledCard} + ${StyledCard} {
      margin-top: ${theme.spacers[8]}px;
    }

    & + & > ${StyledCard}:first-child::after, & > ${StyledCard} + ${StyledCard}::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: ${theme.spacers[6]}px;
      height: ${theme.spacers[8]}px;
      border-right: ${theme.cardBorderWidth}px solid ${theme.cardBorderColor};
    }
  `}
`;

export default CardGroup;
