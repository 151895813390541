import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const ModalContent = styled(Base)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.white};
    pointer-events: auto;
  `}
`;

export default ModalContent;
