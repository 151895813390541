import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const HeaderWrapper = styled(Base)`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    left: 0;
    z-index: ${theme.zindex.header};
    width: 100%;
    margin-bottom: ${theme.spacers[10]}px;
  `}
`;

export default HeaderWrapper;
