import { BaseNav } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type TopnavProps = {
  size?: 'auto';
  isStuck?: boolean;
};

const Topnav = styled(BaseNav)<TopnavProps>`
  ${({ size, isStuck, theme }) => css`
    --topnav-size: ${size === 'auto' ? 'auto' : `${theme.topnavSize.sm}px`};
    --topnav-padding-x: ${size === 'auto' ? undefined : `${theme.topnavPaddingX.sm}px`};
    --topnav-bg: ${theme.topnavBg};
    position: relative;
    display: flex;
    align-items: center;
    height: var(--topnav-size);
    min-height: var(--topnav-size);
    padding-left: var(--topnav-padding-x);
    padding-right: var(--topnav-padding-x);
    background-color: var(--topnav-bg);

    /*
     * Size
     */

    ${size !== 'auto' &&
    `
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --topnav-size: ${theme.topnavSize.base}px;
        --topnav-padding-x: ${theme.topnavPaddingX.base}px;
      }
    `}

    /*
     * Stuck
     */

    ${isStuck !== undefined &&
    `
      --topnav-border-width: ${theme.topnavBorderWidth}px;
      --topnav-border-color: ${theme.topnavBorderColor};

      &::after {
        content: '';
        position: absolute;
        inset: 100% 0 0 0;
        border-bottom: var(--topnav-border-width) solid var(--topnav-border-color);
        transition: ${theme.transition.base};
        transition-property: border-color;

        @media (min-width: ${theme.breakpoints.desktop}px) {
          opacity: ${isStuck ? undefined : 0};
        }
      }
    `}
  `}
`;

export default Topnav;
