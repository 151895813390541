import { progressInfinite } from '~/components/animations';
import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type CardProgressProps = {
  progress: number;
};

const CardProgress = styled(Base).attrs<CardProgressProps>(({ progress }) => ({
  style: {
    maxWidth: progress === -1 ? '100%' : progress + '%',
  },
}))<CardProgressProps>`
  ${({ progress, theme }) => css`
    position: absolute;
    bottom: -${theme.cardBorderWidth}px;
    left: 0;
    width: 100%;
    height: ${theme.cardBorderRadius}px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: ${theme.borderWidth * 3}px solid ${theme.colors.primary};
      animation: ${progress === -1 ? progressInfinite : null} 1.5s infinite cubic-bezier(0.63, 0.2, 0.44, 0.97);
    }
  `}
`;

export default CardProgress;
