import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

export type DropzoneContentProps = {
  isHidden?: boolean;
};

const DropzoneContent = styled(Base)<DropzoneContentProps>`
  ${({ isHidden }) => css`
    display: flex;
    align-items: center;
    visibility: ${isHidden ? 'hidden' : 'visible'};
  `}
`;

export default DropzoneContent;
