import styled from 'styled-components';

export type DropzoneImageProps = {
  imageUrl?: boolean;
};

const DropzoneImage = styled.img<DropzoneImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default DropzoneImage;
