import { StyledBadge } from '~/components/ui/Badge/Badge';
import styled, { css } from 'styled-components';

type TopnavLinkProps = {
  isActive?: boolean;
};

const TopnavLink = styled(StyledBadge).attrs<TopnavLinkProps>(() => ({ size: 'lg' }))<TopnavLinkProps>`
  ${({ count, countVariant, isActive, theme }) => css`
    --topnav-link-spacer: ${theme.topnavLinkSpacer}px;
    --topnav-link-font-size: ${theme.topnavLinkFontSize}px;
    --topnav-link-color: ${theme.topnavLinkColor};
    --topnav-link-hover-bg: ${theme.topnavLinkHoverBg};
    --topnav-link-hover-color: ${theme.topnavLinkHoverColor};
    --topnav-link-active-bg: ${theme.topnavLinkActiveBg};
    --topnav-link-active-color: ${theme.topnavLinkActiveColor};
    font-size: var(--topnav-link-font-size);
    background-color: transparent;
    color: var(--topnav-link-color);

    & + & {
      margin-left: var(--topnav-link-spacer);
    }

    /*
     * Hover
     */

    &:hover {
      background-color: var(--topnav-link-hover-bg);
      color: var(--topnav-link-hover-color);
    }

    /*
     * Active
     */

    ${isActive &&
    `
      background-color: var(--topnav-link-active-bg) !important;
      color: var(--topnav-link-active-color) !important;
    `}

    /*
     * Count
     */

    ${count !== undefined &&
    `
      &:after {
        background-color: ${countVariant ? theme.colors[countVariant] : isActive ? theme.colors.primary : theme.colors.gray500};
      }
    `}
  `}
`;

export default TopnavLink;
