import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

export type ModalDialogProps = {
  size?: keyof CustomTheme['modalSize'];
  layout?: 'vertical' | 'horizontal' | 'fullscreen';
};

const ModalDialog = styled(Base)<ModalDialogProps>`
  ${({ size = 'base', layout, theme }) => css`
    --modal-dialog-max-width: ${theme.modalSize[size]}px;
    --modal-dialog-margin-y: ${theme.modalDialogMarginY}px;
    --modal-dialog-margin-x: auto;
    --modal-dialog-animation-offset: 50px;
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    max-width: var(--modal-dialog-max-width);
    min-height: calc(100% - var(--modal-dialog-margin-y) * 2);
    margin: var(--modal-dialog-margin-y) var(--modal-dialog-margin-x);
    transform: translateY(var(--modal-dialog-animation-offset));
    transition: inherit;
    transition-property: max-width, transform;
    pointer-events: none;

    /*
     * Mobile
     */

    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      --modal-dialog-max-width: none;
      align-items: flex-end;
      margin-top: calc(var(--modal-dialog-margin-y) * 2);
      margin-bottom: 0;
    }

    /* 
     * Layouts
     */

    ${layout === 'horizontal' &&
    `
      --modal-dialog-margin-y: 0;
      align-items: stretch;
      height: 100%;

      @media (min-width: ${theme.breakpoints.desktop}px) {
        &&& {
          --modal-dialog-max-width: ${theme.modalSizeHorizontal}px;
          margin-right: 0;
          margin-left: auto;
          transform: translate(100%, 0);
        }
      }

      & > * {
        height: 100%;
        overflow-y: auto;
        overflow-x: clip;

        @media (max-width: ${theme.breakpoints.desktop - 1}px) {
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }

        @media (min-width: ${theme.breakpoints.desktop}px) {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
    `}

    ${layout === 'fullscreen' &&
    `
      &&& {
        --modal-dialog-max-width: none;
        --modal-dialog-margin-y: 0;
        --modal-dialog-margin-x: 0;
        display: block;
        width: 100%;
        height: 100%;
        transform: translateY(100vh);
      }
    `}
  `}
`;

export default ModalDialog;
