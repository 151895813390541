import React, { useId } from 'react';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';
import { CustomTheme } from '~/@types/styled-components';

const StyledSwitch = styled(Base)`
  ${() => css`
    display: inline-flex;
    align-items: center;
  `};
`;

type StyledSwitchInputProps = {
  _size: keyof CustomTheme['switchWidth'];
};

const StyledSwitchInput = styled.input<StyledSwitchInputProps>`
  ${({ _size, theme }) => css`
    width: ${theme.switchWidth[_size]}px;
    height: ${theme.switchHeight[_size]}px;
    border-radius: ${theme.borderRadius.rounded}px;
    background-color: ${theme.checkboxIndicatorBg};
    background-repeat: no-repeat;
    background-image: ${theme.switchBackgroundImage};
    background-size: ${theme.switchHeight[_size]}px ${theme.switchHeight[_size]}px;
    background-position: top left;
    transition: ${theme.transition.base};
    transition-property: background;
    appearance: none;

    &:checked {
      background-color: ${theme.checkboxIndicatorActiveBg};
      background-position: top right;
    }

    &:disabled {
      opacity: ${theme.switchDisabledOpacity};
    }
  `};
`;

const StyledSwitchLabel = styled(Base)`
  ${({ theme }) => css`
    margin-left: ${theme.spacers[3]}px;
  `};
`;

type SwitchType = {
  <C extends React.ElementType = 'div'>(props: SwitchProps<C> & { ref?: React.Ref<HTMLInputElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type SwitchInnerProps = {
  size?: keyof CustomTheme['switchWidth'];
  inputProps?: BaseHOCPropsWithoutRef<'input'>;
  labelProps?: BaseHOCPropsWithoutRef<'label'>;
};

type SwitchProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, SwitchInnerProps>;

const Switch: SwitchType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, children, size = 'base', inputProps, labelProps, ...props }: SwitchProps<C>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const generatedId = useId();
    const inputId = inputProps?.id ? inputProps.id : generatedId;

    return (
      <StyledSwitch as={as as React.ElementType} {...props}>
        <StyledSwitchInput ref={ref} id={inputId} type="checkbox" _size={size} {...inputProps} />
        {children && (
          <StyledSwitchLabel as="label" htmlFor={inputId} {...labelProps}>
            {children}
          </StyledSwitchLabel>
        )}
      </StyledSwitch>
    );
  },
);

Switch.displayName = 'Switch';
export default Switch;
