import { BaseNav } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import NavLink from './NavLink';
import styled, { css } from 'styled-components';

export type NavProps = {
  size?: keyof CustomTheme['navPaddingY'];
  isFlush?: boolean;
};

const Nav = styled(BaseNav)<NavProps>`
  ${({ size = 'base', isFlush, theme }) => css`
    --nav-padding-y: ${theme.navPaddingY[size]}px;
    --nav-padding-x: ${theme.navPaddingX[size]}px;
    --nav-link-gutter: ${size === 'sm' ? theme.navPaddingX[size] * 2 : theme.navPaddingX[size] * 1.5}px;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    /*
     * Nav link
     */

    ${NavLink}::before {
      display: ${isFlush && 'none'};
    }
  `}
`;

export default Nav;
