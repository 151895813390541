import { css } from 'styled-components';
import { dropdownMenuIn, dropdownMenuOut } from '~/components/animations';
import { menuSelector, menuItemSelector, menuDividerSelector, submenuSelector, menuArrowSelector } from '@szhsin/react-menu/style-utils';
import { Theme } from '~/theme';

type DropdownStyleProps = {
  theme: typeof Theme;
};

const DropdownStyles = ({ theme }: DropdownStyleProps) => css`
  --dropdown-color: ${theme.colors.black};
  --dropdown-menu-bg: ${theme.dropdownMenuBg};
  --dropdown-menu-border-width: ${theme.dropdownMenuBorderWidth}px;
  --dropdown-menu-border-color: ${theme.dropdownMenuBorderColor};
  --dropdown-menu-arrow-size: ${theme.dropdownMenuArrowSize}px;
  top: 0;
  left: 0;
  color: var(--dropdown-color);
  pointer-events: none;

  //
  // Menu
  //

  ${menuSelector.name} {
    pointer-events: auto;
    z-index: ${theme.zindex.dropdown};
    background: none;
    border: none;
  }

  //
  // Animation (top level menu only)
  //

  ${menuSelector.name}:not(${submenuSelector.name})${menuSelector.name}--dir-top {
    transform-origin: bottom center;
  }

  ${menuSelector.name}:not(${submenuSelector.name})${menuSelector.name}--dir-right {
    transform-origin: center left;
  }

  ${menuSelector.name}:not(${submenuSelector.name})${menuSelector.name}--dir-bottom {
    transform-origin: top center;
  }

  ${menuSelector.name}:not(${submenuSelector.name})${menuSelector.name}--dir-left {
    transform-origin: center right;
  }

  ${menuSelector.name}:not(${submenuSelector.name})${menuSelector.name}--state-opening {
    animation: ${dropdownMenuIn} 0.1s ease-out;
  }

  ${menuSelector.name}:not(${submenuSelector.name})${menuSelector.name}--state-closing {
    animation: ${dropdownMenuOut} 0.1s ease-out forwards;
  }

  //
  // Divider
  //

  ${menuDividerSelector.name} {
    margin: 0.5rem 0.625rem;
  }

  //
  // Submenu
  //

  ${menuItemSelector.submenu} {
    position: relative;

    &::after {
      content: '';
      width: ${theme.spacers[3]}px;
      height: ${theme.spacers[4]}px;
      margin-left: auto;
      background-color: ${theme.colors.gray400};
      clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
      position: absolute;
      right: 0.625rem;
    }
  }

  /* Submenu does't have animation, so has to open/close immediately */
  ${submenuSelector.name}${menuSelector.name}--state-opening {
    display: block !important;
  }

  ${submenuSelector.name}${menuSelector.name}--state-closing {
    display: none !important;
  }

  //
  // Arrow
  //

  ${menuArrowSelector.name} {
    z-index: auto;
    width: calc(var(--dropdown-menu-arrow-size) * 2);
    height: calc(var(--dropdown-menu-arrow-size) * 2);
    background: none;
    border: 0;
    transform: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      clip-path: polygon(0% 0%, 50% 50%, 100% 0%);
    }

    &::before {
      background-color: var(--dropdown-menu-border-color);
    }

    &::after {
      transform: translateY(calc(var(--dropdown-menu-border-width) * -2));
      background-color: var(--dropdown-menu-bg);
    }
  }

  ${menuArrowSelector.dirTop} {
    top: 100%;
    bottom: auto;
    transform: translateX(-50%);
  }

  ${menuArrowSelector.dirRight} {
    right: 100%;
    left: auto;
    transform: translateY(-50%) rotate(90deg);
  }

  ${menuArrowSelector.dirBottom} {
    top: auto;
    bottom: 100%;
    transform: translateX(-50%) rotate(180deg);
  }

  ${menuArrowSelector.dirLeft} {
    right: auto;
    left: 100%;
    transform: translateY(-50%) rotate(-90deg);
  }
`;

export default DropdownStyles;
