import React from 'react';
import { components, SingleValueProps } from 'react-select';
import { SelectOptionProps } from '..';

function SingleValue(props: SingleValueProps<SelectOptionProps>) {
  if (props?.hasValue && props?.selectProps?.onValueRender) {
    return <components.SingleValue {...props}>{props.selectProps.onValueRender(props.data)}</components.SingleValue>;
  }
  return <components.SingleValue {...props} />;
}

export default SingleValue;
