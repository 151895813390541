import React from 'react';
import { formatSVG } from '~/helpers';
import { components, MultiValueProps } from 'react-select';
import styled, { css } from 'styled-components';
import { SelectOptionProps } from '..';

type StyledMultiValueContainerProps = {
  isInvalid?: boolean;
};

const StyledMultiValueContainer = styled.div<StyledMultiValueContainerProps>`
  ${({ isInvalid, theme }) => css`
    //
    // Invalid
    //

    ${isInvalid &&
    `
      .react-select__multi-value {
        background-color: ${theme.colors.danger10};
        color: ${theme.colors.danger};
      }

      .react-select__multi-value__remove {
        background-image: ${formatSVG(theme.selectMultiValueCloseIconInvalid)};

        &:hover {
          background-color: ${theme.selectMultiValueCloseHoverBgInvalid};
        }
      }
    `}
  `}
`;

function SelectMultiValueContainer(props: MultiValueProps<SelectOptionProps>) {
  return (
    <>
      <StyledMultiValueContainer
        data-tooltip-id="GlobalTooltip"
        data-tooltip-content={props.data.value}
        data-tooltip-hidden={!props.data.shouldShowValueTooltip}
        isInvalid={props.data.isInvalid}
      >
        <components.MultiValueContainer {...props} />
      </StyledMultiValueContainer>
    </>
  );
}

export default SelectMultiValueContainer;
