import React from 'react';
import Icon from '~/components/vendor/Icon';
import styled, { css } from 'styled-components';
import { Base } from '~/components/layout';
import { moreVertical } from '~/components/vendor/Icon/icons';

const Toggle = styled(Base).attrs(() => ({ children: <Icon icon={moreVertical} />, role: 'button' }))`
  ${({ theme }) => css`
    display: inline-flex;
    vertical-align: middle;
    font-size: ${theme.fontSize.base}px;
    color: ${theme.colors.gray600};

    &:hover {
      color: ${theme.colors.gray700};
    }
  `}
`;

export default Toggle;
