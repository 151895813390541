import { Base } from '~/components/layout';
import styled, { css, CSSProperties } from 'styled-components';

type TableResponsiveInnerProps = {
  span?: CSSProperties['width'];
};

const TableResponsiveInner = styled(Base)<TableResponsiveInnerProps>`
  ${({ span, theme }) => css`
    --table-responsive-min-width: ${typeof span !== 'undefined' ? span : `${theme.tableResponsiveMinWidth}px`};
    min-width: var(--table-responsive-min-width);
    overflow-x: clip;
  `}
`;

type TableResponsiveProps = TableResponsiveInnerProps;

const TableResponsive = styled(Base).attrs<TableResponsiveProps>(({ children, ...props }) => ({
  children: <TableResponsiveInner {...props}>{children}</TableResponsiveInner>,
}))<TableResponsiveProps>`
  ${({ theme }) => css`
    overflow-x: auto;

    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      margin-right: calc(var(--container-gutter-x) * -1);
      padding-right: var(--container-gutter-x);
    }
  `}
`;

export default TableResponsive;
