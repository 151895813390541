import { StretchedLink } from '~/components/type';
import styled, { css } from 'styled-components';
import TableItem from './TableItem';
import TableRow from './TableRow';
import Table from './Table';
import { Icon } from '../vendor';
import TableHandle from './TableHandle';

type TableIconProps = {
  isAbsolute?: boolean;
  isFlush?: boolean;
  isHover?: boolean;
  isHoverToggle?: boolean;
  isTableHover?: boolean;
};

const TableIcon = styled(TableItem).attrs<TableIconProps>(() => ({ span: 'auto' }))<TableIconProps>`
  ${({ isAbsolute, isFlush, isHover, isHoverToggle, isTableHover, theme }) => css`
    position: relative; // make sure they are clickable and not overlapped by StretchedLink
    z-index: 1; //
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: var(--table-icon-font-size);
    height: calc(var(--table-cell-height) - var(--table-cell-padding-y) * 2);
    padding-top: var(--table-cell-padding-y);
    padding-bottom: var(--table-cell-padding-y);

    /* 
     * Icon
     */

    & > ${TableHandle}, & > ${Icon.styledComponent} {
      font-size: var(--table-icon-font-size);
      color: ${theme.colors.gray500};
    }

    /* 
     * First child
     */

    &:first-child {
      margin-right: var(--row-offset-x);
      padding-right: calc(var(--row-gutter-x) / 2);
    }

    /* 
     * Last child
     */

    &:last-child {
      margin-left: var(--row-offset-x);
      padding-left: calc(var(--row-gutter-x) / 2);
    }

    /*
     * Absolute
     */

    ${isAbsolute &&
    `
      @media (min-width: ${theme.breakpoints.desktop}px) {
        position: absolute !important;
        right: 100%;
        border-top: 0 !important;

        & ~ ${TableItem} ${StretchedLink}::before,
        & ~ ${TableItem} ${StretchedLink}::after {
          left: calc((var(--table-icon-font-size) + var(--row-gutter-x) / 2) * -1);
        }
      }
    `}

    /*
     * Flush
     */

    ${isFlush &&
    `
      ${TableRow}:not(:first-child):not(:has(${StretchedLink}):hover):not(:has(${StretchedLink}):hover + ${TableRow}) > &&& {
        margin-top: calc(var(--table-border-width) * -1);
        border-top: var(--table-border-width) solid var(--table-bg);
      }
    `}

    /*
     * Hover
     */

    ${isHover &&
    `
      @media (min-width: ${theme.breakpoints.desktop}px) {
        ${TableRow}[data-rbd-is-dragging-over] > & > *,
        ${TableRow}:not(:hover) > & > * {
          visibility: hidden;
        }
      }
    `}

    /*
     * Hover toggle
     */

    ${isHoverToggle &&
    ` 
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        width: calc(1em * 2 + var(--row-gutter-x) / 2);
        
        & > *:last-child {
          margin-left: calc(var(--row-gutter-x) / 2);
        }
      }

      @media (min-width: ${theme.breakpoints.desktop}px) {
        ${TableRow}[data-rbd-is-dragging-over]:not([data-rbd-is-dragging]) > & > *:first-child,
        ${TableRow}:not([data-rbd-is-dragging]):not(:hover) > & > *:first-child {
          display: none;
        }

        ${TableRow}[data-rbd-is-dragging] > & > ${TableHandle} + *:last-child,
        ${TableRow}:not([data-rbd-is-dragging-over]):hover > & > *:last-child {
          display: none;
        }
      }
    `}

    /*
     * Table hover
     */

    ${isTableHover &&
    `
      @media (min-width: ${theme.breakpoints.desktop}px) {
        & > * {
          visibility: hidden;
        }

        ${Table}:hover ${TableRow} > & > * {
          visibility: visible;
        }
      }
    `}
  `}
`;

export default TableIcon;
