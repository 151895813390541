import { StretchedLink } from '~/components/type';
import Col, { ColProps } from '../layout/Col';
import styled, { css } from 'styled-components';

export type TableItemProps = ColProps & {
  isHidden?: boolean;
  isStuckLeft?: boolean;
};

const TableItem = styled(Col)<TableItemProps>`
  ${({ isHidden, isStuckLeft, theme }) => css`
    display: flex;
    flex-wrap: wrap;

    /*
     * Hidden
     */

    ${(isHidden === true || isHidden === false) &&
    `
      & > * {
        transition: ${theme.transition.lg};
        transition-property: opacity, visibility;
      }
    `}

    ${isHidden === false &&
    `
      opacity: 1;
      visibility: visible;
    `}

    ${isHidden === true &&
    `
      & > * {
        opacity: 0;
        visibility: hidden;
      }
    `}

    /*
     * Clickable
     */

    ${StretchedLink}::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: calc(var(--table-border-width) * -1) 0;
      background-color: ${theme.colors.gray100};
      border-radius: ${theme.borderRadius.lg}px;
      border: var(--table-border-width) solid var(--table-border-color);
      visibility: hidden;
    }

    ${StretchedLink}::after {
      right: 0;
      left: 0;
    }

    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      ${StretchedLink}::before {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }

    /*
     * Stuck (left)
     */

    ${isStuckLeft &&
    `
      &::after {
        content: '';
        position: absolute;
        inset: 0 auto 0 100%;
        z-index: ${theme.zindex.sticky};
        width: var(--table-scroll-started-gradient-size);
        background: linear-gradient(to right, var(--table-scroll-started-gradient-from), var(--table-scroll-started-gradient-to));
        border-left: var(--table-scroll-started-border-width) solid var(--table-scroll-started-border-color);
        pointer-events: none;
      }
    `}
  `}
`;

export default TableItem;
