import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const CardStack = styled(Base)`
  ${({ theme }) => css`
    position: relative;

    > * {
      z-index: 1;
      box-shadow: ${theme.boxShadow[6]};
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      border-radius: ${theme.cardBorderRadius}px;
      background-color: ${theme.colors.white};
      box-shadow: ${theme.boxShadow[6]};
    }

    &::after {
      top: 1rem;
      left: 0.625rem;
      right: 0.625rem;
    }

    &::before {
      top: 2rem;
      left: 1.25rem;
      right: 1.25rem;
    }
  `}
`;

export default CardStack;
