import React from 'react';
import { Spinner } from '~/components/ui';
import { SpinnerProps } from '~/components/ui/Spinner/Spinner';
import { Accept, useDropzone } from 'react-dropzone';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import DropzoneContainer, { DropzoneContainerProps } from './DropzoneContainer';
import DropzoneContent from './DropzoneContent';
import DropzoneIcon from './DropzoneIcon';
import DropzoneLabel from './DropzoneLabel';
import DropzoneRoot from './DropzoneRoot';
import DropzoneImage from './DropzoneImage';

type DropzoneType = {
  <C extends React.ElementType = 'div'>(props: DropzoneProps<C> & { ref?: React.Ref<HTMLInputElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type DropzoneInnerProps = DropzoneContainerProps & {
  accept?: Accept;
  multiple?: boolean;
  imageSrc?: string | null;
  label?: React.ReactNode;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  spinnerProps?: SpinnerProps;
  isLoading?: boolean;
  onFileSelect: (acceptedFiles: File[]) => void;
};

type DropzoneProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, DropzoneInnerProps>;

const Dropzone: DropzoneType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      accept,
      multiple = false,
      imageSrc,
      label = multiple ? 'Upload images' : 'Upload image',
      isLoading,
      onFileSelect,
      inputProps,
      spinnerProps,
      ...props
    }: DropzoneProps<C>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: accept,
      multiple: multiple,
      onDrop: (acceptedFiles) => {
        onFileSelect(acceptedFiles);
      },
    });

    return (
      <>
        <DropzoneRoot as={as as React.ElementType} {...getRootProps()}>
          <input ref={ref} {...getInputProps()} {...inputProps} />
          <DropzoneContainer isDragActive={isDragActive} {...props}>
            <DropzoneContent isHidden={Boolean(imageSrc) || isLoading}>
              <DropzoneIcon src="/img/icons/icon-file-plus.svg" alt="..." />
              {label && <DropzoneLabel>{label}</DropzoneLabel>}
            </DropzoneContent>
            {imageSrc && <DropzoneImage src={imageSrc} alt="..." />}
            {isLoading && <Spinner size="sm" variant="primary" {...spinnerProps} />}
          </DropzoneContainer>
        </DropzoneRoot>
      </>
    );
  },
);

Dropzone.displayName = 'Dropzone';
export default Dropzone;
