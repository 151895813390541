import { BaseProps } from '../layout/Base';
import { FormGroup } from '../form';
import { Row } from '../layout';
import { StretchedLink } from '~/components/type';
import styled, { css } from 'styled-components';
import TableItem from './TableItem';

type TableRowProps = BaseProps & {
  isActive?: boolean;
  isDisabled?: boolean;
  isDragging?: boolean;
  isDraggingOver?: boolean;
  isDropping?: boolean;
  isStuck?: boolean;
};

const TableRow = styled(Row).attrs(
  ({ isDragging, isDraggingOver }: TableRowProps) =>
    ({
      'data-rbd-is-dragging': isDragging ? '' : undefined,
      'data-rbd-is-dragging-over': isDraggingOver ? '' : undefined,
    }) as TableRowProps,
)<TableRowProps>`
  ${({ isActive, isDisabled, isDragging, isDropping, isStuck, theme }) => css`
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    border-top: var(--table-border-width) solid var(--table-border-color);
    border-image: linear-gradient(
        to right,
        transparent,
        transparent var(--row-gutter-x),
        var(--table-border-color) var(--row-gutter-x),
        var(--table-border-color) calc(100% - var(--row-gutter-x)),
        transparent calc(100% - var(--row-gutter-x)),
        transparent
      )
      1;

    /*
     * Hover
     */

    &:hover > ${TableItem} ${StretchedLink}::before {
      visibility: visible;
    }

    /*
     * Active
     */

    ${isActive &&
    `
      & > ${TableItem} ${StretchedLink}::before {
        visibility: visible;
      }
    `}

    /*
     * Disabled
     */

    ${isDisabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}

    /*
     * Dragging
     */

    ${isDragging &&
    `
      &:has(${StretchedLink}) > ${TableItem} ${StretchedLink}::before {
        visibility: visible;
        pointer-events: auto;
      }
    `}

    /*
     * Dropping
     */

    ${isDropping &&
    `
        & > ${TableItem} ${StretchedLink}::before {
          z-index: 1;
          visibility: visible;
          background-color: ${theme.colors.primary10};
          border-color: ${theme.colors.primary};
        }
      `}

    /*
     * Collapsible content
     */

    &:has(.ReactCollapse--collapse) > ${TableItem} ${StretchedLink}::before {
      transition: ${theme.transition.base};
      transition-property: background-color, box-shadow;
    }

    &:has(.ReactCollapse--collapse[aria-hidden='false']) > ${TableItem} ${StretchedLink}::before {
      background-color: ${theme.colors.white};
      box-shadow: ${theme.boxShadow[4]};
    }

    /*
     * FormGroup
     */

    &${FormGroup} {
      margin-bottom: 0;
    }

    /*
     * Stuck
     */

    ${isStuck !== undefined &&
    `
      &::before, &::after {
        content: '';
        position: absolute;
        z-index: -1;
        visibility: ${isStuck ? 'visible' : 'hidden'};
      }

      &::before {
        inset: 0;
        box-shadow: 30vw 0 0 0 var(--table-header-bg), -30vw 0 0 0 var(--table-header-bg);
      }

      &::after {
        inset: 100% 0 auto 0;
        height: var(--table-border-width);
        background-color: var(--table-border-color);
        box-shadow: 30vw 0 0 0 var(--table-border-color), -30vw 0 0 0 var(--table-border-color);
      }
    `}
  `}
`;

export default TableRow;
