import { FlexRow } from '~/components/layout';
import styled, { css } from 'styled-components';

const Scrollable = styled(FlexRow)`
  ${() => css`
    flex-wrap: nowrap;
    overflow: auto hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export default Scrollable;
