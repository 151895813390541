import React from 'react';
import Icon from './Icon';
import { infoCircle } from './Icon/icons';
import { BaseProps } from '../layout/Base';
import dynamic from 'next/dynamic';

const Tooltip = dynamic(() => import('./Tooltip'), { ssr: false });

type InfoTooltipProps = {
  dataFor: string;
  text: React.ReactNode;
  utils?: BaseProps['utils'];
};

const InfoTooltip = ({ dataFor, text, utils }: InfoTooltipProps) => {
  return (
    <>
      <Icon icon={infoCircle} utils={{ fontSize: 'base', color: 'gray600', ...utils }} data-tooltip-id={dataFor} />
      <Tooltip id={dataFor}>{text}</Tooltip>
    </>
  );
};
export default InfoTooltip;
