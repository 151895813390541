import React from 'react';
import { Avatar, Close } from '~/components/ui';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import { getAvatarUrl } from '~/helpers';
import { User } from '~/generated/graphql';
import BadgeBase, { BadgeBaseProps } from './BadgeBase';
import styled, { css } from 'styled-components';

//
// Styled badge
//

type StyledBadgeProps = {
  count?: React.ReactNode;
  countVariant?: keyof CustomTheme['colors'];
  user?: Partial<User>;
  isDismissible?: boolean;
};

export const StyledBadge = styled(BadgeBase)<StyledBadgeProps>`
  ${({ count, countVariant, size = 'base', user, isDismissible, theme }) => css`
    --badge-height: ${theme.badgeHeight[size]}px;
    --badge-padding-x: ${theme.badgePaddingX[size]}px;
    --badge-bg: ${theme.colors.gray600};
    --badge-font-size: ${theme.badgeFontSize[size]}px;
    --badge-count-size: ${theme.badgeCountSize[size]}px;
    --badge-count-bg: ${theme.colors.gray500};
    --badge-count-font-size: ${theme.fontSize['2xs']}px;
    --badge-close-size: ${theme.badgeCloseSize[size]}px;
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    height: var(--badge-height);
    padding-right: var(--badge-padding-x);
    padding-left: var(--badge-padding-x);
    background-color: ${theme.colors.gray600};
    white-space: nowrap;
    font-size: var(--badge-font-size);
    line-height: 1;
    vertical-align: middle;
    border-radius: ${theme.borderRadius.rounded}px;
    color: ${theme.colors.white};

    /*
     * Count
     */

    ${count !== undefined &&
    `
      &:after {
        content: "${count}";
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: var(--badge-count-size);
        min-width:var(--badge-count-size);
        margin-right: ${theme.spacers[-3]}px;
        margin-left: ${theme.spacers[4]}px;
        padding-right: ${theme.spacers[3]}px;
        padding-left: ${theme.spacers[3]}px;
        border-radius: ${theme.borderRadius.rounded}px;
        background-color: ${countVariant ? theme.colors[countVariant] : 'var(--badge-count-bg)'};
        font-size: var(--badge-count-font-size);
        color: ${theme.colors.white};
      }
    `}

    /*
     * User
     */

    ${user &&
    `
      padding-left: calc((var(--badge-height) - var(--badge-close-size)) / 2 + var(--badge-close-size) + var(--badge-padding-x) / 2);
    `}

    /*
     * Dismissible
     */

    ${isDismissible &&
    `
      padding-right: calc((var(--badge-height) - var(--badge-close-size)) / 2 + var(--badge-close-size) + var(--badge-padding-x) / 2);
    `}

    /*
     * Avatar
     */

    & > ${Avatar.styledComponent} {
      position: absolute;
      top: calc((var(--badge-height) - var(--badge-close-size)) / 2);
      left: calc((var(--badge-height) - var(--badge-close-size)) / 2);
    }

    /*
     * Close
     */

    & > ${Close} {
      --close-size: 70%;
      --close-color: ${theme.colors.white};
      --close-hover-color: ${theme.colors.white};
      position: absolute;
      top: calc((var(--badge-height) - var(--badge-close-size)) / 2);
      right: calc((var(--badge-height) - var(--badge-close-size)) / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--badge-close-size);
      height: var(--badge-close-size);
      border-radius: 50%;
      transition: ${theme.transition.base};
      transition-property: background-color;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  `}
`;

//
// Badge
//

type BadgeType = {
  <C extends React.ElementType = 'span'>(props: BadgeProps<C> & { ref?: React.Ref<HTMLSpanElement> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledBadge;
};

type BadgeInnerProps = BadgeBaseProps &
  StyledBadgeProps & {
    onDismiss?: () => void;
  };

export type BadgeProps<C extends React.ElementType = 'span'> = BaseHOCPropsWithoutRef<C, BadgeInnerProps>;

const Badge: BadgeType = React.forwardRef(
  <C extends React.ElementType = 'span'>({ as, size = 'base', user, onDismiss, ...props }: BadgeProps<C>, ref: React.Ref<HTMLSpanElement>) => {
    const userAvatar = user ? getAvatarUrl({ user, width: 40, height: 40 }) : null;
    const userAvatarSize = size === 'base' ? '3xs' : size === 'lg' ? '2xs' : null;

    return (
      <StyledBadge ref={ref} as={as as React.ElementType} user={user} size={size} {...props}>
        {user && <Avatar src={userAvatar} alt={user?.fullName} size={userAvatarSize!} />}
        {user ? user?.fullName : props.children}
        {props.isDismissible && <Close onClick={onDismiss} />}
      </StyledBadge>
    );
  },
);

Badge.displayName = 'Badge';
Badge.styledComponent = StyledBadge;
export default Badge;
