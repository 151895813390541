import { formatSVG } from '~/helpers';
import styled, { css } from 'styled-components';
import TableItem from './TableItem';

type TableHeadProps = {
  isAsc?: boolean;
  isDesc?: boolean;
  isSortable?: boolean;
  isLight?: boolean;
};

const TableHeader = styled(TableItem)<TableHeadProps>`
  ${({ isAsc, isDesc, isSortable, isLight, theme }) => css`
    align-items: baseline;
    min-height: var(--table-header-height);
    padding-top: var(--table-header-padding-y);
    padding-bottom: var(--table-header-padding-y);
    flex-wrap: nowrap;
    font-size: ${theme.fontSize['2xs']}px;
    font-weight: ${theme.fontWeight.bold};
    letter-spacing: 0.08em;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: var(--table-header-bg);
    color: ${theme.colors.gray700};

    /*
     * Dark
     */

    ${isLight &&
    `
      background-color: ${theme.colors.gray100};
      color: ${theme.colors.gray800};
    `}

    /*
     * Sortable
     */

    ${isSortable &&
    `
      user-select: none;
      
      &::after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 8px;
        margin-left: ${theme.spacers[2]}px;
        background-image: ${formatSVG(`<svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M2.5 0L0 3H5L2.5 0Z' fill='${isAsc ? theme.colors.primary : theme.colors.gray500}' />
          <path d='M2.5 8L0 5H5L2.5 8Z' fill='${isDesc ? theme.colors.primary : theme.colors.gray500}' />
        </svg>`)};
      }

      &:hover::after {
        background-image: ${formatSVG(`<svg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M2.5 0L0 3H5L2.5 0Z' fill='${isAsc ? theme.colors.primary : isDesc ? theme.colors.gray700 : theme.colors.gray500}' />
          <path d='M2.5 8L0 5H5L2.5 8Z' fill='${isDesc ? theme.colors.primary : theme.colors.gray700}' />
        </svg>`)};
      }
    `}
  `}
`;

export default TableHeader;
