import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import { formatSVG } from '~/helpers';
import styled, { css } from 'styled-components';

type TableFolderProps = {
  count?: number;
  size: keyof CustomTheme['listFolderWidth'];
  variant?: keyof CustomTheme['colors'];
  isExpanded?: boolean;
  isExpandable?: boolean;
};

const TableFolder = styled(Base)<TableFolderProps>`
  ${({ count, size = 'base', variant = 'primary', isExpanded = false, isExpandable = true, theme }) => css`
    position: relative;
    z-index: 1;
    height: ${theme.listFolderHeight[size]}px;
    width: ${theme.listFolderWidth[size]}px;
    min-width: ${theme.listFolderWidth[size]}px;
    background: no-repeat center center / 100% 100%;
    background-image: ${formatSVG(
      `<svg viewBox='0 0 30 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path opacity='0.3' d='M30 24C30 24.7957 29.6839 25.5587 29.1213 26.1213C28.5587 26.6839 27.7957 27 27 27H3C2.20435 27 1.44129 26.6839 0.87868 26.1213C0.31607 25.5587 0 24.7957 0 24V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H10.5L13.5 4.5H27C27.7957 4.5 28.5587 4.81607 29.1213 5.37868C29.6839 5.94129 30 6.70435 30 7.5V24Z' fill='${theme.colors[variant]}' />
      </svg>`,
    )};
    cursor: pointer;

    /*
     * Expandable
     */

    ${isExpandable &&
    `
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -3px;
        opacity: .5;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 4px solid ${theme.colors[variant]};
        transform-origin: center center;
        transform: rotate(${isExpanded ? '180deg' : '90deg'});
        cursor: pointer;
        transition: ${theme.transition.lg};
        transition-property: transform;
      }
    `}

    /*
     * Count
     */

    ${count !== undefined &&
    `
      &::after {
        content: '${count}';
        position: absolute;
        right: ${theme.spacers[-4]}px;
        bottom: ${theme.spacers[-4]}px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${theme.spacers[6]}px;
        height: ${theme.spacers[6]}px;
        background-color: ${theme.colors.white};
        border-radius: 50%;
        font-size: ${theme.fontSize['2xs']}px;
        font-weight: ${theme.fontWeight.bold};
        color: ${variant === 'black' ? theme.colors.black60 : theme.colors.primary60};
      }
    `}
  `}
`;

export default TableFolder;
