import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

type SidenavLinkProps = {
  hasIndicator?: boolean;
  isActive?: boolean;
};

const SidenavLink = styled(Base)<SidenavLinkProps>`
  ${({ hasIndicator, isActive, theme }) => css`
    --sidenav-link-color: ${isActive ? theme.colors.white : theme.colors.gray600};
    --sidenav-link-hover-color: ${theme.colors.white};
    position: relative;
    display: block;
    text-align: center;
    line-height: ${theme.fontSize.base}px;
    font-size: ${theme.fontSize.xs - 1}px;
    color: var(--sidenav-link-color);

    &:hover {
      color: var(--sidenav-link-hover-color);
    }

    /* 
     * Indicator
     */

    ${hasIndicator &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: -5%;
        right: 15%;
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.5rem;
        border-color: var(--sidenav-bg);
        background-color: ${theme.colors.danger};
        box-sizing: content-box;
      }
    `}
  `}
`;

export default SidenavLink;
