import { defaults } from 'chart.js';
import { Theme as theme } from '~/theme';
import externalTooltipHandler from './helpers/externalTooltipHandler';
import externalTooltipLabelCallback from './helpers/externalTooltipLabelCallback';

export default function ChartDefaults() {
  // Global
  defaults.responsive = true;
  defaults.maintainAspectRatio = false;

  // Default
  defaults.color = theme.colors.gray700;

  defaults.set('font', {
    family: theme.fontFamily.base,
    size: 13,
  });

  // Layout
  defaults.set('layout.padding', {
    top: 24,
  });

  // Legend
  defaults.set('plugins.legend', {
    display: false,
  });

  // Line
  defaults.set('elements.line', {
    borderWidth: 1,
    borderColor: theme.colors.primary,
    backgroundColor: theme.colors.primary30,
    fill: true,
  });

  // Bar
  defaults.set('elements.bar', {
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    hoverBackgroundColor: theme.colors.primary,
    hoverBorderColor: theme.colors.primary,
  });

  defaults.set('datasets.bar', {
    maxBarThickness: 50,
  });

  // Doughnut
  defaults.set('datasets.doughnut', {
    cutout: '80%',
  });

  // Point
  defaults.set('elements.point', {
    radius: 0,
  });

  // Tooltips
  defaults.set('plugins.tooltip', {
    enabled: false,
    mode: 'index',
    intersect: false,
    external: externalTooltipHandler,
    callbacks: {
      label: externalTooltipLabelCallback,
    },
  });

  // xAxis
  defaults.set('scales.linear.border', {
    display: false,
    dash: [4],
    dashOffset: [4],
  });

  defaults.set('scales.linear.grid', {
    color: theme.colors.gray300,
    drawTicks: false,
  });

  defaults.set('scales.linear.ticks', {
    padding: 10,
  });

  // yAxis
  defaults.set('scales.category.border', {
    display: false,
  });

  defaults.set('scales.category.grid', {
    display: false,
    drawTicks: false,
    drawOnChartArea: false,
  });

  defaults.set('scales.category.ticks', {
    padding: 20,
  });

  // Time Axis
  defaults.set('scales.time.grid', {
    drawBorder: false,
    drawOnChartArea: false,
    drawTicks: false,
  });

  defaults.set('scales.time.ticks', {
    padding: 20,
  });
}
