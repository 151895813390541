import { StyledCollapse } from '~/components/ui/Collapse/Collapse';
import { StyledTableScrollable } from './TableScrollable';
import styled, { css } from 'styled-components';
import Table from './Table';
import TableChevron from './TableChevron';
import TableItem from './TableItem';
import TableRow from './TableRow';
import TableSection from './TableSection';

type MatrixProps = {
  colCount: number;
  rowCount: number;
  colWidth?: number;
};

const Matrix = styled(Table).attrs<MatrixProps>(() => ({
  size: 'sm',
  isFullScreen: true,
  isBordered: true,
  isBorderFlush: true,
  isBorderReverse: true,
  isSpaced: true,
}))<MatrixProps>`
  ${({ colCount, rowCount, colWidth = 220 }) => css`
    /*
     * Table scrollable
     */

    ${StyledTableScrollable} {
      display: grid;
      grid-template-columns: 220px repeat(${colCount - 2}, ${colWidth}px) auto;
      grid-template-rows: repeat(${rowCount - 1}, min-content) minmax(min-content, auto);
    }

    /*
     * Table row
     */

    ${TableRow} {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: span ${colCount};
      grid-auto-flow: column;
    }

    ${TableRow} > ${TableItem}:last-child {
      min-height: 0;
      padding: 0;
    }

    ${StyledTableScrollable} > ${TableRow}:last-child > ${TableItem} {
      min-height: 0;
      padding: 0;
    }

    /* 
     * Table section
     */

    ${TableSection} {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: span ${colCount};
    }

    /* 
     * Collapse
     */

    .ReactCollapse--collapse[style*='overflow: hidden'] {
      overflow: clip !important;
    }

    ${StyledCollapse},
    .ReactCollapse--collapse,
    .ReactCollapse--content {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: span ${colCount};
    }

    .ReactCollapse--content > ${TableRow} > ${TableItem}:first-child {
      padding-left: calc(var(--row-gutter-x) + var(--table-icon-font-size));
    }

    .ReactCollapse--content > ${TableRow} > ${TableItem}:first-child::before {
      content: '';
      position: absolute;
      inset: calc(var(--table-border-width) * -1) auto 0 0;
      width: calc(var(--row-gutter-x) + var(--table-icon-font-size));
      background-color: var(--table-bg);
    }

    /* 
     * Table chevron
     */

    ${TableItem}:first-child:has(${TableChevron}) {
      padding-left: calc(var(--row-gutter-x) + var(--table-icon-font-size));
    }

    ${TableItem}:first-child > ${TableChevron} {
      position: absolute;
      inset: auto auto auto var(--row-gutter-x);
    }
  `}
`;

export default Matrix;
