import styled, { css } from 'styled-components';
import { Base } from '~/components/layout';

const Feedback = styled(Base)`
  ${({ theme }) => css`
    margin-top: ${theme.spacers[3]}px;
    margin-bottom: ${theme.spacers[0]}px;
    font-size: ${theme.fontSize.sm}px;
  `}
`;

export default Feedback;
