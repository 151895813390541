import { BaseNav } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import Nav from '../Nav/Nav';
import styled, { css } from 'styled-components';

type HeaderProps = {
  size?: keyof CustomTheme['headerHeight'];
  variant?: 'dark';
};

const Header = styled(BaseNav)<HeaderProps>`
  ${({ size = 'base', variant, theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    height: ${theme.headerHeight[size]}px;
    margin-bottom: ${theme.spacers[6]}px;
    background-color: ${theme.headerBg};
    border-bottom: ${theme.headerBorderWidth}px solid ${theme.headerBorderColor};
    transition: ${theme.transition.base};
    transition-property: border-color;

    /*
     * Nav
     */

    ${Nav} {
      margin-bottom: 0;
    }

    /*
     * Dark
     */

    ${variant === 'dark' &&
    `
      background-color: ${theme.headerDarkBg};
      border-bottom-color: ${theme.headerDarkBorderColor};
    `}
  `}
`;

export default Header;
