import { BaseInput } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

//
// Input
//

export type InputProps = {
  _size?: keyof CustomTheme['inputPaddingX'];
  isFlush?: boolean;
};

const Input = styled(BaseInput)<InputProps>`
  ${({ _size = 'base', isFlush, theme }) => css`
    --input-padding-y: ${theme.inputPaddingY[_size]}px;
    --input-padding-x: ${theme.inputPaddingX[_size]}px;
    --input-font-size: ${theme.inputFontSize[_size]}px;
    --input-line-height: ${theme.inputLineHeight}px;
    --input-background-color: ${theme.inputBg};
    --input-border-radius: ${theme.inputBorderRadius[_size]}px;
    --input-border-width: ${theme.inputBorderWidth}px;
    --input-border-color: ${theme.inputBorderColor};
    --input-hover-border-color: ${theme.inputHoverBorderColor};
    --input-box-shadow: ${theme.inputBoxShadow};
    --input-color: ${theme.inputColor};
    --input-focus-bg: ${theme.inputFocusBg};
    --input-focus-border-color: ${theme.inputFocusBorderColor};
    --input-disabled-color: ${theme.colors.gray600};
    --input-placeholder-color: ${theme.inputPlaceholderColor};
    display: block;
    width: 100%;
    padding: var(--input-padding-y) var(--input-padding-x);
    line-height: var(--input-line-height);
    font-size: var(--input-font-size);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    border: var(--input-border-width) solid var(--input-border-color);
    outline: none;
    box-shadow: var(--input-box-shadow);
    color: var(--input-color);
    transition: ${theme.transition.base};
    transition-property: background-color, border-color, color;
    appearance: none;
    resize: none;

    &:hover {
      border-color: var(--input-hover-border-color);
    }

    &:focus {
      background-color: var(--input-focus-bg);
      border-color: var(--input-focus-border-color);
      color: var(--input-color);
    }

    &:disabled {
      color: var(--input-disabled-color);
      cursor: not-allowed;
    }

    /*
     * Placeholder
     */

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    /*
     * Flush
     */

    ${isFlush &&
    `
      --input-padding-y: 0;
      --input-padding-x: 0;
      --input-border-radius: 0;
      --input-border-width: 0;
      --input-box-shadow: none;
    `}

    /*
     * Disable Chrome decorations
     */

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      display: none;
    }
  `}
`;

export default Input;
