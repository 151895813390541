import { BaseLi } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const ListIconItem = styled(BaseLi)`
  ${({ theme }) => css`
    position: relative;
    padding-left: ${theme.listItemIconSize + theme.listItemIconGutter}px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc((${theme.lineHeight.base} * 1em - 1em) / 2);
      left: 0;
      width: ${theme.listItemIconSize}px;
      height: ${theme.listItemIconSize}px;
      border-radius: 50%;
    }

    &::before {
      background-color: var(--list-icon-variant);
      opacity: var(--list-icon-opacity);
    }

    &::after {
      background-repeat: no-repeat;
      background-image: ${theme.listItemIcon};
      background-position: center center;
      background-size: 10px 8px;
    }

    & + & {
      margin-top: ${theme.listItemSpacing}px;
    }
  `}
`;

export default ListIconItem;
