import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

//
// Input divider
//

const InputDivider = styled(Base)`
  ${({ theme }) => css`
    --input-divider-bg: ${theme.inputBg};
    --input-divider-border-width: ${theme.borderWidth}px;
    --input-divider-border-color: ${theme.borderColor};
    --input-divider-color: ${theme.borderColor};
    position: relative;
    background-color: var(--input-divider-bg);
    border-top: var(--input-divider-border-width) solid var(--input-divider-border-color);
    border-bottom: var(--input-divider-border-width) solid var(--input-divider-border-color);
    transition: ${theme.transition.base};

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      bottom: 10px;
      left: 50%;
      width: var(--input-divider-border-width);
      background-color: var(--input-divider-color);
    }
  `}
`;

export default InputDivider;
