import { Base } from '~/components/layout';
import RatioItem from './RatioItem';
import styled, { css } from 'styled-components';
import { BaseProps } from '~/components/layout/Base';

type Props = BaseProps & {
  ratio?: [number, number];
  zoom?: boolean;
};

type RatioProps = Props & {
  hover?: Props;
  mobile?: Props;
  desktop?: Props;
};

const Ratio = styled(Base)<RatioProps>`
  ${({ hover = {}, mobile = {}, desktop = {}, ratio = [16, 9], theme }) => css`
    --aspect-ratio: ${(ratio[1] / ratio[0]) * 100}%;
    position: relative;
    padding-bottom: var(--aspect-ratio);
    background: ${theme.imageFallbackColor} no-repeat center center / cover;
    overflow: ${hover.zoom && 'hidden'};

    /*
     * Mobile
     */

    ${mobile.ratio &&
    css`
      @media (min-width: ${theme.breakpoints.desktop - 1}px) {
        --aspect-ratio: ${(mobile.ratio[1] / mobile.ratio[0]) * 100}%;
      }
    `}

    /*
     * Desktop
     */

    ${desktop.ratio &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --aspect-ratio: ${(desktop.ratio[1] / desktop.ratio[0]) * 100}%;
      }
    `}

    /*
     * Hover
     */

    &:hover > ${RatioItem} {
      transform: ${hover.zoom && 'scale(1.025)'};
    }

    /*
     * Ratio item
     */

    & > ${RatioItem} {
      transition: ${hover.zoom && theme.transition.base};
    }
  `}
`;

export default Ratio;
