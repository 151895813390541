import React, { useRef } from 'react';
import { components, ContainerProps } from 'react-select';
import { SelectOptionProps } from '..';

function SelectContainer(props: ContainerProps<SelectOptionProps>) {
  const shouldStopEscKeyUpRef = useRef(false);

  const innerProps = {
    ...props.innerProps,
    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
      if (props.selectProps.menuIsOpen && event.key === 'Escape') {
        shouldStopEscKeyUpRef.current = true;
      }
      props.innerProps.onKeyDown!(event);
    },
    onKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
      if (shouldStopEscKeyUpRef.current && event.key === 'Escape') {
        event.stopPropagation();
        shouldStopEscKeyUpRef.current = false;
      }
    },
  };

  return <components.SelectContainer {...props} innerProps={innerProps} />;
}

export default SelectContainer;
