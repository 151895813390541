import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { escHandlersStack } from '~/lib/apolloClient';
import { Transition } from 'react-transition-group';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import ModalDialog, { ModalDialogProps } from './ModalDialog';
import removeBodyOverflow from '~/helpers/removeBodyOverflow';
import restoreBodyOverflow from '~/helpers/restoreBodyOverflow';
import styled, { css } from 'styled-components';

type StyledModalProps = {
  timeout?: number | { enter?: number; exit?: number; appear?: number };
};

const StyledModal = styled(Base)<StyledModalProps>`
  ${({ timeout, theme }) => css`
    position: fixed;
    inset: 0;
    z-index: ${theme.zindex.modal};
    opacity: 0;
    outline: none;
    overflow: clip;
    box-shadow: inset 0 0 0 100vmax ${theme.modalBg};
    transition: ${theme.transition.modal};
    transition-property: opacity;
    transition-duration: ${timeout}ms;
  `}
`;

type ModalInnerProps = ModalDialogProps &
  StyledModalProps & {
    appear?: boolean;
    disableEscClose?: boolean;
    isOpen: boolean;
    onClose?: () => void;
    onExited?: () => void;
    onEntered?: () => void;
  };

export type ModalProps = BaseHOCPropsWithoutRef<'div', ModalInnerProps>;

const Modal = ({
  size = 'base',
  layout = 'vertical',
  appear = false,
  timeout = 500,
  disableEscClose = false,
  isOpen,
  onClose,
  onExited,
  onEntered,
  ...props
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const isModalOpenRef = useRef(isOpen);
  const [isModalMounted, setIsModalMounted] = useState(false);

  // We should do nothing if select component is opened
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current!.querySelector('.react-select__control--menu-is-open')) {
      return;
    }
    e.target === e.currentTarget && onClose!();
  };

  const removeOverflow = () => {
    isModalOpenRef.current = true;
    removeBodyOverflow();

    if (layout === 'fullscreen') {
      document.body.style.overscrollBehaviorX = 'none';
      document.documentElement.style.overscrollBehaviorX = 'none';
    }
  };

  const restoreOverflow = (timeout: number) => {
    if (!isModalOpenRef.current) {
      return;
    }

    setTimeout(() => {
      isModalOpenRef.current = false;
      restoreBodyOverflow();

      if (layout === 'fullscreen') {
        document.body.style.overscrollBehaviorX = '';
        document.documentElement.style.overscrollBehaviorX = '';
      }
    }, timeout);
  };

  const handleEscClose = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && escHandlersStack()[escHandlersStack().length - 1].handler === handleEscClose) {
      document.activeElement && (document.activeElement as HTMLElement).blur(); // Safari bug fix
      onClose!();
    }
  };

  useEffect(() => {
    setIsModalMounted(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      removeOverflow();

      if (!disableEscClose) {
        document.addEventListener('keyup', handleEscClose, false);
        escHandlersStack([...escHandlersStack(), { handler: handleEscClose, id: props.id }]);

        return () => {
          document.removeEventListener('keyup', handleEscClose, false);
          escHandlersStack(escHandlersStack().filter((handler) => handler.handler !== handleEscClose));
        };
      }
    } else {
      restoreOverflow(typeof timeout !== 'number' ? timeout.exit! : timeout);
    }
  }, [isOpen]);

  const transitionStyles: Record<string, React.CSSProperties> = {
    entering: { opacity: 1 },
    entered: { opacity: 1, overflowY: layout !== 'horizontal' ? 'auto' : undefined },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const dialogTransitionStyle: Record<string, React.CSSProperties> = {
    entering: { transform: 'translateY(0)' },
    entered: { transform: 'translateY(0)' },
  };

  return isModalMounted
    ? ReactDOM.createPortal(
        <Transition in={Boolean(isOpen)} timeout={timeout} mountOnEnter unmountOnExit appear={appear} onExited={onExited} onEntered={onEntered}>
          {(status) => (
            <StyledModal ref={modalRef} timeout={timeout} role="dialog" tabIndex={-1} style={transitionStyles[status]} onMouseDown={handleMouseDown} {...props}>
              <ModalDialog size={size} layout={layout} style={dialogTransitionStyle[status]}>
                {props.children}
              </ModalDialog>
            </StyledModal>
          )}
        </Transition>,
        document.body,
      )
    : null;
};

export default Modal;
