import { Base } from '~/components/layout';
import styled from 'styled-components';

const ListChainedAnchor = styled(Base)`
  position: relative;
  align-self: normal;

  & + * {
    width: 100%;
    min-width: 0; // image max width fix
  }
`;

export default ListChainedAnchor;
