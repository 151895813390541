import { ControlledMenu, ControlledMenuProps } from '@szhsin/react-menu';
import DropdownStyles from './DropdownStyles';
import styled from 'styled-components';

const dropdownAttrs: ControlledMenuProps = {
  boundingBoxPadding: '20px',
  overflow: 'auto',
  submenuCloseDelay: 0,
  submenuOpenDelay: 0,
  transition: true,
};

const DropdownControlledBase = styled(ControlledMenu).attrs<ControlledMenuProps>(() => dropdownAttrs)<ControlledMenuProps>`
  ${DropdownStyles}
`;

export default DropdownControlledBase;
