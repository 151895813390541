import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type CardHeaderProps = {
  isStuck?: boolean;
};

const CardHeader = styled(Base)<CardHeaderProps>`
  ${({ isStuck, theme }) => css`
    padding: ${theme.spacers[7]}px var(--card-spacing);
    border-bottom: var(--card-border-width) solid var(--card-border-color);

    /*
     * Sticky
     */

    ${isStuck !== undefined &&
    `
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      transition: ${theme.transition.base};
      transition-property: border-color;
    `}

    ${isStuck === false &&
    `
      border-bottom-color: transparent;
    `}

    ${isStuck &&
    `
      background-color: ${theme.colors.white};
      border-bottom-color: ${theme.hrBorderColor};
    `}
  `}
`;

export default CardHeader;
