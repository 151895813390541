import React from 'react';
import { Avatar } from '~/components/ui';
import { components } from 'react-select';
import { ControlProps } from 'react-select';
import { Image } from '~/components/ui';
import { SelectOptionProps } from '..';
import Map, { MapValue } from '~/components/icons/maps/Map';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

const SelectControl = ({ ...props }: ControlProps<SelectOptionProps>) => {
  const children =
    props?.hasValue && props?.selectProps?.onControlRender ? props?.selectProps?.onControlRender(props.getValue()?.[0], props.children) : props.children;

  //
  // Show avatar
  //

  if (props?.hasValue && !props.selectProps.isMulti && props.selectProps?.showAvatar) {
    const value = props.getValue();
    const selectedValue = value[0];
    const user = selectedValue.user;
    const size = props.selectProps.showAvatar === true ? 'xs' : props.selectProps.showAvatar;

    return (
      <components.Control {...props}>
        {selectedValue.isWL && !selectedValue.avatarUrl ? (
          <Image src="/img/brand/brand.svg" alt="WelcomeLend" />
        ) : user ? (
          <UserAvatar size={size} user={user} />
        ) : (
          <Avatar size={size} src={selectedValue.avatarUrl} alt={selectedValue.label} />
        )}
        {children}
      </components.Control>
    );
  }

  //
  // Show map
  //

  if (props?.hasValue && props.selectProps?.showMap) {
    return (
      <components.Control {...props}>
        {props.getValue().map((option) => (
          <React.Fragment key={option.value as MapValue}>
            <Map height="1em" width="1.6em" value={option.value as MapValue[]} style={{ marginRight: 8 }} />
            {children}
          </React.Fragment>
        ))}
      </components.Control>
    );
  }

  return <components.Control {...props}>{children}</components.Control>;
};

export default SelectControl;
