import React, { useId } from 'react';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

export const StyledCheckbox = styled(Base)`
  ${() => css`
    display: flex;
    align-items: center;
  `};
`;

export const StyledCheckboxInput = styled.input`
  ${({ theme }) => css`
    appearance: none;
    display: block;
    min-width: ${theme.checkboxSize}px;
    width: ${theme.checkboxSize}px;
    height: ${theme.checkboxSize}px;
    margin-right: ${theme.checkboxGutter}px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-color: ${theme.checkboxIndicatorBg};
    background-image: ${theme.checkboxIndicatorIcon};
    background-position: center center;
    background-size: 10px 8px;

    &:checked {
      background-color: ${theme.checkboxIndicatorActiveBg};
    }

    &:disabled {
      background-image: ${theme.checkboxDisableIcon};
      background-size: 16px 16px;
    }

    &:disabled + ${StyledCheckboxLabel} {
      color: ${theme.checkboxColor};
      cursor: not-allowed;
    }
  `};
`;

const StyledCheckboxLabel = styled(Base)`
  ${({ theme }) => css`
    display: inherit;
    font-size: ${theme.checkboxFontSize}px;
    color: ${theme.colors.gray600};
    cursor: pointer;
  `};
`;

type CheckboxType = {
  <C extends React.ElementType = 'div'>(props: CheckboxProps<C> & { ref?: React.Ref<HTMLInputElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type CheckboxInnerProps = {
  inputProps?: BaseHOCPropsWithoutRef<'input'>;
  labelProps?: BaseHOCPropsWithoutRef<'label'>;
  isChangeDisabled?: boolean;
};

type CheckboxProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, CheckboxInnerProps>;

const Checkbox: CheckboxType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, children, inputProps, labelProps, isChangeDisabled, ...props }: CheckboxProps<C>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const generatedId = useId();
    const inputId = inputProps?.id ? inputProps.id : generatedId;

    return (
      <StyledCheckbox as={as as React.ElementType} {...props}>
        <StyledCheckboxInput ref={ref} id={inputId} type="checkbox" onChange={isChangeDisabled ? undefined : inputProps?.onChange} {...inputProps} />
        {children && (
          <StyledCheckboxLabel as="label" htmlFor={inputId} {...labelProps}>
            {children}
          </StyledCheckboxLabel>
        )}
      </StyledCheckbox>
    );
  },
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
