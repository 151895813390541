import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import Input from './Input';
import InputAddon from './InputAddon';
import InputDivider from './InputDivider';
import styled, { css } from 'styled-components';
import { StyledDropdown as SelectAddress } from '~/components/vendor/SelectAddress';

//
// Input group
//

interface InputGroupProps {
  size?: keyof CustomTheme['inputPaddingX'];
  isFlush?: boolean;
}

const InputGroup = styled(Base)<InputGroupProps>`
  ${({ size = 'base', isFlush, theme }) => css`
    --input-group-border-radius: ${theme.inputBorderRadius[size]}px;
    --input-group-box-shadow: ${theme.inputBoxShadow};
    display: flex;
    width: 100%;
    border-radius: var(--input-group-border-radius);
    box-shadow: var(--input-group-box-shadow);

    &:has(> ${SelectAddress}) {
      position: relative;
    }

    & > ${SelectAddress} {
      position: static;
      width: inherit;
      border-radius: inherit;
    }

    & > ${Input},
    & > ${SelectAddress} > ${Input} {
      --input-box-shadow: none;
    }

    & > ${Input},
    & > ${SelectAddress} > ${Input} {
      --input-padding-y: ${theme.inputPaddingY[size]}px;
      --input-padding-x: ${theme.inputPaddingX[size]}px;
      --input-font-size: ${theme.inputFontSize[size]}px;
      border-radius: inherit;
    }

    & > ${InputAddon} {
      --input-addon-padding-y: ${theme.inputPaddingY[size]}px;
      --input-addon-padding-x: ${theme.inputPaddingX[size]}px;
      --input-addon-font-size: ${theme.inputFontSize[size]}px;
      border-radius: inherit;
    }

    & > ${InputAddon}:first-child {
      padding-right: 0;
    }

    & > ${InputAddon}:last-child {
      padding-left: 0;
    }

    & > ${Input}:not(:first-child),
    & > ${SelectAddress}:not(:first-child) > ${Input} {
      padding-left: 10px; // TODO: Replace with a spacers value when available
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }

    & > ${Input}:not(:last-child),
    & > ${SelectAddress}:not(:last-child) > ${Input} {
      padding-right: 10px; // TODO: Replace with a spacers value when available
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }

    & > ${Input}:first-child, & > ${InputAddon}:first-child, & > ${SelectAddress}:first-child > ${Input} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }

    & > ${Input}:last-child, & > ${InputAddon}:last-child, & > ${SelectAddress}:last-child > ${Input} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }

    &:hover > ${Input},
    &:hover > ${SelectAddress} > ${Input} {
      --input-border-color: ${theme.inputHoverBorderColor};
    }

    &:hover > ${InputAddon} {
      --input-addon-border-color: ${theme.inputHoverBorderColor};
    }

    &:hover > ${InputDivider} {
      --input-divider-border-color: ${theme.inputHoverBorderColor};
    }

    &:has(> ${Input}:focus) > ${Input},
    &:has(> ${SelectAddress} > ${Input}:focus) > ${SelectAddress} > ${Input} {
      --input-border-color: ${theme.inputFocusBorderColor};
      --input-hover-border-color: ${theme.inputFocusBorderColor};
      --input-bg-color; ${theme.inputFocusBg};
    }

    &:has(> ${Input}:focus) > ${InputAddon},
    &:has(> ${SelectAddress} > ${Input}:focus) > ${InputAddon} {
      --input-addon-border-color: ${theme.inputFocusBorderColor};
      --input-addon-bg-color; ${theme.inputFocusBg};
    }

    &:has(> ${Input}:focus) > ${InputDivider},
    &:has(> ${SelectAddress} > ${Input}:focus) > ${InputDivider} {
      --input-divider-border-color: ${theme.inputFocusBorderColor};
      --input-divider-bg-color; ${theme.inputFocusBg};
    }

    /*
     * Flush
     */

    ${
      isFlush &&
      `
        --input-group-box-shadow: none;

        & > ${Input},
        & > ${SelectAddress} > ${Input} {
          --input-padding-y: 0;
          --input-border-width: 0;
        }

        & > ${InputAddon} {
          --input-addon-padding-y: 0;
          --input-addon-border-width: 0;
        }

        & > ${Input},
        & > ${SelectAddress} > ${Input} {
          --input-padding-y: 0;
          --input-border-width: 0;
        }

        & > ${Input}:last-child, & > ${InputAddon}:last-child, & > ${InputDivider}:last-child, & > ${SelectAddress}:last-child > ${Input} {
          padding-right: 0;
        }

        & > ${Input}:first-child, & > ${InputAddon}:first-child, & > ${InputDivider}:first-child, & > ${SelectAddress}:first-child > ${Input} {
          padding-left: 0;
        }
    `
    }
  `}
`;

export default InputGroup;
