import { Base } from '~/components/layout';
import { Input, InputAddon } from '../Input';
import EditorContainer from '~/components/vendor/Editor/EditorContainer';
import Feedback from '../Feedback';
import Label from '../Label';
import styled, { css } from 'styled-components';

interface FormGroupProps {
  isFloating?: boolean;
  isValid?: boolean;
}

const FormGroup = styled(Base)<FormGroupProps>`
  ${({ isFloating, isValid, theme }) => css`
    margin-bottom: ${theme.spacers[6]}px;

    /*
     * Floating
     */

    ${isFloating &&
    `
      position: relative;
      padding-top: ${theme.spacers[4]}px;

      ${Label} {
        position: absolute;
        top: ${theme.spacers[4]}px;
        left: ${theme.spacers[4]}px;
        z-index: 1;
        padding-left: ${theme.spacers[4]}px;
        padding-right: ${theme.spacers[4]}px;
        background-color: ${theme.inputBg};
        font-size: ${theme.fontSize.xs}px;
        color: ${isValid === false ? theme.colors.danger : theme.inputColor};
        transform: translateY(-50%);
      }

      &:focus-within > ${Label} {
        color: ${isValid === false ? theme.colors.danger : theme.inputFocusBorderColor};
      }
    `}

    /*
     * Validation
     */

    &&& {
      ${Feedback} {
        color: ${isValid === false && theme.colors.danger};
      }

      ${Input},
      ${InputAddon},
      ${EditorContainer},
      .react-select__control {
        border-color: ${isValid === false && theme.colors.danger};
      }
    }
  `}
`;

export default FormGroup;
