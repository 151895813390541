import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';
import RadioButton from './RadioButton';

interface RadioGroupProps {
  size?: keyof CustomTheme['buttonPaddingX'];
  isBlock?: boolean;
}

const RadioGroup = styled(Base)<RadioGroupProps>`
  ${({ size = 'base', isBlock, theme }) => css`
    --radio-group-bg: ${theme.radioGroupBg};
    --radio-group-border-radius: ${theme.buttonBorderRadius[size]}px;
    --radio-group-border-width: ${theme.radioGroupBorderWidth}px;
    --radio-group-border-color: ${theme.radioGrouipBorderColor};
    display: inline-flex;
    border: var(--radio-group-border-width) solid var(--radio-group-border-color);
    border-radius: var(--radio-group-border-radius);
    background-color: var(--radio-group-bg);

    /* 
     * Button
     */

    & > ${RadioButton} {
      --btn-padding-y: ${theme.buttonPaddingY[size]}px;
      --btn-padding-x: ${theme.buttonPaddingX[size]}px;
      --btn-font-size: ${theme.buttonFontSize[size]}px;
      --btn-border-radius: ${theme.buttonBorderRadius[size]}px;
    }

    /* 
     * Block
     */

    ${isBlock &&
    `
      display: flex;
      width: 100%;

      & > ${RadioButton} {
        display: block;
        width: 100%;
      }
    `}
  `}
`;

export default RadioGroup;
