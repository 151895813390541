import { Base } from '~/components/layout';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { BaseProps } from '~/components/layout/Base';

type StypedDropdownBackdropProps = BaseProps & {
  isOpen: boolean;
};

const StyledDropdownBackdrop = styled(Base).attrs(() => ({ tabIndex: -1, ariaHidden: true }))<StypedDropdownBackdropProps>`
  ${({ isOpen, theme }) => css`
    position: absolute;
    inset: 0;
    z-index: ${theme.zindex.dropdown};
    visibility: ${isOpen ? 'visible' : 'hidden'};
  `}
`;

type DrodpownBackdropProps = StypedDropdownBackdropProps & {
  onClick: () => void;
};

const DropdownBackdrop = ({ ...props }: DrodpownBackdropProps) => {
  return createPortal(<StyledDropdownBackdrop {...props} />, document.body);
};

export default DropdownBackdrop;
