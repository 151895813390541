import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

//
// Input addon
//

interface InputAddonProps {
  size?: keyof CustomTheme['inputPaddingX'];
}

const InputAddon = styled(Base)<InputAddonProps>`
  ${({ size = 'base', theme }) => css`
    --input-addon-padding-y: ${theme.inputPaddingY[size]}px;
    --input-addon-padding-x: ${theme.inputPaddingX[size]}px;
    --input-addon-bg: ${theme.inputBg};
    --input-addon-border-width: ${theme.inputBorderWidth}px;
    --input-addon-border-color: ${theme.inputBorderColor};
    --input-addon-border-radius: ${theme.inputBorderRadius[size]}px;
    --input-addon-line-height: ${theme.inputLineHeight}px;
    --input-addon-font-size: ${theme.inputFontSize[size]}px;
    --input-addon-color: ${theme.colors.gray700};
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: var(--input-addon-padding-y) var(--input-addon-padding-x);
    background-color: var(--input-addon-bg);
    border: var(--input-addon-border-width) solid var(--input-addon-border-color);
    border-radius: var(--input-addon-border-radius);
    line-height: var(--input-addon-line-height);
    font-size: var(--input-addon-font-size);
    color: var(--input-addon-color);
    transition: ${theme.transition.base};
  `}
`;

export default InputAddon;
