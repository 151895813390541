import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';
import { BaseProps } from '../layout/Base';

type Props = BaseProps & {
  indent?: keyof CustomTheme['spacers'];
  slant?: keyof CustomTheme['spacers'];
};

type ShapeProps = Props & {
  horizontal?: 'left' | 'right';
  image?: string;
  variant?: keyof CustomTheme['colors'];
  vertical?: 'top' | 'bottom';
  mobile?: Props;
  desktop?: Props;
};

const Shape = styled(Base)<ShapeProps>`
  ${({ horizontal = 'right', image, indent = 0, slant = 0, variant = 'black', vertical = 'top', mobile = {}, desktop = {}, theme }) => css`
    --shape-indent: ${theme.spacers[indent]}px;
    --shape-slant: ${theme.spacers[slant]}px;
    position: absolute;
    width: 100%;
    top: ${vertical === 'bottom' && 0};
    bottom: ${vertical === 'top' && 0};
    height: calc(var(--shape-indent) + var(--shape-slant));
    transform: ${horizontal === 'left' && 'scaleX(-1)'};
    background-repeat: no-repeat;
    background-position: center center;
    background-image: linear-gradient(to right, ${theme.colors[variant]} 0%, ${theme.colors[variant]} 100%) ${image && `, url(${image})`};
    background-size: cover;
    clip-path: ${vertical === 'top'
      ? 'polygon(0% var(--shape-slant), 100% 0%, 100% 100%, 0% 100%)'
      : 'polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - var(--shape-slant)))'};
    transform: scaleZ(-1);

    /* 
     * Mobile
     */

    ${(mobile?.slant || mobile?.indent) &&
    css`
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        --shape-indent: ${theme.spacers[mobile?.indent ?? indent]}px;
        --shape-slant: ${theme.spacers[mobile?.slant ?? slant]}px;
      }
    `}

    /* 
     * Desktop
     */

    ${(desktop?.slant || desktop?.indent) &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --shape-indent: ${theme.spacers[desktop?.indent ?? indent]}px;
        --shape-slant: ${theme.spacers[desktop?.slant ?? slant]}px;
      }
    `}
  `}
`;

export default Shape;
