import React, { useId } from 'react';
import { formatSVG } from '~/helpers';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const StyledCheckbox = styled(Base)`
  ${() => css`
    display: flex;
    align-items: center;
  `};
`;

const StyledCheckboxInput = styled.input`
  ${({ theme }) => css`
    appearance: none;
    width: ${theme.checkboxSquareInputSize}px;
    min-width: ${theme.checkboxSquareInputSize}px;
    height: ${theme.checkboxSquareInputSize}px;
    border: ${theme.borderWidth}px solid ${theme.checkboxSquareInputBorderColor};
    border-radius: ${theme.checkboxSquareInputBorderRadius}px;
    background: no-repeat center center / cover;

    &:checked {
      border-color: ${theme.checkboxSquareInputActiveBorderColor};
      background-color: ${theme.checkboxSquareInputActiveBg};
      background-image: ${formatSVG(theme.checkboxSquareInputActiveIcon)};
    }

    &:disabled {
      border-color: ${theme.checkboxSquareInputDisabledBorderColor};
      background-color: ${theme.checkboxSquareInputDisabledBg};
    }
  `};
`;

const StyledCheckboxLabel = styled(Base)`
  ${({ theme }) => css`
    margin-left: ${theme.spacers[3]}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;

type CheckboxType = {
  <C extends React.ElementType = 'div'>(props: CheckboxProps<C> & { ref?: React.Ref<HTMLInputElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type CheckboxInnerProps = {
  inputProps?: BaseHOCPropsWithoutRef<'input'>;
  labelProps?: BaseHOCPropsWithoutRef<'label'>;
};

type CheckboxProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, CheckboxInnerProps>;

const CheckboxSquare: CheckboxType = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, children, inputProps, labelProps, ...props }: CheckboxProps<C>, ref: React.Ref<HTMLInputElement>) => {
    const generatedId = useId();
    const inputId = inputProps?.id ? inputProps.id : generatedId;

    return (
      <StyledCheckbox as={as as React.ElementType} {...props}>
        <StyledCheckboxInput ref={ref} id={inputId} type="checkbox" {...inputProps} />
        {children && (
          <StyledCheckboxLabel as="label" htmlFor={inputId} {...labelProps}>
            {children}
          </StyledCheckboxLabel>
        )}
      </StyledCheckbox>
    );
  },
);

CheckboxSquare.displayName = 'CheckboxSquare';
export default CheckboxSquare;
