import { Base } from '../layout';
import React from 'react';
import styled, { css } from 'styled-components';

type TogglerProps = {
  isActive?: boolean;
};

const Toggler = styled(Base).attrs<TogglerProps>(() => ({
  children: (
    <>
      <span></span>
      <span></span>
      <span></span>
    </>
  ),
}))<TogglerProps>`
  ${({ isActive, theme }) => css`
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: middle;
    width: ${theme.togglerWidth}px;
    height: ${theme.togglerHeight}px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    /* 
     * Span
     */

    & > span {
      display: block;
      width: 100%;
      height: ${theme.togglerHeight / 8}px;
      background-color: ${theme.togglerColor};
      border-radius: 1rem;
      transition: ${theme.transition.base};
    }

    & > span:nth-child(1) {
      transform-origin: center center;
    }

    & > span:nth-child(2) {
      transform-origin: center left;
    }

    & > span:nth-child(3) {
      transform-origin: center center;
    }

    /* 
     * Active
     */

    ${isActive &&
    `

      & > span:nth-child(1) {
        transform: translateY(${theme.togglerHeight / 2 - theme.togglerHeight / 16}px) rotate(45deg);
      }

      & > span:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }

      & > span:nth-child(3) {
        transform: translateY(${theme.togglerHeight / 16 - theme.togglerHeight / 2}px) rotate(-45deg);
      }
    `}
  `}
`;

export default Toggler;
