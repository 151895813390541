export function getDomain(url: string, subdomain?: boolean): string | undefined {
  try {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
      const tmp = url.split('.');

      url = tmp.slice(tmp.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
      return url.split('/')[0];
    }

    return url;
  } catch (error) {
    return undefined;
  }
}
