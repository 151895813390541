import React, { useEffect, useRef, useState } from 'react';
import { avatarVariantMapped } from '~/helpers/getAvatarUrl';
import { BadgeBaseProps } from '~/components/ui/Badge/BadgeBase';
import { Base } from '~/components/layout';
import { BaseHOCPropsWithoutRef, BaseProps } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import { formatName } from '~/helpers';
import { ImageProps } from '~/components/ui/Image';
import AvatarBadge from './AvatarBadge';
import AvatarImage from './AvatarImage';
import AvatarTitle from './AvatarTitle';
import styled, { css } from 'styled-components';

type Props = BaseProps & {
  size?: keyof CustomTheme['avatarSize'];
  ratio?: [number, number];
  status?: keyof CustomTheme['avatarStatus'];
  isInactive?: boolean;
};

type StyledAvatarProps = Props & {
  mobile?: Props;
  desktop?: Props;
};

export const StyledAvatar = styled(Base)<StyledAvatarProps>`
  ${({ size = 'base', ratio, status, mobile = {}, desktop = {}, isInactive, theme }) => css`
    --avatar-size: ${theme.avatarSize[size]}px;
    --avatar-width: var(--avatar-size);
    --avatar-height: var(--avatar-size);
    --avatar-border-radius: ${theme.borderRadius.rounded}px;
    --avatar-font-size: ${theme.avatarFontSize[size]}px;
    --avatar-status-variant: ${theme.avatarStatus[status as keyof CustomTheme['avatarStatus']]};
    position: relative;
    display: inline-flex;
    min-width: var(--avatar-width);
    width: var(--avatar-width);
    height: var(--avatar-height);
    border-radius: var(--avatar-border-radius);
    font-size: var(--avatar-font-size);
    font-weight: ${theme.fontWeight.bold};
    text-transform: uppercase;
    vertical-align: middle;
    color: ${theme.colors.white};

    /*
     * Ratio
     */

    ${ratio &&
    `
      --avatar-width: calc(var(--avatar-size) * ${ratio[0] / ratio[1]});

      ${AvatarImage} {
        object-fit: cover;
      }
    `}

    /*
     * Mobile
     */

    ${mobile.size &&
    css`
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        --avatar-size: ${theme.avatarSize[mobile.size]}px;
        --avatar-font-size: ${theme.avatarFontSize[mobile.size]}px;
      }
    `}

    /*
     * Desktop
     */

    ${desktop.size &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --avatar-size: ${theme.avatarSize[desktop.size]}px;
        --avatar-font-size: ${theme.avatarFontSize[desktop.size]}px;
      }
    `}

    /*
     * Status indicator
     */

    ${status &&
    `
      &::after {
        content: '';
        position: absolute;
        bottom: calc(var(--avatar-size) * 0.05);
        right: calc(var(--avatar-size) * 0.05);
        width: calc(var(--avatar-size) * 0.2);
        height: calc(var(--avatar-size) * 0.2);
        background-color: var(--avatar-status-variant);
        border-radius: 50%;
      }

      ${AvatarImage}, ${AvatarTitle} {
        mask-image: radial-gradient(circle at 85% 85%, transparent calc(var(--avatar-size) * 0.15), black calc(var(--avatar-size) * 0.15));
      }
    `}

    /*
     * Inactive styles
     */

    ${isInactive &&
    `
      color: ${theme.colors.gray600};

      ${AvatarImage}, ${AvatarTitle} {
        background-color: ${theme.colors.gray100};
        border: ${theme.borderWidth}px dashed ${theme.colors.gray500};
      }
    `}
  `}
`;

type AvatarType = {
  <C extends React.ElementType = 'div'>(props: AvatarProps<C> & { ref?: React.Ref<HTMLDivElement> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledAvatar;
};

export type AvatarInnerProps = StyledAvatarProps & {
  alt?: React.ReactNode;
  src?: string | null;
  fallbackSrc?: string | null;
  badge?: React.ReactNode;
  imageProps?: ImageProps;
  titleProps?: BaseHOCPropsWithoutRef<'div'>;
  badgeProps?: BaseHOCPropsWithoutRef<'span', BadgeBaseProps>;
  shouldClipGoogleLogo?: boolean;
  onLoadError?: () => void;
};

export type AvatarProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, AvatarInnerProps>;

const Avatar: AvatarType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      size = 'base',
      alt,
      src,
      fallbackSrc,
      badge,
      imageProps = {},
      titleProps = {},
      badgeProps = {},
      shouldClipGoogleLogo,
      onLoadError,
      ...props
    }: AvatarProps<C>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [variant, setVariant] = useState(avatarVariantMapped[src!] ? (src as keyof CustomTheme['avatarVariant']) : null);
    const [imageSource, setImageSource] = useState(avatarVariantMapped[src!] ? null : src);
    const [hasImageError, setHasImageError] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const initialSrc = useRef(src);
    const title = typeof alt === 'string' ? formatName(alt) : alt;

    useEffect(() => {
      if (src === initialSrc.current) {
        return;
      }

      setHasImageError(false);
      setIsImageLoaded(false);

      if (avatarVariantMapped[src!]) {
        setVariant(src as keyof CustomTheme['avatarVariant']);
        setImageSource(null);
      } else {
        setImageSource(src);
        setVariant(null);
      }
    }, [src]);

    const onLoad = () => {
      setIsImageLoaded(true);
    };

    const onError = () => {
      if (imageSource && fallbackSrc && imageSource !== fallbackSrc) {
        setImageSource(fallbackSrc);
      } else {
        setHasImageError(true);
      }
      onLoadError?.();
    };

    const wrapAvatarImage = (element: React.ReactNode) => {
      return shouldClipGoogleLogo ? <div style={{ overflow: 'hidden', borderRadius: 'inherit' }}>{element}</div> : element;
    };

    return (
      <StyledAvatar ref={ref} as={as as React.ElementType} size={size} {...props}>
        {imageSource &&
          wrapAvatarImage(
            <AvatarImage
              src={imageSource as string}
              alt={typeof title === 'string' ? title : ''}
              onError={onError}
              onLoad={onLoad}
              isLoaded={isImageLoaded}
              shouldClipGoogleLogo={shouldClipGoogleLogo}
              style={{ display: hasImageError ? 'none' : 'block' }}
              {...imageProps}
            />,
          )}
        {((imageSource && hasImageError) || !imageSource) && (
          <AvatarTitle variant={variant!} {...titleProps}>
            {typeof alt === 'string' ? formatName(alt) : alt}
          </AvatarTitle>
        )}
        {badge && <AvatarBadge {...badgeProps}>{badge}</AvatarBadge>}
      </StyledAvatar>
    );
  },
);

Avatar.displayName = 'Avatar';
Avatar.styledComponent = StyledAvatar;
export default Avatar;
