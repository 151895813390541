import styled, { css } from 'styled-components';

// YEVGENY: Get rid of this weird component
const FolderLendersList = styled.ul`
  ${({ theme }) => css`
    padding-left: ${theme.tooltipPaddingX}px;
    margin-bottom: 0px;
    margin-top: 8px;
    list-style-position: outside;
    list-style-type: disc;
  `}
`;

export default FolderLendersList;
