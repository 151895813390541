import { CustomTheme } from '~/@types/styled-components';
import List from '../List';
import styled, { css } from 'styled-components';

type ListIconProps = {
  variant?: keyof CustomTheme['colors'];
};

const ListIcon = styled(List)<ListIconProps>`
  ${({ variant, theme }) => css`
    --list-icon-variant: ${variant ? theme.colors[variant] : 'currentColor'};
    --list-icon-opacity: ${variant ? 1 : 0.2};
  `}
`;

export default ListIcon;
