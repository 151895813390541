import { Flex } from '~/components/layout';
import styled, { css } from 'styled-components';

type SidenavRoundingProps = {
  isActive: boolean;
};

const SidenavRounding = styled(Flex)<SidenavRoundingProps>`
  ${({ isActive = false, theme }) => css`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 100%;
    bottom: 0;
    width: var(--sidenav-border-radius);
    border-top-left-radius: var(--sidenav-border-radius);
    border-bottom-left-radius: var(--sidenav-border-radius);
    box-shadow: calc(var(--sidenav-border-radius) * -1) 0 0 var(--sidenav-border-radius) var(--sidenav-bg);
    pointer-events: none;

    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      transform: scaleX(${isActive ? 1 : 0});
      transform-origin: top left;
      transition: ${theme.transition.base};
      transition-property: transform;
    }
  `}
`;

export default SidenavRounding;
