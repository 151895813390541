import React, { useEffect, useState, useRef } from 'react';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import { Control, Menu, MultiValueContainer, MultiValueLabel, Option, SelectContainer, ValueContainer, SingleValue } from './components';
import { CreatableAdditionalProps } from 'react-select/dist/declarations/src/useCreatable';
import { CustomTheme } from '~/@types/styled-components';
import { Document, User } from '~/generated/graphql';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { GroupBase, Props, OptionProps, Options, SelectInstance } from 'react-select';
import SelectStyles from './SelectStyles';
import styled from 'styled-components';

type DropdownTitleProps = {
  default: React.ReactNode;
  empty: React.ReactNode;
};

export type UserWithLogo = User & { logo?: string };

export type Location = {
  address?: string;
  city?: string;
  country?: string;
};

export type Folder = {
  title?: string;
  depth?: number;
  adminsOnlyAccess?: boolean;
};

export type SelectOptionProps = {
  [key: string]: any;
  avatarUrl?: string;
  document?: Document;
  dropdownTitle?: DropdownTitleProps;
  folder?: Folder;
  groupTitle?: string;
  isDisabled?: boolean;
  isDivider?: boolean;
  isInvalid?: boolean;
  isWL?: boolean;
  label: string;
  location?: Location;
  replaceOptionRender?: boolean;
  tooltip?: string;
  user?: UserWithLogo;
  value: any;
};

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
    extends AsyncAdditionalProps<Option, Group>,
      CreatableAdditionalProps<Option, Group> {
    async?: boolean;
    groupValuesByLabel?: string;
    isCreatable?: boolean;
    isFlush?: boolean;
    menuAlignment?: 'left' | 'right';
    menuWidth?: 'auto' | 'full';
    onControlRender?: (data: SelectOptionProps, children: React.ReactNode) => React.ReactNode;
    onOptionRender?: (data: SelectOptionProps, props: OptionProps<SelectOptionProps>) => React.ReactNode;
    onValueContainerRender?: (data: Options<SelectOptionProps>) => React.ReactNode;
    onValueRender?: (data: SelectOptionProps) => React.ReactNode;
    setOpen?: React.Dispatch<() => void>;
    showAvatar?: boolean | keyof CustomTheme['avatarSize'];
    showDropdownIcon?: boolean;
    showMap?: boolean;
    showOptionCheckbox?: boolean;
    size?: keyof CustomTheme['inputPaddingX'];
    truncateValues?: boolean;
  }
}

export const StyledSelect = styled(ReactSelect)`
  ${SelectStyles}
`;

export const StyledAsyncSelect = styled(AsyncSelect)`
  ${SelectStyles}
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  ${SelectStyles}
`;

function Select<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  menuAlignment = 'left',
  menuPlacement = 'auto',
  menuWidth = 'full',
  menuPortalTarget,
  menuShouldBlockScroll = true,
  openMenuOnFocus = false,
  isClearable = false,
  setOpen,
  ...props
}: Omit<Props<Option, IsMulti, Group>, 'theme'>) {
  const selectRef = useRef<SelectInstance<Option> | null>(null);

  const [portalTarget, setPortalTarget] = useState<HTMLElement | null | undefined>(menuPortalTarget);
  const ActiveSelectComponent = props.async ? StyledAsyncSelect : props.isCreatable ? StyledCreatableSelect : StyledSelect;

  useEffect(() => {
    if (!menuPortalTarget) {
      setPortalTarget(document.body);
    }
  }, [menuPortalTarget]);

  useEffect(() => {
    setOpen?.(() => () => {
      if (selectRef.current) {
        selectRef.current.focus();
        selectRef.current.openMenu('first');
      }
    });
  }, []);

  return (
    // @ts-ignore
    <ActiveSelectComponent
      ref={selectRef}
      classNamePrefix="react-select"
      components={{ Control, Menu, Option, SelectContainer, ValueContainer, MultiValueContainer, MultiValueLabel, SingleValue }}
      menuAlignment={menuAlignment}
      menuPlacement={menuPlacement}
      menuWidth={menuWidth}
      menuShouldBlockScroll={menuShouldBlockScroll}
      menuPortalTarget={portalTarget}
      openMenuOnFocus={openMenuOnFocus}
      isClearable={isClearable}
      {...props}
    />
  );
}

Select.styledComponent = StyledSelect;
export default Select;
