import React from 'react';
import ReactConfetti from 'react-confetti';
import ReactDOM from 'react-dom';
import { Theme } from '~/theme';

export default function Confetti(props: React.ComponentProps<typeof ReactConfetti>) {
  const width = window?.innerWidth;
  const height = window?.innerHeight;

  const styles = {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: Theme['zindex'].confetti,
  } as React.CSSProperties;

  return ReactDOM.createPortal(
    <ReactConfetti width={width} height={height} numberOfPieces={1000} tweenDuration={10000} recycle={false} style={styles} {...props} />,
    document.body,
  );
}
