import React, { useId } from 'react';
import Base, { BaseHOCPropsWithoutRef } from '../layout/Base';
import styled, { css } from 'styled-components';

export const StyledFileInput = styled(Base)`
  ${() => css`
    display: inline-block;
  `};
`;
const FileInputInput = styled.input`
  ${() => css`
    display: none;
  `};
`;

const FileInputLabel = styled(Base)`
  ${() => css``};
`;

type FileInputType = {
  <C extends React.ElementType = 'div'>(props: FileInputProps<C> & { ref?: React.Ref<HTMLInputElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type FileInputInnerProps = {
  inputProps?: BaseHOCPropsWithoutRef<'input'>;
  labelProps?: BaseHOCPropsWithoutRef<'label'>;
};

type FileInputProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C, FileInputInnerProps>;

const FileInput: FileInputType = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, children, inputProps, labelProps, ...props }: FileInputProps<C>, ref: React.Ref<HTMLInputElement>) => {
    const generatedId = useId();
    const inputId = inputProps?.id ? inputProps.id : generatedId;
    return (
      <StyledFileInput as={as as React.ElementType} {...props}>
        <FileInputInput ref={ref} id={inputId} type="file" {...inputProps} />
        <FileInputLabel as="label" htmlFor={inputId} {...labelProps}>
          {children}
        </FileInputLabel>
      </StyledFileInput>
    );
  },
);

FileInput.displayName = 'FileInput';
export default FileInput;
