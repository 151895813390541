import { Base } from '~/components/layout';
import { Icon } from '~/components/vendor';
import styled, { css } from 'styled-components';
import { x } from '~/components/vendor/Icon/icons';

interface CloseProps {
  isAbsolute?: boolean;
  isRoundedButton?: boolean;
}

const Close = styled(Base).attrs<CloseProps>(({ role = 'button', children = <Icon icon={x} /> }) => ({ role, children }))<CloseProps>`
  ${({ isAbsolute, isRoundedButton, theme }) => css`
    --close-size: 24px;
    --close-color: ${theme.colors.gray600};
    --close-hover-color: ${theme.colors.gray700};
    color: var(--close-color);

    /*
     * Icon
     */

    & > svg {
      width: var(--close-size);
      height: var(--close-size);
    }

    /* 
     * Hover
     */

    &:hover {
      color: var(--close-hover-color);
    }

    /* 
     * Absolute
     */

    ${isAbsolute &&
    `
      display: flex;
      position: absolute;
      top: ${theme.spacers[7]}px;
      right: ${theme.spacers[7]}px;
      z-index: 1;
    `}

    /*
     * Rounded
     */

    ${isRoundedButton &&
    `
      align-items: center;
      justify-content: center;
      width: ${theme.spacers[9]}px;
      height: ${theme.spacers[9]}px;
      border-radius: ${theme.borderRadius.rounded}px;
      background-color: ${theme.colors.black50};
      color: ${theme.colors.white};
      transition: ${theme.transition.base};

      &:hover, &:active {
        background-color: ${theme.colors.black60};
        color: ${theme.colors.white};
      }
    `}
  `}
`;

export default Close;
