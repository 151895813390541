import List from '../List';
import ListChainedAnchor from './ListChainedAnchor';
import ListItem from '../ListItem';
import styled, { css } from 'styled-components';

const ListChained = styled(List)`
  ${({ gutter = 0, theme }) => css`
    /*
     * List item
     */

    & > ${ListItem} {
      display: flex;

      &:not(:last-child) > ${ListChainedAnchor}::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: -${theme.spacers[gutter]}px;
        left: 50%;
        z-index: -1;
        border-right: ${theme.borderWidth}px solid ${theme.borderColor};
      }
    }
  `}
`;

export default ListChained;
