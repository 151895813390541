import Base from '~/components/layout/Base';
import styled from 'styled-components';

const CardLeft = styled(Base)`
  position: inherit;

  > * {
    border-radius: inherit;
  }
`;

export default CardLeft;
