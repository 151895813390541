import { BaseLabel } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const Label = styled(BaseLabel)`
  ${({ theme }) => css`
    display: inline-block;
    margin-bottom: ${theme.spacers[4]}px;
    font-size: ${theme.labelFontSize}px;
  `}
`;

export default Label;
