import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type CardBodyProps = {
  isStuck?: boolean;
};

const CardBody = styled(Base)<CardBodyProps>`
  ${({ isStuck, theme }) => css`
    position: inherit;
    padding: var(--card-spacing);

    /*
     * Sticky
     */

    ${isStuck !== undefined &&
    `
      &:first-child {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom: var(--card-border-width) solid var(--card-border-color);
      }

      &:last-child {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-top: var(--card-border-width) solid var(--card-border-color);
      }

      transition: ${theme.transition.base};
      transition-property: border-color;
    `}

    ${isStuck === false &&
    `
      &:first-child {
        border-bottom-color: transparent;
      }

      &:last-child {
        border-top-color: transparent;
      }
    `}

    ${isStuck &&
    `
      background-color: ${theme.colors.white};

      &:first-child {
        border-bottom-color: ${theme.hrBorderColor};
      }

      &:last-child {
        border-top-color: ${theme.hrBorderColor};
      }
    `}
  `}
`;

export default CardBody;
