import React, { FC } from 'react';
import styled from 'styled-components';

type StyledSimpleTooltipProps = {
  isVisible?: boolean;
};

const StyledSimpleTooltip = styled.div<StyledSimpleTooltipProps>`
  position: relative;
  display: inline-block;

  .tooltip {
    background: ${({ theme }) => theme.tooltipBg};
    padding: ${({ theme }) => `${theme.tooltipPaddingY}px ${theme.tooltipPaddingX}px`};
    font-size: ${({ theme }) => theme.tooltipFontSize}px;
    text-align: center;
    border-radius: ${({ theme }) => theme.tooltipBorderRadius}px;
    color: ${({ theme }) => theme.tooltipColor};
    position: absolute;
    bottom: 145%;
    left: 50%;
    transform: translate(-50%, 0);
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      bottom: -${({ theme }) => theme.tooltipArrowSize}px;
      margin-left: -${({ theme }) => theme.tooltipArrowSize}px;
      border-top: ${({ theme }) => theme.tooltipArrowSize}px solid ${({ theme }) => theme.tooltipBg};
      border-right: ${({ theme }) => theme.tooltipArrowSize}px solid transparent;
      border-left: ${({ theme }) => theme.tooltipArrowSize}px solid transparent;
    }
  }
`;

type SimpleTooltipProps = StyledSimpleTooltipProps & {
  text: string;
  children: React.ReactNode;
};

const SimpleTooltip: FC<SimpleTooltipProps> = ({ text, children, ...props }) => {
  return (
    <StyledSimpleTooltip {...props}>
      {children}
      <div className="tooltip">{text}</div>
    </StyledSimpleTooltip>
  );
};

export default SimpleTooltip;
