import { Card, CardBody } from '~/components/card';
import { ControlledMenu, ControlledMenuProps, MenuCloseEvent } from '@szhsin/react-menu';
import { escHandlersStack } from '~/lib/apolloClient';
import { Modal } from '~/components/ui';
import { Theme as theme } from '~/theme';
import { useEffect, useId, useState } from 'react';
import DropdownBackdrop from './DropdownBackdrop';
import DropdownStyles from './DropdownStyles';
import removeBodyOverflow from '~/helpers/removeBodyOverflow';
import restoreBodyOverflow from '~/helpers/restoreBodyOverflow';
import styled from 'styled-components';

type DropdownProps = {
  isFixedMobile?: boolean;
};

const dropdownAttrs: ControlledMenuProps = {
  boundingBoxPadding: '20px',
  overflow: 'auto',
  portal: true,
  submenuCloseDelay: 0,
  submenuOpenDelay: 0,
  transition: true,
};

const Dropdown = styled(ControlledMenu).attrs<DropdownProps>(() => dropdownAttrs)<DropdownProps>`
  ${DropdownStyles}
`;

export type DropdownControlledProps = DropdownProps & ControlledMenuProps;

const DropdownControlled = ({ onClose, isFixedMobile, ...props }: DropdownControlledProps) => {
  const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });
  const dropdownId = useId();

  //
  // Check if the screen size is mobile
  //

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const checkScreenSize = () => {
      setViewportSize({ width: window.innerWidth, height: window.innerHeight });
    };

    const debouncedCheckScreenSize = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(checkScreenSize, 20);
    };

    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver(debouncedCheckScreenSize);

    // Start observing the document body
    resizeObserver.observe(document.body);

    // Clean up function
    return () => {
      // Stop observing the body
      resizeObserver.unobserve(document.body);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  //
  // Handle menu state change
  //

  useEffect(() => {
    if (props.state === undefined || props.state === 'opening' || props.state === 'closing') {
      return;
    }

    const handleEscClose = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && escHandlersStack()[escHandlersStack().length - 1].handler === handleEscClose) {
        document.activeElement && (document.activeElement as HTMLElement).blur(); // Safari bug fix
        onClose?.({ key: 'Escape', reason: 'cancel' });
      }
    };

    if (props.state === 'open') {
      document.addEventListener('keyup', handleEscClose, false);
      escHandlersStack([...escHandlersStack(), { handler: handleEscClose, id: dropdownId }]);
      if (escHandlersStack().length === 1) {
        removeBodyOverflow();
      }
    }

    return () => {
      document.removeEventListener('keyup', handleEscClose, false);
      escHandlersStack(escHandlersStack().filter((handler) => handler.handler !== handleEscClose));
      if (escHandlersStack().length === 0) {
        restoreBodyOverflow();
      }
    };
  }, [props.state]);

  //
  // Handle close
  //

  function handleClose(e: MenuCloseEvent) {
    if (e.reason === 'click') {
      onClose?.(e);
    }
  }

  return isFixedMobile && viewportSize.width < theme.breakpoints.desktop ? (
    <>
      {/* Modal */}
      <Modal isOpen={props.state === 'open' || props.state === 'opening'} onClose={onClose as () => void}>
        <Card size="sm" isModalContent>
          <CardBody>{props.children as React.ReactNode}</CardBody>
        </Card>
      </Modal>
    </>
  ) : (
    <>
      {/* Backdrop */}
      {props.state !== undefined && <DropdownBackdrop isOpen={props.state !== 'closed'} onClick={() => onClose?.({ reason: 'blur' })} />}

      {/* Dropdown */}
      <Dropdown repositionFlag={JSON.stringify(viewportSize)} onClose={handleClose} {...props} />
    </>
  );
};

export default DropdownControlled;
