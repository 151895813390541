import { Flex } from '~/components/layout';
import styled, { css } from 'styled-components';

type SidenavBackdropProps = {
  isActive: boolean;
};

const SidenavBackdrop = styled(Flex)<SidenavBackdropProps>`
  ${({ isActive = false, theme }) => css`
    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      width: calc(100vw - var(--sidenav-width));
      visibility: ${isActive ? 'visible' : 'hidden'};
    }
  `}
`;

export default SidenavBackdrop;
