import React, { ElementType, forwardRef } from 'react';
import Avatar, { AvatarProps } from './Avatar';
import { getBaseApiUrl } from '~/helpers';
import { avatarVariants } from '~/helpers/getAvatarUrl';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';

const AVATAR_SIZE = 96;

const getUserAvatarUrl = (profileFileId: string) => {
  if (profileFileId?.includes('blob')) {
    return profileFileId;
  }
  return `${getBaseApiUrl()}/api/images/${profileFileId}?width=${AVATAR_SIZE}&height=${AVATAR_SIZE}`;
};

const getAvatarVariant = (email = '') => {
  email = email.trim();
  let sum = 0;
  for (let i = 0; i < email.length; i++) {
    sum += email.charCodeAt(i);
  }
  return avatarVariants[sum % avatarVariants.length];
};

type UserDataLenderContact = {
  first_name?: string | null;
  last_name?: string | null;
  emails: {
    value: string;
    source: string;
  }[];
  avatar_id?: string | null;
};

type UserDataBorrowerContact = {
  firstName?: string | null;
  lastName?: string | null;
  emails: string[];
  avatarId?: string | null;
};

export type UserData = {
  user?: {
    fullName?: string | null;
    email: string;
    profileImageSet?: boolean | null;
    profileFileId?: string | null;
    lenderContact?: UserDataLenderContact | null;
    borrowerContact?: UserDataBorrowerContact | null;
    logo?: string | null;
  } | null;
  lenderContact?: UserDataLenderContact | null;
  borrowerContact?: UserDataBorrowerContact | null;
};

export const getUserEmail = ({ user, lenderContact, borrowerContact }: UserData) => {
  return user?.email
    || user?.lenderContact?.emails?.[0]?.value
    || user?.borrowerContact?.emails[0]
    || lenderContact?.emails?.[0]?.value
    || borrowerContact?.emails[0];
};

export const getUserName = ({ user, lenderContact, borrowerContact }: UserData) => {
  return user?.fullName
    || (user?.lenderContact && `${user.lenderContact.first_name} ${user.lenderContact.last_name}`)
    || (user?.borrowerContact && `${user.borrowerContact.firstName} ${user.borrowerContact.lastName}`)
    || (lenderContact && `${lenderContact.first_name} ${lenderContact.last_name}`)
    || (borrowerContact && `${borrowerContact.firstName} ${borrowerContact.lastName}`);
};

export const getUserAvatarSrc = ({ user, lenderContact, borrowerContact }: UserData) => {
  return (user?.profileImageSet && user.profileFileId ? getUserAvatarUrl(user.profileFileId) : undefined)
    || (user?.lenderContact?.avatar_id && `${getBaseApiUrl()}/api/lender/file/${user.lenderContact.avatar_id}?width=${AVATAR_SIZE}&height=${AVATAR_SIZE}`)
    || (user?.borrowerContact?.avatarId && `${getBaseApiUrl()}/api/images/${user.borrowerContact.avatarId}?width=${AVATAR_SIZE}&height=${AVATAR_SIZE}`)
    || (lenderContact?.avatar_id && `${getBaseApiUrl()}/api/lender/file/${lenderContact.avatar_id}?width=${AVATAR_SIZE}&height=${AVATAR_SIZE}`)
    || (borrowerContact?.avatarId && `${getBaseApiUrl()}/api/images/${borrowerContact.avatarId}?width=${AVATAR_SIZE}&height=${AVATAR_SIZE}`);
};

type UserAvatarProps<C extends ElementType = 'div'> = Omit<AvatarProps<C>, 'src' | 'alt'> & UserData;

const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(function UserAvatar({ user, lenderContact, borrowerContact, ...props }, ref) {
  const email = getUserEmail({ user, lenderContact, borrowerContact });
  const userName = getUserName({ user, lenderContact, borrowerContact }) || email;
  const avatarSrc = getUserAvatarSrc({ user, lenderContact, borrowerContact })
    || (user?.logo && getLogoUrl({ logo: user.logo }))
    || getAvatarVariant(email);

  return (
    <Avatar
      key={avatarSrc}
      ref={ref}
      {...props}
      src={avatarSrc}
      alt={userName}
    />
  );
});

export default UserAvatar;
