import { BaseMark } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const HighlightedWord = styled(BaseMark)`
  ${({ theme }) => css`
    border: ${theme.borderWidth}px solid ${theme.colors.warning};
    border-radius: ${theme.borderRadius.sm}px;
    background-color: rgba(244, 198, 34, 0.3);
    padding-top: ${theme.spacers[1]};
  `}
`;

export default HighlightedWord;
