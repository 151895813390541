import styled, { css } from 'styled-components';
import { BaseHr } from '~/components/layout/Base';

interface DividerProps {
  size?: 'base' | 'sm';
}

const Divider = styled(BaseHr)<DividerProps>`
  ${({ size = 'base', theme }) => css`
    --divider-spacing-y: ${theme.spacers[7]}px;
    --divider-thickness: ${theme.hrBorderWidth}px;
    --divider-color: ${theme.hrBorderColor};
    margin-top: var(--divider-spacing-y);
    margin-bottom: var(--divider-spacing-y);
    border-width: var(--divider-thickness) 0 0 0;
    border-style: solid;
    border-color: var(--divider-color);

    /* 
     * Small 
     */

    ${size === 'sm' &&
    `
      max-width: ${theme.hrSmallWidth}px;
      margin-left: auto;
      margin-right: auto;
    `}
  `}
`;

export default Divider;
