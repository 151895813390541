import { BaseA } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type NavLinkProps = {
  isActive?: boolean;
};

const NavLink = styled(BaseA)<NavLinkProps>`
  ${({ isActive, theme }) => css`
    position: relative;
    display: block;
    padding-top: var(--nav-padding-y);
    padding-bottom: var(--nav-padding-y);
    outline: none;
    font-size: ${theme.navLinkFontSize}px;
    white-space: nowrap;
    color: ${theme.navLinkColor};
    cursor: pointer;

    &:hover {
      color: ${theme.navLinkHoverColor};
    }

    & + & {
      margin-left: var(--nav-link-gutter);
    }

    /*
     * Active
     */

    &.active {
      color: ${theme.navLinkFocusColor};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: ${theme.borderWidth}px solid currentColor;
      }
    }

    ${isActive &&
    css`
      color: ${theme.navLinkFocusColor};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: ${theme.borderWidth}px solid currentColor;
      }
    `}
  `}
`;

export default NavLink;
