import { Chart } from 'chart.js';

export default function getOrCreateTooltip(chart: Chart) {
  let tooltipEl = chart.canvas.parentNode!.querySelector('#chart-tooltip') as HTMLElement;
  let tooltipLineEl = chart.canvas.parentNode!.querySelector('#chart-tooltip-line') as HTMLElement;

  if ('type' in chart.config && (chart.config.type === 'line' || chart.config.type === 'bar')) {
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.setAttribute('id', 'chart-tooltip');
      tooltipEl.setAttribute('role', 'tooltip');
      tooltipEl.classList.add('chart-tooltip');

      chart.canvas.parentNode!.appendChild(tooltipEl);
    }

    if (!tooltipLineEl) {
      tooltipLineEl = document.createElement('div');
      tooltipLineEl.setAttribute('id', 'chart-tooltip-line');
      tooltipLineEl.classList.add('chart-tooltip-line');

      chart.canvas.parentNode!.appendChild(tooltipLineEl);
    }
  }

  return { tooltipEl, tooltipLineEl };
}
