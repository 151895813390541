import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Spinner } from './Spinner';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type StyledTabPaneProps = {
  timeout: number;
  isHidden?: boolean;
  isLoading?: boolean;
};

const StyledTabPane = styled(Base).attrs<StyledTabPaneProps>(({ children, isLoading }) => ({
  children: isLoading ? (
    <>
      <Spinner size="sm" variant="primary" />
      <div style={{ visibility: 'hidden' }}>{children}</div>
    </>
  ) : (
    children
  ),
}))<StyledTabPaneProps>`
  ${({ timeout, isHidden, theme }) => css`
    position: relative;
    grid-row: 1;
    grid-column: 1;
    opacity: 1;
    visibility: ${isHidden ? 'hidden' : undefined};

    &.exit,
    &.exit-active {
      display: none;
    }

    &.enter {
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
      transition: ${theme.transition.base};
      transition-property: opacity;
      transition-duration: ${timeout}ms !important;
      overflow: clip;
    }

    &.enter-done {
      opacity: 1;
    }
  `}
`;

type TabPaneInnerProps = Omit<StyledTabPaneProps, 'timeout'> & {
  isActive?: boolean;
};

type TabPaneProps = BaseHOCPropsWithoutRef<'div', TabPaneInnerProps>;

export default function TabPane({ isActive, ...props }: TabPaneProps) {
  const timeout = 500;
  const nodeRef = useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={isActive} timeout={timeout} unmountOnExit>
      <StyledTabPane ref={nodeRef} timeout={timeout} {...props} />
    </CSSTransition>
  );
}
