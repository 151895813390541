import { Chart, Plugin, ChartMeta } from 'chart.js';
import { Theme as theme } from '~/theme';

const ChartBarFunnel: Plugin = {
  id: 'barfunnel',
  afterDatasetsDraw: (chart: Chart) => {
    const ctx = chart.ctx;
    const datasets = chart.getSortedVisibleDatasetMetas();

    datasets.forEach((dataset: ChartMeta<any>) => {
      dataset.data.forEach((bar: any, index: number) => {
        if (index === 0) {
          return;
        }

        const barX = bar.x - (bar.width as number) / 2;
        const barY = bar.y;
        const barHeight = bar.height as number;

        const prevBar = dataset.data[index - 1];
        const prevBarX = prevBar.x - (prevBar.width as number) / 2;
        const prevBarY = prevBar.y;
        const prevBarWidth = prevBar.width as number;
        const prevBarHeight = prevBar.height as number;

        ctx.fillStyle = theme.colors.primary15;
        ctx.beginPath();
        ctx.moveTo(barX, barY);
        ctx.lineTo(barX, barY + barHeight);
        ctx.lineTo(prevBarX + prevBarWidth, prevBarY + prevBarHeight);
        ctx.lineTo(prevBarX + prevBarWidth, prevBarY);
        ctx.fill();
      });
    });
  },
};

export default ChartBarFunnel;
