import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import Avatar from './Avatar';
import AvatarImage from './AvatarImage';
import AvatarTitle from './AvatarTitle';
import styled, { css } from 'styled-components';

interface AvatarGroupProps {
  size?: keyof CustomTheme['avatarSize'];
  outline?: keyof CustomTheme['colors'];
}

const AvatarGroup = styled(Base)<AvatarGroupProps>`
  ${({ size = 'base', outline, theme }) => css`
    & > ${Avatar.styledComponent} {
      --avatar-size: ${theme.avatarSize[size]}px;
      --avatar-font-size: ${theme.avatarFontSize[size]}px;
      --avatar-offset: calc(var(--avatar-size) / -5);
      --avatar-outline-width: calc(var(--avatar-size) / 15);

      /* 
       * Outline
       */

      ${outline &&
      `
        box-shadow: 0 0 0 var(--avatar-outline-width) ${theme.colors[outline]};
      `}
    }

    & > ${Avatar.styledComponent}:not(:first-child) {
      margin-left: var(--avatar-offset);
    }

    & > ${Avatar.styledComponent}:not(:last-child) ${AvatarImage}, & > ${Avatar.styledComponent}:not(:last-child) ${AvatarTitle} {
      mask-image: radial-gradient(
        circle at calc(100% - var(--avatar-offset) + var(--avatar-outline-width)) 50%,
        transparent calc(var(--avatar-size) / 2 + var(--avatar-outline-width) / 2),
        black calc(var(--avatar-size) / 2 + var(--avatar-outline-width) / 2)
      );
    }
  `}
`;

export default AvatarGroup;
