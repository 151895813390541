import React, { useState, useEffect, useRef, useMemo } from 'react';

function buildThresholdList(numSteps: number) {
  const thresholds = [];
  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }
  thresholds.push(0);
  return thresholds;
}

type TableWindowProps<T> = {
  items: T[];
  totalItems?: number;
  itemHeight: number;
  rootId?: string;
  renderItem: (item: T | undefined, style: React.CSSProperties, originalIndex: number) => React.ReactElement;
  onItemsScrollEnd?: () => void;
  onLoadedItemsScrollEnd?: (index: number) => void;
};

export default function TableWindow<T>({ items, totalItems, itemHeight, rootId, renderItem, onItemsScrollEnd, onLoadedItemsScrollEnd }: TableWindowProps<T>) {
  const [itemsCount, setItemsCount] = useState(0);
  const [startIndex, setStartIndex] = useState(0);

  const itemsToLoad = useMemo(() => (totalItems && totalItems > items.length ? new Array(totalItems - items.length).fill(null) : []), [items, totalItems]);
  const itemsWithItemsToLoad = useMemo(() => (totalItems && totalItems > items.length ? [...items, ...itemsToLoad] : items), [items, totalItems, itemsToLoad]);
  const itemsWithOriginalIndex = useMemo(() => itemsWithItemsToLoad.map((item, index) => ({ ...item, originalIndex: index })), [itemsWithItemsToLoad]);
  const renderedItems = itemsWithOriginalIndex.slice(startIndex, startIndex + itemsCount);

  const tableWindowRef = useRef<HTMLDivElement>(null);
  const tableWindowHeight = itemsWithOriginalIndex.length * itemHeight;
  const tableWindowStyle = { position: 'relative', height: tableWindowHeight };

  useEffect(() => {
    const tableWindowEl = tableWindowRef.current;
    if (tableWindowEl) {
      const root = rootId ? document.getElementById(rootId) : null;
      const rootHeight = root ? root.offsetHeight : window.innerHeight;
      const handler: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          const itemsRendered = Math.ceil(entry.intersectionRect.height / itemHeight);

          // // Abort if no item is visible
          // if (!entry.isIntersecting) {
          //   return;
          // }

          // Reached end of items list
          if (itemsRendered === totalItems ? totalItems : items.length) {
            onItemsScrollEnd?.();
          }

          // Reach end of loaded items list
          if (itemsRendered > items.length) {
            onLoadedItemsScrollEnd?.(itemsRendered);
          }

          // Update range of items to show
          const newItemsCount = (Math.ceil(Math.min(entry.intersectionRect.height, rootHeight) / itemHeight) || 1) + 1;
          const newStartIndex = entry.intersectionRect.top < 0 ? Math.floor(Math.abs(entry.intersectionRect.top) / itemHeight) : 0;
          setItemsCount(newItemsCount * 3);
          setStartIndex(Math.max(newStartIndex - newItemsCount, 0));
        });
      };
      const options = {
        root,
        rootMargin: `${tableWindowHeight - rootHeight}px 0px 0px 0px`,
        threshold: buildThresholdList(itemsWithOriginalIndex.length),
      };
      const observer = new IntersectionObserver(handler, options);
      observer?.observe(tableWindowEl);
      return () => observer?.unobserve(tableWindowEl);
    }
  }, [items, itemsWithOriginalIndex, totalItems, itemHeight, rootId, tableWindowHeight, onItemsScrollEnd, onLoadedItemsScrollEnd]);

  return (
    <div ref={tableWindowRef} style={tableWindowStyle as React.CSSProperties}>
      {renderedItems.map((item) => {
        const itemStyle: React.CSSProperties = {
          position: 'absolute',
          top: item.originalIndex * itemHeight,
          left: 0,
          width: 'calc(100% + var(--row-gutter-x) * 2)',
          height: itemHeight,
        };
        return <React.Fragment key={item.originalIndex}>{renderItem(itemsWithItemsToLoad[item.originalIndex], itemStyle, item.originalIndex)}</React.Fragment>;
      })}
    </div>
  );
}
