import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const Alert = styled(Base)`
  ${({ theme }) => css`
    --alert-padding-y: ${theme.alertPaddingY}px;
    --alert-padding-x: ${theme.alertPaddingX}px;
    --alert-border-radius: ${theme.alertBorderRadius}px;
    --alert-bg-color: ${theme.colors.gray600};
    --alert-font-size: ${theme.alertFontSize}px;
    display: flex;
    flex-wrap: nowrap;
    padding: var(--alert-padding-y) var(--alert-padding-x);
    border-radius: var(--alert-border-radius);
    background-color: var(--alert-bg-color);
    font-size: var(--alert-font-size);
    color: ${theme.colors.white};
  `}
`;

export default Alert;
