import { Icon } from '~/components/vendor';
import { Matrix } from '~/components/table';
import { maximize2 } from '~/components/vendor/Icon/icons';
import { useEffect, useRef, useState } from 'react';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';
import useElementSize from '~/hooks/useElementSize';

const StyledMatrixOverlay = styled(Base)`
  ${({ theme }) => css`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.black50};
    color: ${theme.colors.white};
    opacity: 0;
    transition: ${theme.transition.base};
    transition-property: opacity;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      inset: 50% auto auto 50%;
      width: ${theme.spacers[11]}px;
      height: ${theme.spacers[11]}px;
      border-radius: 50%;
      background-color: ${theme.colors.black60};
      transform: translate(-50%, -50%);
    }

    > ${Icon.styledComponent} {
      position: relative;
      font-size: ${theme.fontSize['3xl']}px;
    }
  `}
`;

type StyledMatrixPreviewProps = {
  matrixWidth: number;
  matrixScale: number;
};

const StyledMatrixPreview = styled(Base)<StyledMatrixPreviewProps>`
  ${({ matrixWidth, matrixScale, theme }) => css`
    position: relative;
    width: 100%;
    padding-bottom: 50%;
    border-radius: ${theme.borderRadius.base}px;
    border: ${theme.borderWidth}px solid ${theme.borderColor};
    box-shadow: ${theme.boxShadow['3']};
    overflow: hidden;

    /*
     * Overlay
     */

    &:hover > ${StyledMatrixOverlay} {
      opacity: 1;
    }

    /* 
     * Matrix
     */

    & > ${Matrix} {
      position: absolute;
      inset: 0;
      width: ${matrixWidth}px;
      height: ${matrixWidth * matrixScale}px;
      transform: scale(${matrixScale});
      transform-origin: 0 0;
      pointer-events: none;
      user-select: none;
    }
  `}
`;

type QuoteMatrixTablePreviewProps = BaseHOCPropsWithoutRef<'div'> & {
  matrixWidth?: number;
  defaultMatrixScale?: number;
  children: React.ReactNode;
};

export default function MatrixPreview({ matrixWidth = 1400, defaultMatrixScale = 0.15, children, ...props }: QuoteMatrixTablePreviewProps) {
  const [matrixScale, setMatrixScale] = useState(defaultMatrixScale);

  const containerRef = useRef(null);
  const { width: containerWidth } = useElementSize(containerRef);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      setMatrixScale(containerWidth / matrixWidth);
    }
  }, [containerWidth, matrixWidth]);

  return (
    <StyledMatrixPreview ref={containerRef} matrixWidth={matrixWidth} matrixScale={matrixScale} {...props}>
      {children}
      <StyledMatrixOverlay>
        <Icon icon={maximize2} />
      </StyledMatrixOverlay>
    </StyledMatrixPreview>
  );
}
