import styled, { css } from 'styled-components';
import { Base } from '../layout';

interface Props {
  size?: 'base' | 'sm';
}

const Vr = styled(Base)<Props>`
  ${({ theme }) => css`
    --vr-height: ${theme.vrHeight};
    --vr-spacing-x: ${theme.vrSpacingX}px;
    --vr-border-width: ${theme.vrBorderWidth}px;
    --vr-border-color: ${theme.vrBorderColor};
    display: inline-block;
    align-self: stretch;
    vertical-align: middle;
    width: var(--vr-border-width);
    min-height: var(--vr-height);
    margin-right: var(--vr-spacing-x);
    margin-left: var(--vr-spacing-x);
    background-color: var(--vr-border-color);
  `}
`;

export default Vr;
