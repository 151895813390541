import React, { useState } from 'react';
import { BaseImg, BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type StyledImageProps = {
  isFluid?: boolean;
  isLoaded?: boolean;
  showImageFallbackColor?: boolean;
};

const StyledImage = styled(BaseImg)<StyledImageProps>`
  ${({ isFluid, isLoaded, showImageFallbackColor = true, theme }) => css`
    width: ${isFluid ? '100%' : null};
    height: ${isFluid ? 'auto' : null};
    background-color: ${isLoaded || showImageFallbackColor === false ? null : theme.imageFallbackColor};
  `}
`;

type ImageType = {
  <C extends React.ElementType = 'img'>(props: ImageProps<C> & { ref?: React.Ref<HTMLImageElement> }): React.ReactNode;
  displayName?: string | undefined;
  styledComponent?: typeof StyledImage;
};

type ImageInnerProps = StyledImageProps;

export type ImageProps<C extends React.ElementType = 'img'> = BaseHOCPropsWithoutRef<C, ImageInnerProps>;

const Image: ImageType = React.forwardRef(<C extends React.ElementType = 'img'>({ as, isFluid, ...props }: ImageProps<C>, ref: React.Ref<HTMLImageElement>) => {
  const [isImageLoaded, setIsImageLoaded] = useState(props.src?.endsWith('.svg') ? true : false);
  return (
    <StyledImage
      as={as as React.ElementType}
      ref={ref}
      onLoad={() => setIsImageLoaded(true)}
      onError={() => setIsImageLoaded(true)}
      isLoaded={isImageLoaded}
      isFluid={isFluid}
      {...props}
    />
  );
});

Image.displayName = 'Image';
Image.styledComponent = StyledImage;
export default Image;
