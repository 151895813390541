import React from 'react';
import { Base } from '~/components/layout';
import { Icon } from '~/components/vendor';
import styled, { css } from 'styled-components';
import { chevronRight } from '~/components/vendor/Icon/icons';

type TableChevronProps = {
  isOpen?: boolean;
};

const TableChevron = styled(Base).attrs<TableChevronProps>(() => ({ role: 'button', children: <Icon icon={chevronRight} /> }))<TableChevronProps>`
  ${({ isOpen, theme }) => css`
    display: inline-flex;
    align-self: center;
    color: ${theme.colors.black};
    transform-origin: center center;
    transform: rotate(${isOpen ? '90deg' : '0deg'});
    transition: ${theme.transition.base};
  `}
`;

export default TableChevron;
