import React from 'react';
import { Block, FlexRow } from '~/components/layout';
import { CheckboxSquare } from '~/components/form';
import { components, OptionProps } from 'react-select';
import { Document } from '~/generated/graphql';
import { FileStatus } from '~/components/file';
import { Icon } from '~/components/vendor';
import { ListFolder } from '~/components/list';
import { plus } from '~/components/vendor/Icon/icons';
import { SelectOptionProps, Folder, Location, UserWithLogo } from '..';
import { Text } from '~/components/type';
import Divider from '../SelectDivider';
import UserAvatar, { getUserName } from '~/components/ui/Avatar/UserAvatar';

const SelectOption = (props: OptionProps<SelectOptionProps>) => {
  //
  // Renders completely custom option
  //

  if (props?.selectProps?.onOptionRender && props.data?.replaceOptionRender) {
    return props?.selectProps?.onOptionRender(props.data, props);
  }

  //
  // Title
  //

  if (props.data.dropdownTitle) {
    const allOptionsLength = (props.options as SelectOptionProps[]).filter((option) => !option.dropdownTitle && !option.isWL && !option.isDivider).length;
    const selectedOptions = (props.selectProps.value && 'length' in props.selectProps.value && props.selectProps.value?.length) ?? 0;
    if (props.data.dropdownTitle?.empty && allOptionsLength === 0) {
      return props.data.dropdownTitle?.empty;
    }
    if (allOptionsLength === selectedOptions) {
      return null;
    }
    return props.data.dropdownTitle?.default ?? null;
  }

  //
  // Divider
  //

  if (props.data.isDivider) {
    return <Divider />;
  }

  //
  // User
  //

  const userOption = (user: UserWithLogo) => {
    const fullName = getUserName({ user });
    return (
      <FlexRow utils={{ alignItems: 'center' }}>
        <Block utils={{ mr: 5 }}>
          <UserAvatar size="sm" user={user} />
        </Block>
        <Block style={{ minWidth: 0 }}>
          <Text utils={{ textTruncate: true }}>{fullName}</Text>
          {user.email && <Text utils={{ fontSize: 'sm', color: 'gray700', textTruncate: true }}>{user.email}</Text>}
        </Block>
      </FlexRow>
    );
  };

  //
  // Document
  //

  const documentOption = (document: Document) => (
    <FlexRow utils={{ alignItems: 'center' }}>
      <Text utils={{ mr: 5, textTruncate: true }}>{document.name}</Text>{' '}
      <Block utils={{ ml: 'auto' }}>
        <FileStatus isLender={false} status={document.status} />
      </Block>
    </FlexRow>
  );

  //
  // Location
  //

  const locationOption = (location: Location) => (
    <>
      <strong>{location.address}</strong>, {location.city}, {location.country}
    </>
  );

  //
  // Folder
  //

  const folderOption = (folder: Folder) => (
    <FlexRow utils={{ alignItems: 'center' }} style={{ paddingLeft: folder.depth! * 12 }}>
      {folder.title === 'At The Top Level' ? (
        <Icon icon={plus} utils={{ color: 'primary', mr: 4 }} />
      ) : (
        <ListFolder size="xs" variant={folder.adminsOnlyAccess ? 'black' : 'primary'} isExpandable={false} utils={{ mr: 4 }} />
      )}
      <Text utils={{ fontWeight: folder.depth! > 0 ? 'base' : 'bold', textTruncate: true }}>{folder.title}</Text>
    </FlexRow>
  );

  //
  // Rest
  //

  const children = props?.selectProps?.onOptionRender
    ? props?.selectProps?.onOptionRender(props.data, props)
    : props.data.user
      ? userOption(props.data.user)
      : props.data.document
        ? documentOption(props.data.document)
        : props.data.location
          ? locationOption(props.data.location)
          : props.data.folder
            ? folderOption(props.data.folder)
            : props.children;

  const { innerProps, isDisabled, ...rest } = props;

  return (
    <components.Option
      data-testid={typeof props?.children === 'string' && props.children}
      innerProps={{
        ...innerProps,
        ...(typeof props?.data.tooltip === 'string' ? { 'data-tooltip-id': 'GlobalTooltip', 'data-tooltip-content': props?.data.tooltip } : {}),
      }}
      isDisabled={isDisabled || Boolean(props.data.isDisabled)}
      {...rest}
    >
      {props?.selectProps?.isMulti && props?.selectProps?.showOptionCheckbox ? (
        <CheckboxSquare inputProps={{ checked: props.isSelected, readOnly: true }} labelProps={{ utils: { ml: 4 } }} style={{ pointerEvents: 'none' }}>
          {children}
        </CheckboxSquare>
      ) : (
        children
      )}
    </components.Option>
  );
};

export default SelectOption;
