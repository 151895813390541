import { FormGroup } from '../form';
import styled, { css } from 'styled-components';
import TableItem from './TableItem';

const TableCell = styled(TableItem)`
  ${() => css`
    align-items: var(--table-align);
    min-height: var(--table-cell-height);
    padding-top: var(--table-cell-padding-y);
    padding-bottom: var(--table-cell-padding-y);

    /*
     * FormGroup
     */

    &${FormGroup} {
      margin-bottom: 0;
    }
  `}
`;

export default TableCell;
