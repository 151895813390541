import { Avatar } from '~/components/ui';
import { css } from 'styled-components';
import { CustomTheme } from '~/@types/styled-components';
import { formatSVG } from '~/helpers';
import { Image } from '~/components/ui';
import { Props } from 'react-select';

type SelectStylesProps = Omit<Props, 'theme'> & { theme: CustomTheme };

const SelectStyles = ({ size = 'base', showAvatar, showDropdownIcon, isMulti, isFlush, theme }: SelectStylesProps) => css`
  .react-select__control {
    --select-input-max-height: ${theme.selectInputMaxHeight}px;
    --select-input-padding-y: ${theme.selectInputPaddingY[size]}px;
    --select-input-padding-x: ${theme.selectInputPaddingX[size]}px;
    --select-input-border-radius: ${theme.selectInputBorderRadius[size]}px;
    --select-input-border-width: ${theme.selectInputBorderWidth}px;
    --select-input-border-color: ${theme.selectInputBorderColor};
    --select-input-hover-border-color: ${theme.selectInputHoverBorderColor};
    --select-input-focus-border-color: ${theme.selectInputFocusBorderColor};
    --select-input-bg: ${theme.selectInputBg};
    --select-input-box-shadow: ${theme.selectInputBoxShadow};
    --select-input-line-height: ${theme.selectInputLineHeight}px;
    --select-input-font-size: ${theme.selectInputFontSize[size]}px;
    --select-input-placeholder-color: ${theme.selectInputPlaceholderColor};
    --select-icon-size: ${theme.selectIconSize[size as keyof typeof theme.selectIconSize]}px;
    --select-icon-size-base: ${theme.selectIconSize.base}px;
    --select-clear-icon-size: ${theme.selectClearIconSize[size]}px;
    --select-multi-value-margin-x: ${theme.selectMultiValueMarginX}px;
    --select-multi-padding-x: ${theme.selectMultiPaddingX}px;
    --select-multi-bg: ${theme.selectMultiBg};
    --select-multi-border-radius: ${theme.selectMultiBorderRadius}px;
    --select-multi-color: ${theme.selectMultiColor};
    --select-multi-value-font-size: ${theme.selectMultiValueFontSize}px;
    --select-multi-value-line-height: ${theme.selectMultiValueLineHeight}px;
    --select-multi-value-close-size: ${theme.selectMultiValueCloseSize}px;
    --select-multi-value-close-color: ${theme.selectMultiValueCloseColor};
    --select-multi-value-close-hover-bg: ${theme.selectMultiValueCloseHoverBg};
    --select-multi-value-close-hover-bg-invalid: ${theme.selectMultiValueCloseHoverBgInvalid};
    --select-multi-value-close-icon-size: ${theme.selectMultiValueCloseIconSize}px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-height: calc(var(--select-input-padding-y) * 2 + var(--select-input-border-width) * 2 + var(--select-input-line-height));
    padding: calc(var(--select-input-padding-y) - var(--select-input-border-width)) var(--select-input-padding-x);
    border: var(--select-input-border-width) solid var(--select-input-border-color);
    border-radius: var(--select-input-border-radius);
    background-color: var(--select-input-bg);
    box-shadow: var(--select-input-box-shadow);
    transition: ${theme.transition.base};

    &:hover {
      border-color: var(--select-input-hover-border-color);
    }

    &--is-focused,
    &--is-focused:hover {
      border-color: var(--select-input-focus-border-color);
    }

    /* 
     * Arrow
     */

    ${(!isMulti || showDropdownIcon) &&
    showDropdownIcon !== false &&
    `
      padding-right: calc(var(--select-icon-size) + var(--select-input-padding-x) * 1.5) !important;
      background-repeat: no-repeat;
      background-image: ${formatSVG(theme.selectIcon)};
      background-position: right var(--select-icon-size) center;
      background-size: var(--select-icon-size-base) var(--select-icon-size-base);
    `}

    /* 
     * Flush
     */

    ${isFlush &&
    `
      --select-input-padding-x: 0;
      --select-input-padding-y: 0;
      --select-input-border-width: 0;
      --select-input-box-shadow: none;
    `}

    /*
     * Avatar
     */

    & > ${Image.styledComponent}, & > ${Avatar.styledComponent} {
      width: ${showAvatar && showAvatar !== true ? theme.avatarSize[showAvatar] : theme.spacers[8]}px;
      height: ${showAvatar && showAvatar !== true ? theme.avatarSize[showAvatar] : theme.spacers[8]}px;
      min-width: ${showAvatar && showAvatar !== true ? theme.avatarSize[showAvatar] : theme.spacers[8]}px;
    }

    & > ${Image.styledComponent} + .react-select__value-container,
    & > ${Avatar.styledComponent} + .react-select__value-container {
      margin-left: ${theme.spacers[3]}px;
    }
  }

  .react-select__placeholder,
  .react-select__single-value {
    font-size: var(--select-input-font-size);
  }

  .react-select__placeholder {
    color: var(--select-input-placeholder-color);
  }

  .react-select__value-container {
    max-height: var(--select-input-max-height);
    overflow-y: auto;
    padding: 0;
    font-size: var(--select-input-font-size);

    > * {
      margin: 0;
      padding: 0;
    }

    &:not(:has(.react-select__multi-value)) {
      flex-wrap: nowrap;
    }
  }

  .react-select__value-container__grouped-value:has(> .react-select__input-container) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .react-select__value-container__grouped-value > .react-select__input-container[data-value=''] {
    max-width: 1px;
  }

  .react-select__value-container__grouped-value:has(.react-select__input-container:not([data-value=''])) > .react-select__grouped-value {
    display: none;
  }

  .react-select__value-container__grouped-value
    > .react-select__value-container:has(.react-select__input-container:not([data-value='']))
    ~ .react-select__indicators {
    display: none;
  }

  .react-select__dropdown-indicator {
    display: none;
  }

  .react-select__clear-indicator {
    width: var(--select-clear-icon-size);
    height: var(--select-clear-icon-size);
    background: no-repeat ${formatSVG(theme.selectClearIcon)} center center / var(--select-clear-icon-size) var(--select-clear-icon-size);
    cursor: pointer;
  }

  .react-select__clear-indicator:hover {
    background-image: ${formatSVG(theme.selectClearIconHover)};
  }

  .react-select__clear-indicator > svg {
    display: none;
  }

  .react-select__indicator-separator:first-child {
    display: none;
  }

  .react-select__indicator-separator:not(:first-child) {
    display: ${!isMulti || showDropdownIcon ? undefined : 'none'};
  }

  .react-select__indicator-separator {
    margin: ${theme.spacers[2]}px calc(var(--select-input-padding-x) / 2) ${theme.spacers[2]}px var(--select-input-padding-x);
    background-color: ${theme.colors.gray300};
  }

  .react-select__multi-value {
    position: relative;
    padding-left: var(--select-multi-padding-x);
    padding-right: calc(var(--select-multi-padding-x) / 2 + var(--select-multi-value-close-size));
    margin: calc((${theme.selectMenuItemFontSize * theme.lineHeight.base}px - var(--select-multi-value-line-height)) / 2) var(--select-multi-value-margin-x);
    background-color: var(--select-multi-bg);
    border-radius: var(--select-multi-border-radius);
    color: var(--select-multi-color);

    & > ${Avatar.styledComponent} {
      margin-top: calc((var(--select-multi-value-line-height) - ${theme.avatarSize['3xs']}px) / 2);
      margin-right: ${theme.spacers[2]}px;
      margin-left: calc((var(--select-multi-padding-x) - (var(--select-multi-value-line-height) - ${theme.avatarSize['3xs']}px) / 2) * -1);
    }
  }

  .react-select__multi-value__label {
    padding: 0;
    line-height: var(--select-multi-value-line-height);
    font-size: var(--select-multi-value-font-size);
    color: inherit;
  }

  .react-select__multi-value__remove {
    position: absolute;
    top: calc((var(--select-multi-value-line-height) - var(--select-multi-value-close-size)) / 2);
    right: calc((var(--select-multi-value-line-height) - var(--select-multi-value-close-size)) / 2);
    width: var(--select-multi-value-close-size);
    height: var(--select-multi-value-close-size);
    background: no-repeat ${formatSVG(theme.selectMultiValueCloseIcon)} center center / var(--select-multi-value-close-icon-size)
      var(--select-multi-value-close-icon-size);
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: var(--select-multi-value-close-hover-bg);
    }

    > svg {
      display: none;
    }
  }
`;

export default SelectStyles;
