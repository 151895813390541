import Base, { BaseHOCPropsWithoutRef } from '../layout/Base';
import mergeRefs from '~/helpers/mergeRefs';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export const StyledTableScrollable = styled(Base)`
  ${() => css`
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

type TableScrollableInnerProps = {
  children: (hasScrollXStarted: boolean) => React.ReactNode;
  onScroll: (e: Event) => void;
};

type TableScrollableProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C> & TableScrollableInnerProps;

const TableScrollable = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, children, onScroll, ...props }: TableScrollableProps<C>, ref: React.Ref<HTMLDivElement>) => {
    const innerRef = useRef<HTMLDivElement | null>(null);
    const hasScrollXStartedRef = useRef(false);
    const [hasScrollXStarted, setHasScrollXStarted] = useState(false);

    useEffect(() => {
      if (innerRef.current) {
        const inner = innerRef.current;

        const handleScroll = (e: Event) => {
          onScroll?.(e);

          const hasScrollXStarted = (e.target as HTMLDivElement).scrollLeft > 0;
          if ((hasScrollXStarted && !hasScrollXStartedRef.current) || (!hasScrollXStarted && hasScrollXStartedRef.current)) {
            hasScrollXStartedRef.current = hasScrollXStarted;
            setHasScrollXStarted(hasScrollXStarted);
          }
        };

        inner.addEventListener('scroll', handleScroll);

        return () => {
          inner.removeEventListener('scroll', handleScroll);
        };
      }
    }, []);

    return (
      <StyledTableScrollable as={as as React.ElementType} ref={mergeRefs([ref, innerRef])} {...props}>
        {children(hasScrollXStarted)}
      </StyledTableScrollable>
    );
  },
);

TableScrollable.displayName = 'TableScrollable';
export default TableScrollable;
