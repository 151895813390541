import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import { FormGroup, Input, InputGroup, RadioGroup } from '~/components/form';
import { StyledButton } from '../form/Button';
import { StyledDropdown } from '../vendor/SelectAddress';
import { StyledSelect, StyledAsyncSelect, StyledCreatableSelect } from '../vendor/Select';
import { StyledTableScrollable } from './TableScrollable';
import styled, { css, CSSProperties } from 'styled-components';
import TableCell from './TableCell';
import TableItem from './TableItem';
import TableRow from './TableRow';

export type TableProps = {
  size?: keyof CustomTheme['tableFontSize'];
  align?: CSSProperties['alignItems'];
  gutterY?: keyof CustomTheme['spacers'];
  isFlush?: boolean;
  isBorderFlush?: boolean;
  isBorderless?: boolean;
  isBordered?: boolean;
  isSpaced?: boolean;
  isSpacedRight?: boolean;
  isDragging?: boolean;
  isHoverable?: boolean;
  isBorderReverse?: boolean;
  isFullScreen?: boolean;
};

const Table = styled(Base)<TableProps>`
  ${({
    size = 'base',
    align = 'baseline',
    gutterY,
    isFlush,
    isBorderFlush,
    isBorderless,
    isBordered,
    isSpaced,
    isSpacedRight,
    isDragging,
    isHoverable,
    isBorderReverse,
    isFullScreen,
    theme,
  }) => css`
    --table-align: ${align};
    --table-bg: ${theme.tableBgColor};
    --table-hover-bg: ${theme.tableHoverBgColor};
    --table-border-width: ${theme.tableBorderWidth}px;
    --table-border-color: ${theme.tableBorderColor};
    --table-font-size: ${theme.tableFontSize[size]}px;
    --table-cell-height: ${theme.tableCellHeight[size]}px;
    --table-cell-padding-y: calc((var(--table-cell-height) - 1em * ${theme.lineHeight.base}) / 2);
    --table-cell-input-offset: ${theme.inputPaddingX.sm + theme.inputBorderWidth}px;
    --table-header-height: ${theme.tableHeaderHeight[size]}px;
    --table-header-padding-y: calc((var(--table-header-height) - 1em * ${theme.lineHeight.base}) / 2);
    --table-header-bg: ${theme.tableHeaderBgColor};
    --table-icon-font-size: ${theme.tableIconFontSize}px;
    --table-scroll-started-border-width: ${theme.tableScrollStartedBorderWidth}px;
    --table-scroll-started-border-color: ${theme.tableScrollStartedBorderColor};
    --table-scroll-started-gradient-size: ${theme.tableScrollStartedGradientSize}px;
    --table-scroll-started-gradient-from: ${theme.tableScrollStartedGradientFrom};
    --table-scroll-started-gradient-to: ${theme.tableScrollStartedGradientTo};
    position: relative;
    font-size: var(--table-font-size);

    /*
     * Dragging
     */

    ${isDragging &&
    `
      display: block !important;
      
      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        background-color: ${theme.colors.gray100};
        border-radius: ${theme.borderRadius.lg}px;
        border: var(--table-border-width) solid var(--table-border-color);
      }
    `}

    /*
     * Hoverable
     */

    ${isHoverable &&
    `
      &:hover::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        background-color: ${theme.colors.gray100};
        border-radius: ${theme.borderRadius.lg}px;
        border: var(--table-border-width) solid var(--table-border-color);
      }
    `}

    /*
     * Bordered
     */

    ${isBordered &&
    `
      ${TableRow} > ${TableItem}:not(:last-child) {
        border-right: var(--table-border-width) solid var(--table-border-color);
      }
    `}

    /*
     * Spaced
     */

    ${isSpaced &&
    `
      ${TableRow} {
        margin-left: 0;
        margin-right: 0;
        border-image: none;
      }
    `}

    ${isSpacedRight &&
    `
      ${TableRow} {
        margin-right: 0;
        border-image: linear-gradient(
            to right,
            transparent,
            transparent var(--row-gutter-x),
            var(--table-border-color) var(--row-gutter-x),
            var(--table-border-color)
          )
          1;
      }
    `}

    /*
     * Gutter Y
     */

    ${gutterY !== undefined &&
    `
      --table-cell-padding-y: ${theme.spacers[gutterY]}px;
    `}

    /*
     * Borderless
     */

    ${isBorderless &&
    `
      ${TableRow} {
        border: 0 !important;
      }
    `}

    /*
     * Flush
     */

    ${isFlush &&
    `
      ${TableRow}:first-child,
      ${TableRow}:first-child {
        border-top: 0 !important;
      }

      ${TableRow}:first-child ${TableItem} {
        padding-top: 0 !important;
      }

      ${TableRow}:last-child ${TableItem} {
        padding-bottom: 0 !important;
      }
    `}

    ${isFlush &&
    isBorderReverse &&
    `
      ${TableRow}:last-child,
      ${TableRow}:last-child {
        border-bottom: 0 !important;
      }
    `}

    /*
     * Border flush
     */

    ${isBorderFlush &&
    ` 
      & > ${TableRow}:first-child,
      & > .ReactCollapse--content:first-child > ${TableRow},
      & > ${StyledTableScrollable} > ${TableRow}:last-child {
        border-top: 0 !important;
      }
    `}

    ${isBorderFlush &&
    isBorderReverse &&
    ` 
      & > ${TableRow}:last-child,
      & > .ReactCollapse--content:last-child > ${TableRow},
      & > ${StyledTableScrollable} > ${TableRow}:last-child {
        border-bottom: 0 !important;
      }
    `}

    /*
     * Border reverse
     */

    ${isBorderReverse &&
    `
      ${TableRow} {
        border-top: none;
        border-bottom: var(--table-border-width) solid var(--table-border-color);
      }
    `}

    /*
     * Adjust vertical spacing
     */

    ${gutterY === undefined &&
    `
      ${TableCell}:has(${Input}),
      ${TableCell}:has(${InputGroup}),
      ${TableCell}:has(${StyledSelect}),
      ${TableCell}:has(${StyledAsyncSelect}),
      ${TableCell}:has(${StyledCreatableSelect}),
      ${TableCell}:has(${StyledButton}),
      ${TableCell}:has(${RadioGroup}),
      ${TableCell}:has(${StyledDropdown}) {
        --table-cell-padding-y: calc(
          (var(--table-cell-height) - ${theme.inputPaddingY.sm}px * 2 - ${theme.inputBorderWidth}px * 2 - ${theme.inputLineHeight}px) / 2
        );
      }
    `}


    /*
     * Fullscreen
     */

    ${isFullScreen &&
    `
      width: 100%;
      height: 100%;
    `}

    /* 
     * Adjust horizontal spacing
     */

    ${TableCell} + ${TableCell} > ${Input},
    ${TableCell} + ${TableCell} > ${InputGroup},
    ${TableCell} + ${TableCell} > ${StyledSelect},
    ${TableCell} + ${TableCell} > ${StyledAsyncSelect},
    ${TableCell} + ${TableCell} > ${StyledCreatableSelect},
    ${TableCell} + ${TableCell} > ${RadioGroup},
    ${TableCell} + ${TableCell} > ${FormGroup},
    ${TableCell} + ${TableCell} > ${StyledDropdown} {
      width: calc(100% + var(--table-cell-input-offset));
      margin-left: calc(var(--table-cell-input-offset) * -1);
    }

    ${TableCell}:has(+ ${TableCell}) > ${Input},
    ${TableCell}:has(+ ${TableCell}) > ${InputGroup},
    ${TableCell}:has(+ ${TableCell}) > ${StyledSelect},
    ${TableCell}:has(+ ${TableCell}) > ${StyledAsyncSelect},
    ${TableCell}:has(+ ${TableCell}) > ${StyledCreatableSelect},
    ${TableCell}:has(+ ${TableCell}) > ${RadioGroup},
    ${TableCell}:has(+ ${TableCell}) > ${FormGroup},
    ${TableCell}:has(+ ${TableCell}) > ${StyledDropdown} {
      width: calc(100% + var(--table-cell-input-offset));
      margin-right: calc(var(--table-cell-input-offset) * -1);
    }

    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${Input},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${InputGroup},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${StyledSelect},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${StyledAsyncSelect},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${StyledCreatableSelect},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${RadioGroup},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${FormGroup},
    ${TableCell} + ${TableCell}:has(+ ${TableCell}) > ${StyledDropdown} {
      width: calc(100% + var(--table-cell-input-offset) * 2);
    }
  `}
`;

export default Table;
